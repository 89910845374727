import { Grid, Stack, Typography } from '@mui/material'
import { CompensatoryDayItem } from '@services/timeoff.services'
import { formatDate } from '@utils/helper/common.helper'
import dayjs from 'dayjs'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface CompDayItemProps {
  selected?: boolean
  compDay: CompensatoryDayItem
}
const CompDayItem: React.FC<CompDayItemProps> = ({
  selected = false,
  compDay,
}) => {
  const { t } = useTranslation()

  return (
    <Grid
      item
      sx={{
        border: '1px solid',
        borderRadius: '8px',
        borderColor: selected
          ? (theme) => theme.palette.primary.main
          : '#e9e9e9',
        backgroundColor: selected ? '#fffafa' : 'white',
        px: 2,
        py: 1,
      }}
    >
      <Stack alignItems={'flex-start'}>
        <Typography fontWeight={500} fontSize={'medium'}>
          {formatDate(
            _.get(
              compDay,
              'attributes.timeoff_requests.data.attributes.start_at'
            )
          )}{' '}
          ~{' '}
          {formatDate(
            _.get(compDay, 'attributes.timeoff_requests.data.attributes.end_at')
          )}
        </Typography>
        <Typography variant="caption">
          {t('TIMEOFF_REQUEST.remainder')}
          {_.get(compDay, 'attributes.remaining_day')}/
          {_.get(compDay, 'attributes.earned_day')}
        </Typography>
        <Typography variant="caption">
          {t('TIMEOFF_REQUEST.comp_expire_at')}
          {dayjs(_.get(compDay, 'attributes.expire_at')).format('YYYY-MM-DD')}
        </Typography>
      </Stack>
    </Grid>
  )
}

export default CompDayItem
