import React from 'react'
import { Box, BoxProps } from '@mui/material'

interface CustomIconProps extends BoxProps {
  iconName: string
  fontSize?: string | number
}

const CustomIcon: React.FC<CustomIconProps> = ({
  iconName,
  fontSize,
  ...rest
}) => {
  return (
    <Box
      component={'span'}
      className={`icon-custom icon-${iconName}`}
      sx={{
        fontSize: fontSize ? fontSize : '20px',
        width: '100%',
        height: '100%',
      }}
      {...rest}
    ></Box>
  )
}

export default CustomIcon
