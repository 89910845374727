import { createReducer } from '@reduxjs/toolkit'
import {
  DailyReportDetailItem,
  TagDetail,
  TagItem,
  TeamDailyReportItem,
} from '@services/dailyreport.services'
import * as actions from '../actions'
import { TimeCardItem } from '@services/time.services'
import { MetaPage } from '@constants/common.constants'

type StateType = {
  myDailyReportList?: TimeCardItem[]
  teamDailyReportList?: TeamDailyReportItem[]
  hrDailyReportList?: TeamDailyReportItem[]
  dailyReportDetail?: DailyReportDetailItem
  tagList?: TagItem[]
  tagDetails?: TagDetail
  tagAllList?: TagItem[]
  tagListMeta?: MetaPage
}

const initialState: StateType = {
  myDailyReportList: undefined,
  teamDailyReportList: undefined,
  hrDailyReportList: undefined,
  dailyReportDetail: undefined,
  tagList: undefined,
  tagDetails: undefined,
  tagAllList: undefined,
  tagListMeta: undefined,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.fetchMyDailyReport.fulfilled, (state, action) => {
    state.myDailyReportList = action.payload.data
  })
  builder.addCase(actions.fetchTeamDailyReport.fulfilled, (state, action) => {
    state.teamDailyReportList = action.payload.data
  })
  builder.addCase(actions.fetchHrDailyReport.fulfilled, (state, action) => {
    state.hrDailyReportList = action.payload.data
  })
  builder.addCase(actions.updateDailyReport.fulfilled, (state, action) => {
    state.dailyReportDetail = action.payload.data
  })
  builder.addCase(actions.clearReports, (state) => {
    state.hrDailyReportList = undefined
    state.teamDailyReportList = undefined
  })
  builder.addCase(actions.fetchTagList.fulfilled, (state, action) => {
    state.tagList = action.payload.data
  })
  builder.addCase(actions.fetchTagDetailList.fulfilled, (state, action) => {
    state.tagDetails = action.payload.data
  })
  builder.addCase(actions.fetchAllTagList.fulfilled, (state, action) => {
    state.tagAllList = action.payload.data
    state.tagListMeta = action.payload.meta
  })
  builder.addCase(actions.clearTags, (state) => {
    state.tagDetails = undefined
  })
})
