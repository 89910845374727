import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import * as services from '@services/settings.services'
import { SETTINGS_ACTION_TYPE } from './types'
import { FilterParams } from '@constants/common.constants'

// Help Content
export const fetchPageContent = createAsyncThunk<
  services.ContentResponse,
  string
>(SETTINGS_ACTION_TYPE.GET_CONTENT, async (params, { rejectWithValue }) => {
  try {
    const res = await services.getPageContent(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

// Events
export const fetchEventListAll = createAsyncThunk<
  services.EventResponse,
  services.EventParams
>(SETTINGS_ACTION_TYPE.EVENT_LIST_ALL, async (params, { rejectWithValue }) => {
  try {
    const res = await services.eventListAllService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const fetchEventListPublic = createAsyncThunk<
  services.EventResponse,
  void
>(SETTINGS_ACTION_TYPE.EVENT_LIST_PUBLIC, async (_, { rejectWithValue }) => {
  try {
    const res = await services.eventListPublicService()
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const eventCreate = createAsyncThunk<
  services.EventCreateResponse,
  services.EventCreateParams
>(SETTINGS_ACTION_TYPE.EVENT_CREATE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.eventCreateService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const eventUpdate = createAsyncThunk<
  services.EventCreateResponse,
  services.EventUpdateParams
>(SETTINGS_ACTION_TYPE.EVENT_CREATE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.eventUpdateService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const eventDelete = createAsyncThunk<
  services.EventCreateResponse,
  string
>(SETTINGS_ACTION_TYPE.EVENT_DELETE, async (params, { rejectWithValue }) => {
  try {
    const res = await services.eventDeleteService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const clearHelp = createAction(SETTINGS_ACTION_TYPE.HELP_CLEAR)

//Meeting rooms
export const fetchMeetingRoomList = createAsyncThunk<
  services.MeetingRoomListResponse,
  void
>(SETTINGS_ACTION_TYPE.MEETING_ROOM_LIST, async (_, { rejectWithValue }) => {
  try {
    const res = await services.meetingRoomListService()
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const meetingRoomDetail = createAsyncThunk<
  services.MeetingRoomResponse,
  services.MeetingRoomIdParams
>(
  SETTINGS_ACTION_TYPE.MEETING_ROOM_DETAIL,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.meetingRoomDetailService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const meetingRoomCreate = createAsyncThunk<
  services.MeetingRoomResponse,
  services.MeetingRoomCreateParams
>(
  SETTINGS_ACTION_TYPE.MEETING_ROOM_CREATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.meetingRoomCreateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const meetingRoomUpdate = createAsyncThunk<
  services.MeetingRoomResponse,
  services.MeetingRoomUpdateParams
>(
  SETTINGS_ACTION_TYPE.MEETING_ROOM_UPDATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.meetingRoomUpdateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateMeetingRoomOrder = createAsyncThunk<
  services.MeetingRoomListResponse,
  services.MeetingRoomUpdateOrderParams
>(
  SETTINGS_ACTION_TYPE.UPDATE_MEETING_ROOM_ORDER,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.meetingRoomUpdateOrderService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const meetingRoomDelete = createAsyncThunk<
  services.MeetingRoomResponse,
  services.MeetingRoomIdParams
>(
  SETTINGS_ACTION_TYPE.MEETING_ROOM_DELETE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.meetingRoomDeleteService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchMeetingRoomOrderList = createAsyncThunk<
  services.MeetingRoomOrderListResponse,
  services.MeetingRoomOrderParams
>(
  SETTINGS_ACTION_TYPE.MEETING_ROOM_ORDER_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.meetingRoomOrderListService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)
//Time tables
export const fetchTimeTableList = createAsyncThunk<
  services.TimeTableListResponse,
  void
>(SETTINGS_ACTION_TYPE.TIME_TABLE_LIST, async (_, { rejectWithValue }) => {
  try {
    const res = await services.timeTableListService()
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const timeTableDetail = createAsyncThunk<
  services.TimeTableResponse,
  services.TimeTableIdParams
>(
  SETTINGS_ACTION_TYPE.TIME_TABLE_DETAIL,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.timeTableDetailService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const meetingRoomOrderDetail = createAsyncThunk<
  services.MeetingRoomOrderResponse,
  services.MeetingRoomIdParams
>(
  SETTINGS_ACTION_TYPE.MEETING_ROOM_ORDER_DETAIL,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.meetingRoomOrderDetailService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)
export const timeTableCreate = createAsyncThunk<
  services.TimeTableResponse,
  services.TimeTableCreateParams
>(
  SETTINGS_ACTION_TYPE.TIME_TABLE_CREATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.timeTableCreateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const meetingRoomOrderCreate = createAsyncThunk<
  services.MeetingRoomOrderResponse,
  services.MeetingRoomOrderCreateParams
>(
  SETTINGS_ACTION_TYPE.MEETING_ROOM_ORDER_CREATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.meetingRoomOrderCreateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)
export const timeTableUpdate = createAsyncThunk<
  services.TimeTableResponse,
  services.TimeTableUpdateParams
>(
  SETTINGS_ACTION_TYPE.TIME_TABLE_UPDATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.timeTableUpdateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const meetingRoomOrderUpdate = createAsyncThunk<
  services.MeetingRoomOrderResponse,
  services.MeetingRoomOrderUpdateParams
>(
  SETTINGS_ACTION_TYPE.MEETING_ROOM_ORDER_UPDATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.meetingRoomOrderUpdateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const meetingRoomOrderDelete = createAsyncThunk<
  services.MeetingRoomOrderResponse,
  services.RoomDeleteParams
>(
  SETTINGS_ACTION_TYPE.MEETING_ROOM_ORDER_DELETE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.meetingRoomOrderDeleteService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)
export const timeTableDelete = createAsyncThunk<
  services.TimeTableResponse,
  services.TimeTableIdParams
>(
  SETTINGS_ACTION_TYPE.TIME_TABLE_DELETE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.timeTableDeleteService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchSystemSettings = createAsyncThunk<
  services.SystemSettingsListResponse,
  FilterParams
>(
  SETTINGS_ACTION_TYPE.SYSTEM_SETTINGS_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.systemSettingsListService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const createSystemSettings = createAsyncThunk<
  services.SystemSettingsRes,
  services.SystemSettingsParams
>(
  SETTINGS_ACTION_TYPE.SYSTEM_SETTINGS_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.systemSettingsCreateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateSystemSettings = createAsyncThunk<
  services.SystemSettingsRes,
  services.SystemSettingsParams
>(
  SETTINGS_ACTION_TYPE.SYSTEM_SETTINGS_UPDATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.systemSettingsUpdateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchCompanyRulesList = createAsyncThunk<
  services.CompanyRulesResponse,
  void
>(SETTINGS_ACTION_TYPE.COMPANY_RULES_LIST, async (_, { rejectWithValue }) => {
  try {
    const res = await services.fetchCompanyRulesService()
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateCompanyRules = createAsyncThunk<
  services.CompanyRulesResponse,
  services.CompanyRuleParams
>(
  SETTINGS_ACTION_TYPE.COMPANY_RULES_UPDATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.companyRulesUpdateService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchWorkspace = createAsyncThunk<
  services.WorkspaceListResponse,
  FilterParams
>(SETTINGS_ACTION_TYPE.WORKSPACE_LIST, async (params, { rejectWithValue }) => {
  try {
    const res = await services.fetchWorkspaceListService(params)
    return res
  } catch (err) {
    const error: any = err
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const fetchAllWorkspace =
  createAsyncThunk<services.WorkspaceAllListResponse>(
    SETTINGS_ACTION_TYPE.WORKSPACE_ALL_LIST,
    async (_, { rejectWithValue }) => {
      try {
        const res = await services.fetchAllWorkspaceListService()
        return res
      } catch (err) {
        const error: any = err
        if (!error.response) {
          throw error
        }
        return rejectWithValue(error.response.data)
      }
    }
  )

export const createWorkspace = createAsyncThunk<
  services.WorkspaceResponse,
  services.WorkspaceCreateParams
>(
  SETTINGS_ACTION_TYPE.WORKSPACE_CREATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.createWorkspaceService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateWorkspace = createAsyncThunk<
  services.WorkspaceResponse,
  services.WorkspaceUpdateParams
>(
  SETTINGS_ACTION_TYPE.WORKSPACE_UPDATE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.updateWorkspaceService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteWorkspace = createAsyncThunk<
  services.WorkspaceResponse,
  services.MeetingRoomIdParams
>(
  SETTINGS_ACTION_TYPE.WORKSPACE_DELETE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.deleteWorkspaceService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const statusChangeWorkspace = createAsyncThunk<
  services.WorkspaceResponse,
  services.MeetingRoomIdParams
>(
  SETTINGS_ACTION_TYPE.WORKSPACE_STATUS_CHANGE,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.workSpaceStatusChangeService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchWorkspaceDetail = createAsyncThunk<
  services.WorkspaceResponse,
  services.MeetingRoomIdParams
>(
  SETTINGS_ACTION_TYPE.WORKSPACE_DETAIL,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.showWorkspaceService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const verifyWorkspace = createAsyncThunk<
  any,
  services.WorkspaceVerifyParams
>(
  SETTINGS_ACTION_TYPE.WORKSPACE_VERIFY,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.verifyWorkspaceTokenService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const regenerateWorkspaceUid = createAsyncThunk<
  services.WorkspaceResponse,
  services.MeetingRoomIdParams
>(
  SETTINGS_ACTION_TYPE.WORKSPACE_REGENERATE_UID,
  async (params, { rejectWithValue }) => {
    try {
      const res = await services.regenerateWorkspaceUidService(params)
      return res
    } catch (err) {
      const error: any = err
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)
