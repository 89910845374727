import { Languages } from '@constants/common.constants'
import { HEADER, SIDEBAR } from '@constants/layouts.constants'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import {
  AppBar,
  Box,
  IconButton,
  Slide,
  Toolbar,
  useMediaQuery,
} from '@mui/material'
import Stack from '@mui/material/Stack'
import { UserProfileItem } from '@services/user.services'
import React from 'react'
import LanguageSwitcher from './LanguageSwitcher'
import UserMenu from './UserMenu'
import { Check, EditNote, Home } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import NotificationsDropdown from '@components/NotificationsDropdown'
import QrDialog from '@components/@devices/QrReader/QrDialog'
import IconButtonExtend from '@components/@material-extend/IconButtonExtend'
import useReport from '@components/MissingDailyReport/useReport'
import { MissingReportItem } from '@services/common.services'
import { ROUTE } from '@constants/route.constants'
import useInit from '@utils/hooks/useInit'
import NotificationBar from './NotificationBar'
import { useTranslation } from 'react-i18next'

interface HeaderProps {
  open: boolean
  setSideOpen: (open: boolean) => void
  onLogout: () => void
  user?: UserProfileItem
  onSwitch: (id: string) => void
  language?: string
  isFull?: boolean
  isDemo?: boolean
}

const Header: React.FC<HeaderProps> = ({
  open,
  user,
  setSideOpen,
  language,
  onLogout,
  onSwitch,
  isFull = false,
  isDemo,
}) => {
  const [dropdownSearchVisibility, setDropdownSearchVisibility] =
    React.useState(false)

  const showDropdownSearch = useMediaQuery('(max-width:575px)')

  const width = !isFull ? (open ? SIDEBAR.width : 0) : 0
  const currentLang = language ? language : 'en'
  const navigate = useNavigate()
  const report = useReport()
  const { missingReports, limit, isDenyWorkStart } = useInit()
  // const currentLang = user ? user.language.toString() : undefined

  // TODO: language enum shiidegdher string bolgono

  const handleReportDialog = () =>
    report({})
      .then((item: MissingReportItem) => {
        // eslint-disable-next-line no-console
        item &&
          navigate(
            `${ROUTE.DAILY_REPORT.ROOT}?date=${item.attributes.work_date}`
          )
      })
      .catch(() => null)

  const { t } = useTranslation()

  return (
    <AppBar
      elevation={0}
      position={'fixed'}
      sx={{
        width: {
          sm: open ? `calc(100% - ${width}px)` : `calc(100% - ${width}px)`,
        },
        ml: { sm: !isFull ? (open ? `${width}px` : 0) : 0 },
        transition: (theme) => theme.transitions.create(['width']),
        willChange: 'width',

        background: (theme) => theme.palette.secondary.light,
        minHeight: 40,
      }}
    >
      {isDemo && <NotificationBar text={t('DEMO.notification')} />}
      <Toolbar
        sx={{
          minHeight: {
            xs: HEADER.height,
            sm: HEADER.height,
          },
          px: { lg: 1, xs: 2 },
        }}
      >
        <React.Fragment>
          {!isFull ? (
            open ? (
              ''
            ) : (
              <IconButton
                edge="start"
                aria-label="open drawer"
                sx={{
                  ml: 0,
                }}
                onClick={() => setSideOpen(!open)}
              >
                {open ? <MenuOpenIcon /> : <MenuIcon />}
              </IconButton>
            )
          ) : (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{
                ml: 0,
                mr: 3,
              }}
              onClick={() => navigate('/')}
            >
              {<Home />}
            </IconButton>
          )}

          {showDropdownSearch && (
            <Slide in={dropdownSearchVisibility}>
              <Box
                sx={{
                  zIndex: 5,
                  left: 0,
                  right: 0,
                  position: 'absolute',
                  height: '100%',
                }}
              >
                <IconButton
                  sx={{
                    position: 'absolute',
                    right: 15,
                    top: '50%',
                    color: 'inherit',
                    transform: 'translateY(-50%)',
                  }}
                  onClick={() => setDropdownSearchVisibility(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Slide>
          )}
          <Stack
            direction="row"
            alignItems="center"
            spacing={{ xs: 1, sm: 1, md: 2 }}
            sx={{ ml: 'auto' }}
          >
            <QrDialog isDenyWorkStart={isDenyWorkStart} />
            <LanguageSwitcher
              currentLang={currentLang?.toString()}
              data={Languages}
              onSwitch={onSwitch}
            />

            {limit !== 0 && limit !== undefined && limit > 0 && (
              <IconButtonExtend
                sx={{ border: '1px solid #a29dbe' }}
                className="daily-report-badge"
                onClick={() => handleReportDialog()}
                badge={{
                  variant: 'standard',
                  badgeContent: missingReports && missingReports.length,
                }}
                elevation={3}
              >
                <EditNote sx={{ fontSize: '1.25rem' }} />
                {missingReports.length === 0 && (
                  <Box
                    sx={{
                      width: 20,
                      height: 20,
                      borderRadius: 20,
                      background: (theme) => theme.palette.success.main,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'absolute',
                      top: -10,
                      right: -10,
                    }}
                  >
                    <Check sx={{ fontSize: 13 }} />
                  </Box>
                )}
              </IconButtonExtend>
            )}

            <NotificationsDropdown />
            <UserMenu user={user} onLogout={onLogout} />
          </Stack>
        </React.Fragment>
      </Toolbar>
    </AppBar>
  )
}

export default Header
