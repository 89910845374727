import { TIMESHEET_WORKSPACE } from '@constants/time.constants'
import { PositionItem } from '@services/resource.services'
import {
  ApprovalTemplateDetailItem,
  ApprovalTemplateItem,
} from '@services/timeoff.services'
import { EmployeeItem } from '@services/user.services'
import _ from 'lodash'
import moment from 'moment'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { DateObject } from 'react-multi-date-picker'
import { t } from 'i18next'
import { RemoteWorkSettingsItem } from '@services/time.services'
import { formatDate } from './common.helper'
import isToday from 'dayjs/plugin/isToday'
// Extend Day.js with the necessary plugins
dayjs.extend(utc)
dayjs.extend(timezone)

export const isTimeCorrectionStepManual = (
  step: number,
  template: ApprovalTemplateItem | undefined | null
): boolean => {
  if (!template) return false

  const stepDetails = getTimeCorrectionStepDetail(step, template)

  return (stepDetails && stepDetails.attributes.is_manual) ?? false
}

export const getTimeCorrectionStepDetail = (
  step: number,
  template: ApprovalTemplateItem | undefined | null
): ApprovalTemplateDetailItem | undefined => {
  if (!template) return undefined

  const steps = _.get(
    template,
    'attributes.approval_template_details.data',
    undefined
  )

  const stepDetails = _.isEmpty(steps)
    ? undefined
    : _.find(
        steps,
        (item: ApprovalTemplateDetailItem) => item?.attributes?.step === step
      )

  return stepDetails
}

export const filterTimeCorrectionApprovers = (
  step: number,
  template: ApprovalTemplateItem,
  employees: EmployeeItem[] | undefined,
  userId: string
): EmployeeItem[] => {
  if (!employees || _.isEmpty(employees)) return []

  const stepDetail = getTimeCorrectionStepDetail(step, template)

  const positions = _.get(
    stepDetail,
    'attributes.positions.data',
    []
  ) as PositionItem[]

  if (_.isEmpty(positions)) return employees

  const filteredUsers = employees.filter((user: EmployeeItem) => {
    return (
      user.id !== userId &&
      user.attributes.position?.data &&
      user.attributes.position?.data?.id &&
      positions.some(
        (position) => position.id === _.get(user, 'attributes.position.data.id')
      )
    )
  })

  return filteredUsers
}

export const getTimeCorrectionStepPositionNames = (
  step: number,
  template: ApprovalTemplateItem | undefined | null
): string => {
  if (!template) return ''

  const stepDetails = getTimeCorrectionStepDetail(step, template)
  const positions = _.get(stepDetails, 'attributes.positions.data', [])

  if (_.isEmpty(positions)) return t('TIMEOFF_REQUEST.any_position')

  let names = ''

  _.map(positions, (position, index) => {
    if (index === positions.length - 1) {
      names += _.get(position, 'attributes.short_name', '')
    } else {
      names += _.get(position, 'attributes.short_name', '') + ', '
    }
  })

  return names
}

export const setWorkDateYearMonthDay = (
  _workDate: Date | string,
  _date: Date | string
): Date => {
  const workDate = new Date(_workDate)
  const date = new Date(_date)
  return new Date(
    workDate.getFullYear(),
    workDate.getMonth(),
    workDate.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds()
  )
}

export const getDurationDateTimeField = (from: Date, to: Date): number => {
  const fromDate = moment(new Date(from)).format('HH:mm:ss')

  const correctFromDate = moment(`2000-01-01 ${fromDate}`)

  const toDate = moment(new Date(to)).format('HH:mm:ss')

  const correctToDate = moment(`2000-01-01 ${toDate}`)

  const duration = moment
    .duration(correctFromDate.diff(correctToDate))
    .asMilliseconds()

  return duration
}

export const extendedHourStringToHours = (
  extendedHourString: string | null | undefined
): number => {
  if (!extendedHourString) {
    return 0
  }

  const [hoursStr, minutesStr, secondsStr] = extendedHourString.split(':')
  const hours: number = parseInt(hoursStr) // Parse hours part to an integer
  const minutes: number = parseInt(minutesStr) // Parse minutes part to an integer
  const seconds: number = parseInt(secondsStr) // Parse seconds part to an integer

  // Calculate the total number of hours
  const totalHours: number = hours + minutes / 60 + seconds / 3600

  return totalHours
}

export const formatCoordinates = (latitude: number, longitude: number) => {
  // Function to convert decimal degrees to degrees, minutes, and seconds
  function convertToDMS(coord: number) {
    const absolute = Math.abs(coord)
    const degrees = Math.floor(absolute)
    const minutesDecimal = (absolute - degrees) * 60
    const minutes = Math.floor(minutesDecimal)
    const seconds = (minutesDecimal - minutes) * 60
    return `${degrees}° ${minutes}' ${seconds.toFixed(3)}''`
  }

  // Determine direction (N/S for latitude, E/W for longitude)
  const latitudeDirection = latitude >= 0 ? 'N' : 'S'
  const longitudeDirection = longitude >= 0 ? 'E' : 'W'

  // Format latitude and longitude
  const formattedLatitude = `${latitudeDirection} ${convertToDMS(latitude)}`
  const formattedLongitude = `${longitudeDirection} ${convertToDMS(longitude)}`

  return { latitude: formattedLatitude, longitude: formattedLongitude }
}

export const timesheetWorkspace = (workspace: any) => {
  if (Number(workspace) === 0) {
    return TIMESHEET_WORKSPACE.remote
  }
  if (_.isNull(workspace)) {
    return TIMESHEET_WORKSPACE.no_location
  } else {
    return TIMESHEET_WORKSPACE.office
  }
}

const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

// Function to get the offset in minutes for a given timezone
export const getTimezoneOffset = (timezone: string): number => {
  // Get the current time in the specified timezone
  const now = dayjs().tz(timezone)

  // Return the offset in minutes
  return now.utcOffset()
}

export const convertDateToLocalSystem = (
  date: any,
  systemTimezone: string | undefined
) => {
  // eslint-disable-next-line no-console
  // Calculate the timezone offsets
  const dateUTC = dayjs.utc(date)
  const userTimezoneOffset = getTimezoneOffset(userTimezone)
  const systemTimezoneOffset = getTimezoneOffset(
    systemTimezone ? systemTimezone : 'Asia/Ulaanbaatar'
  )
  const diff = systemTimezoneOffset - userTimezoneOffset
  const adjustedDate = dateUTC.add(diff, 'minute')

  return adjustedDate.toDate()
}

export const compareDateObjectToDayjs = (date: DateObject, fieldDate: any) => {
  const res = dayjs(date.toDate()).isSame(fieldDate.work_date, 'day')
  return res
}

export const compareDayjsToDateObject = (fieldDate: any, date: DateObject) => {
  const res = dayjs(date.toDate()).isSame(fieldDate.work_date, 'day')
  return res
}

export const checkDateInDateRange = (
  date: Date,
  dateRange?: RemoteWorkSettingsItem[]
) => {
  let isDisabled = false
  dateRange &&
    dateRange.map((item) => {
      if (
        !_.isNull(item.attributes.disable_start_date) &&
        _.isNull(item.attributes.disable_end_date) &&
        moment(item.attributes.disable_start_date).isSameOrBefore(
          moment(date).startOf('day')
        )
      ) {
        isDisabled = true
      } else if (
        moment(item.attributes.disable_start_date).isSameOrBefore(
          moment(date).startOf('day')
        ) &&
        moment(item.attributes.disable_end_date).isSameOrAfter(
          moment(date).startOf('day')
        )
      ) {
        isDisabled = true
      }
    })

  return isDisabled
}

export const getPossibleMyTimeHelper = (
  date: string | undefined | Date
): number => {
  const timeStr =
    date instanceof Date
      ? formatDate(date, 'HH:mm')
      : date
      ? formatDate(new Date(`2000-01-01T${date}Z`), 'HH:mm')
      : '00:00'
  const [hours, minutes] = timeStr.split(':').map(Number)
  return hours * 60 + minutes
}

dayjs.extend(isToday)

export const isTodayHelper = (date: string | Date | undefined): boolean => {
  return dayjs(date).isValid() && dayjs(date).isToday()
}

export const msToTime = (duration: number): string => {
  const hours = Math.floor(duration / (1000 * 60 * 60))
  const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((duration % (1000 * 60)) / 1000)

  const hoursStr = String(hours).padStart(2, '0')
  const minutesStr = String(minutes).padStart(2, '0')
  const secondsStr = String(seconds).padStart(2, '0')

  return `${hoursStr}:${minutesStr}:${secondsStr}`
}
