export const DEMO_URL = 'demo.teamsync.mn'
//export const DEMO_URL = 'localhost'

export const DEMO_USERS = [
  {
    email: 'jagaa.tselmeg@demo.mn',
    password: 'Demo@123',
    name: 'Human resource',
    id: 1,
  },
  {
    email: 'gomboo.byambaa@demo.mn',
    password: 'Demo@123',
    name: 'Employee',
    id: 2,
  },
]
