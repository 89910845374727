import { ConfirmOptions } from '@components/Confirm/types'
import { FullScreenOptions } from '@components/FullScreenForm/types'
import { EMPLOYEE_STATUS } from './user.constants'

export const COST_MAX_LIMIT = 1

export type GenderItem = {
  id: string
  name: string
}

export enum GENDER {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other',
}

export const Genders: GenderItem[] = [
  {
    id: GENDER.MALE,
    name: 'SYSCOMMON.gender_male',
  },
  {
    id: GENDER.FEMALE,
    name: 'SYSCOMMON.gender_female',
  },
  {
    id: GENDER.OTHER,
    name: 'SYSCOMMON.gender_other',
  },
]

export const GENDER_LABELS: { [x: string]: { label: string } } = {
  [`${GENDER.MALE}`]: {
    label: 'SYSCOMMON.gender_male',
  },
  [`${GENDER.FEMALE}`]: {
    label: 'SYSCOMMON.gender_female',
  },
  [`${GENDER.OTHER}`]: {
    label: 'SYSCOMMON.gender_other',
  },
}

export const defaultConfirmationOptions: ConfirmOptions = {
  confirmationButtonProps: {
    fullWidth: true,
    variant: 'contained',
    size: 'large',
  },
  cancellationButtonProps: {
    variant: 'outlined',
    fullWidth: true,
    size: 'large',
  },
  dialogProps: {
    maxWidth: 'sm',
  },
}

export const defaultFullScreenOptions: FullScreenOptions = {
  dialogProps: {
    maxWidth: 'md',
  },
}

export const validPerPages = [10, 30, 50]

export type LanguageItem = {
  label: string
  shortLabel: string
  id: string
}

export type Option = {
  id: number
  name: string
}

export const PerPage: Option[] = [
  { id: 10, name: '10' },
  { id: 30, name: '20' },
  { id: 50, name: '50' },
]

export const InitialPaging = {
  page: 1,
  per_page: 10,
}

export const DefaultPaging = {
  page: '1',
  per_page: '10',
}

export enum Direction {
  ASC = 'asc',
  DESC = 'desc',
}

export const DefaultFilter = {
  keyword: '',
  order_by: '',
  direction: Direction.ASC,
  per_page: '10',
  page: '1',
}

export const EmailTemplateGridPaging = {
  keyword: '',
  order_by: '',
  direction: Direction.ASC,
  per_page: '12',
  page: '1',
}

export const EmployeePublicDefaultFilter = {
  keyword: '',
  order_by: '',
  direction: Direction.ASC,
  per_page: '12',
  page: '1',
}

export const EmployeeDefaultFilter = {
  keyword: '',
  order_by: '',
  direction: Direction.ASC,
  per_page: '10',
  page: '1',
  gender: [GENDER.MALE, GENDER.FEMALE, GENDER.OTHER],
  positions: [],
  divisions: [],
  roles: [],
  status: [EMPLOYEE_STATUS.ACTIVE],
  is_time_required: true,
}

export const UserDefaultFilter = {
  keyword: '',
  order_by: '',
  direction: Direction.ASC,
  per_page: '24',
  page: '1',
}

export const CompanyPerPage = 100

export type MetaPage = {
  current_page: number
  total_pages: number
  total_count: number
  per_page: number
}
export interface FilterParams {
  keyword: string
  order_by: string
  direction: Direction
  per_page: string
  page: string
}

export const Languages: LanguageItem[] = [
  {
    label: 'English',
    shortLabel: 'EN',
    id: 'en',
  },
  {
    label: 'Japanese',
    shortLabel: 'JP',
    id: 'jp',
  },
  {
    label: 'Mongolian',
    shortLabel: 'MN',
    id: 'mn',
  },
]

export enum EventTypes {
  Holiday = 'holiday',
  Event = 'event',
  Birthday = 'birthday',
}

export type SearchFilter = {
  keyword?: string
}

export enum ListName {
  EmployeePublic = 'employee_public',
  EmployeePrivate = 'emoployee_private',
  Project = 'project',
  Department = 'department',
  Role = 'role',
  Position = 'position',
  Skills = 'skills',
  PartnerCompany = 'partner_company',
  PartnerDivision = 'partner_divisio',
  Resource = 'resource',
  SkillCategory = 'skill_category',
  EmailSend = 'email_send',
  EmailCampaign = 'email_campaign',
  EmailTemplate = 'email_template',
  TimeOffTemplate = 'timeoff_template',
  TimeOffRequest = 'timeoff_request',
  TimeOffApprove = 'timeoff_approve',
  ApprovalTemplate = 'approval_template',
  Notifications = 'notifications',
  QuitUsers = 'quit_users',
  InfoLog = 'info_log',
  DailyReport = 'daily_report',
  TeamReport = 'team_report',
  TimeCorrectionApprove = 'time_correction_approve',
  MyTimeCorrection = 'my_time_correction',
  CompanySettings = 'company_settings',
  RemoteWorkRequest = 'remote_work_requests',
  RemoteWorkApproveList = 'remote_work_approve_list',
  RemoteWorkSettings = 'remote_work_settings',
  ActualCostReport = 'actual_cost_report',
  UserCompensatory = 'user_compensatory',
  PartnerPurchaseOrderProject = 'partner_purchase_order_project',
  ProjectListWithInvoice = 'project_list_with_invoice',
  CostReport = 'cost_report_list',
  Tags = 'tags',
  // Add more list names as needed
}

export const BgColorOptions = { saturation: 70, lightness: 75, alpha: 100 }
export const BgColorOptionsLight = {
  saturation: 50,
  lightness: 85,
  alpha: 100,
}

export const BgColorOptionsMedium = {
  saturation: 40,
  lightness: 65,
  alpha: 100,
}

export const WordPressURL =
  'https://public-api.wordpress.com/rest/v1.1/sites/teamsynchelp.wordpress.com/'

export const FallBackTimezone = 'Asia/Ulaanbaatar'

export enum SYSTEM_SETTINGS_ITEM_NAME {
  COMPANY_PREFIX = 'company_prefix',
  JSON_VALUE = 'JSON value',
  LUNCH_DURATION = 'lunch_duration',
  LUNCH_START = 'lunch_start',
  MAX_MY_TIME = 'max_my_time',
  NDSH_DAY = 'ndsh_day',
  SALARY_MONTH_START_DAY = 'salary_month_start_day',
  VACATION_BALANCE_CONDITIONS = 'vacation_balance_conditions',
  WORKING_HOURS = 'working_hours',
  WORK_START_INTERVAL = 'work_start_interval',
  WORK_START_MAX = 'work_start_max',
  WORK_START_MIN = 'work_start_min',
  MAP_INIT_POINT = 'map_init_point',
}

export const PagingFilterNames = [
  'keyword',
  'order_by',
  'direction',
  'per_page',
  'page',
]

export enum FREQUENCY {
  NON_RECURRING = 'non_recurring',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}
export enum DAYS {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 0,
}

export const FREQUENCY_OPTIONS = [
  { name: 'MEETING_ROOM_ORDER.none', id: FREQUENCY.NON_RECURRING },
  { name: 'MEETING_ROOM_ORDER.daily', id: FREQUENCY.DAILY },
  { name: 'MEETING_ROOM_ORDER.weekly', id: FREQUENCY.WEEKLY },
  { name: 'MEETING_ROOM_ORDER.monthly', id: FREQUENCY.MONTHLY },
]

export const DAYS_OPTIONS = [
  { name: 'MEETING_ROOM_ORDER.mon', id: DAYS.MONDAY },
  { name: 'MEETING_ROOM_ORDER.tue', id: DAYS.TUESDAY },
  { name: 'MEETING_ROOM_ORDER.wed', id: DAYS.WEDNESDAY },
  { name: 'MEETING_ROOM_ORDER.thu', id: DAYS.THURSDAY },
  { name: 'MEETING_ROOM_ORDER.fri', id: DAYS.FRIDAY },
  { name: 'MEETING_ROOM_ORDER.sat', id: DAYS.SATURDAY },
  { name: 'MEETING_ROOM_ORDER.sun', id: DAYS.SUNDAY },
]
