import React, { useEffect } from 'react'
import {
  Box,
  Popover,
  CardHeader,
  Divider,
  CardActions,
  Button,
  List,
} from '@mui/material'
import IconButtonExtend from '@components/@material-extend/IconButtonExtend'
import {
  NotificationsOutlined as IconNotificationImportantOutlined,
  ArrowForward as IconArrowForward,
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import NotificationItem from './NotificationItem'
import useNotify from '@containers/@modules/Notification/useNotify'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import DataLoading from '@components/DataLoading'

const NotificationsDropdown: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const isOpen = Boolean(anchorEl)
  const { t } = useTranslation()
  const { count, shortList, shortData, read, metaShort } = useNotify()
  const countNumber = _.get(count, 'count', 0)

  const handleClick = React.useCallback((event: any) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = React.useCallback(() => {
    setAnchorEl(null)
  }, [])

  useEffect(() => {
    if (isOpen) {
      shortList({ page: '1', per_page: '3' })
    }
  }, [isOpen])

  return (
    <>
      <Box onClick={handleClick}>
        <IconButtonExtend
          badge={{ variant: 'standard', badgeContent: countNumber }}
          sx={{ border: '1px solid #a29dbe' }}
          elevation={3}
        >
          <IconNotificationImportantOutlined sx={{ fontSize: '1.25rem' }} />
        </IconButtonExtend>
      </Box>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          elevation: 20,
          sx: {
            borderRadius: 1,
            overflow: 'inherit',
          },
        }}
        sx={{
          borderRadius: 2,
          mr: 6,
          mt: '5px',
        }}
      >
        <Box
          sx={{
            width: 360,
            maxWidth: '100%',
            position: 'relative',
            // '&::before': {
            //   backgroundColor: 'white',
            //   content: '""',
            //   display: 'block',
            //   position: 'absolute',
            //   width: 12,
            //   height: 12,
            //   top: -6,
            //   transform: 'rotate(45deg)',
            //   right: {
            //     xl: '64px',
            //     lg: '64px',
            //     md: '74px',
            //     sm: '74px',
            //     xs: '72px',
            //   },
            // },
          }}
        >
          <CardHeader
            title={t('SYSCOMMON.notifications')}
            sx={{
              '& .MuiTypography-root': {
                color: '#333',
                fontSize: 16,
              },
              py: 2,
              borderBottom: '1px solid #eee',
            }}
          />
          <Box sx={{ position: 'relative', minHeight: 200 }}>
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                zIndex: 100,
                alignItems: 'center',
                justifyContent: 'center',
                pointerEvents: 'none',
              }}
            >
              <DataLoading
                isLoading={metaShort.pending && !shortData}
                isEmptyData={
                  _.isEmpty(shortData) &&
                  _.isArray(shortData) &&
                  !metaShort.pending
                }
              />
            </Box>

            <List disablePadding>
              {shortData &&
                shortData.map((item, index) => {
                  return (
                    <NotificationItem
                      key={index}
                      item={item}
                      markAsRead={(v) => {
                        read(v)
                        handleClose()
                        //close popover
                      }}
                    />
                  )
                })}
            </List>
          </Box>
          <Divider />
          <CardActions sx={{ justifyContent: 'center' }}>
            <Button
              component={Link}
              sx={{
                textTransform: 'none',
                fontWeight: 'normal',
                '&:hover': { bgcolor: 'transparent' },
              }}
              size={'small'}
              variant="text"
              endIcon={<IconArrowForward />}
              disableRipple
              to={`/notifications`}
              onClick={() => handleClose()}
            >
              {t('SYSCOMMON.view_all')}
            </Button>
          </CardActions>
        </Box>
      </Popover>
    </>
  )
}

export default NotificationsDropdown
