import { HEADER } from '@constants/layouts.constants'
import { LayoutContext } from '@layouts/MainLayout/LayoutProvider'
import {
  ArrowForward,
  Close,
  CloseSharp,
  InfoOutlined,
  NavigateNext,
} from '@mui/icons-material'
import {
  Alert,
  Box,
  BoxProps,
  Breadcrumbs,
  Collapse,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import React, {
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

interface DetailPageLayout extends PropsWithChildren {
  breadcrumbs?: BreadCrumb[]
  title?: ReactNode
  spacing?: any
  actions?: ReactNode
  helpTitle?: string
  helpContent?: ReactNode
  total?: number | string
  backgroundProps?: BoxProps
  sideContent?: { content: ReactNode; title: string }
  onClose?: () => void
  side?: boolean
  error?: boolean | any
  spacingFromContent?: any
  leftSideBar?: ReactNode
  backgroundImage?: string
}

export type BreadCrumb = {
  title: string
  to?: string
}

const DetailPageLayout: React.FC<DetailPageLayout> = ({
  children,
  breadcrumbs,
  spacing,
  title,
  total,
  side = false,
  error,
  actions,
  helpContent,
  onClose,
  sideContent,
  helpTitle,
  backgroundProps,
  spacingFromContent = '60px',
  leftSideBar,
  backgroundImage,
}) => {
  const spacingX = spacing ? spacing : { xl: 8, lg: 4, md: 2, xs: 2, sm: 2 }
  const [help, setHelp] = useState<boolean>(false)
  const [errorOpen, setErrorOpen] = useState<boolean | any>(false)
  const { isSidebarOpen } = useContext(LayoutContext)

  const { t } = useTranslation()

  useEffect(() => {
    if (error) {
      setErrorOpen(error)
    } else {
      setErrorOpen(false)
    }
  }, [error])

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          width: '100%',
          background: (theme) => theme.palette.secondary.light,
          pt: `${HEADER.height}px`,
          backgroundSize: 'cover',
          position: 'relative',
          '&:after': {
            display: backgroundImage ? 'inline-block' : 'none',
            position: 'absolute',
            content: '""',
            inset: '0px',
            pointerEvents: 'none',
          },
        }}
        {...backgroundProps}
        className="content-header"
      >
        {helpContent && (
          <IconButton
            data-test-id="info"
            onClick={() => setHelp(true)}
            sx={{
              position: 'absolute',
              right: 16,
              cursor: 'pointer',
              zIndex: 110,
              top: {
                xs: HEADER.height + 8,
                md: HEADER.height + 8,
              },
            }}
          >
            <InfoOutlined />
          </IconButton>
        )}
        {helpContent && (
          <Drawer
            data-test-id="info-drawer"
            anchor={'right'}
            open={help}
            onClose={() => setHelp(false)}
          >
            <Box
              sx={{ width: { xs: 260, sm: 260, md: 260, lg: 300, xl: 320 } }}
            >
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                px={2}
                py={1}
              >
                <Typography
                  variant="h5"
                  component={'div'}
                  sx={{ m: 0, pl: 3, position: 'relative' }}
                >
                  <InfoOutlined
                    fontSize="small"
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      bottom: 0,
                      color: '#333',
                      transform: 'translateY(-50%)',
                      left: 0,
                      mr: 0.5,
                    }}
                  />
                  {helpTitle ? helpTitle : title}
                </Typography>
                <IconButton data-test-id="arrow" onClick={() => setHelp(false)}>
                  <ArrowForward />
                </IconButton>
              </Stack>
              <Divider />
              <Box sx={{ p: 2 }}>{helpContent}</Box>
            </Box>
          </Drawer>
        )}

        {sideContent && (
          <Drawer
            data-test-id="side-content"
            anchor={'right'}
            open={side}
            onClose={() => onClose && onClose()}
          >
            <Box
              sx={{ width: { xs: 260, sm: 260, md: 260, lg: 300, xl: 320 } }}
            >
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                px={2}
                py={1}
              >
                <Typography
                  variant="h5"
                  component={'div'}
                  sx={{ m: 0, pl: 1, position: 'relative' }}
                >
                  {sideContent?.title ? sideContent?.title : ''}
                </Typography>
                <IconButton
                  data-test-id="close"
                  onClick={() => onClose && onClose()}
                >
                  <Close />
                </IconButton>
              </Stack>
              <Divider />
              <Box data-test-id="side-content-element" sx={{ p: 2 }}>
                {sideContent?.content}
              </Box>
            </Box>
          </Drawer>
        )}

        <Box
          sx={{
            px: spacingX,
            position: 'relative',
            pb: spacingFromContent,
            zIndex: 100,
            pt: 2,
          }}
        >
          <Box>
            <Collapse in={!!errorOpen}>
              <Alert
                severity="warning"
                color="warning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setErrorOpen(false)
                    }}
                  >
                    <CloseSharp fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {error?.message
                  ? error.message
                  : t('SYSCOMMON.something_went_wrong')}
              </Alert>
            </Collapse>
          </Box>
          <Box>
            {breadcrumbs && (
              <Breadcrumbs
                data-test-id="bread-crumbs"
                separator={<NavigateNext fontSize="medium" />}
                aria-label="breadcrumb"
                sx={{ mb: 1 }}
              >
                {breadcrumbs.map((item: BreadCrumb, index: number) => {
                  return item.to ? (
                    <Box
                      data-test-id="breadcrumb-item"
                      key={index}
                      sx={{
                        '& a': {
                          color: (theme) => theme.palette.primary.light,
                        },
                      }}
                    >
                      <Link color="inherit" to={item.to}>
                        {item.title}
                      </Link>
                    </Box>
                  ) : (
                    <Typography key={index}>{item.title}</Typography>
                  )
                })}
              </Breadcrumbs>
            )}
          </Box>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems="center"
            mt={{ xs: 2, md: 1 }}
          >
            {title && (
              <Typography
                data-test-id="title"
                variant="h2"
                noWrap
                sx={{ margin: 0, height: 26 }}
              >
                {title}
                {total !== 0 && total && (
                  <Typography
                    component={'span'}
                    variant="body1"
                    sx={{
                      fontSize: 14,
                      fontWeight: 500,
                      ml: 1,
                      color: '#999',
                    }}
                  >{`(${total})`}</Typography>
                )}
              </Typography>
            )}
            {actions ? <Box>{actions}</Box> : <Box sx={{ height: 36.5 }} />}
          </Stack>
        </Box>
      </Box>

      <Box
        sx={{
          px: spacingX,
          top: -40,
          position: 'relative',
          minHeight: 200,
        }}
      >
        {leftSideBar ? (
          <Box sx={{ position: 'relative', zIndex: 100 }}>
            <Grid container spacing={2} sx={{ height: '100%' }}>
              {/* Side menu */}
              <Grid item xs={12} md={isSidebarOpen ? 12 : 4} lg={4} xl={3}>
                {/* Render the ProjectMenu component */}
                {leftSideBar && leftSideBar}
              </Grid>
              <Grid item xs={12} md={isSidebarOpen ? 12 : 8} lg={8} xl={9}>
                {children}
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box sx={{ position: 'relative', zIndex: 100 }}>{children}</Box>
        )}
      </Box>
    </Box>
  )
}

export default DetailPageLayout
