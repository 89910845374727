import { Direction, FilterParams, MetaPage } from '@constants/common.constants'
import { TimeOffListFilter } from '@constants/timeoff.constants'
import { URI } from '@constants/uri.constants'
import { api } from './api'
import { PositionItem } from './resource.services'
import { EmployeeItem, EmployeeShortItem, ShortUserItem } from './user.services'

// TIME OFF TEMPLATE
export type TimeOffTemplateIdParams = string

export type TimeOffTemplateCreateParams = {
  name: string
  approval_template_id: string
  description?: string
  pay_status: string
  range_type: string
  identifier: string
  is_deduct_from_vacation: boolean
  is_manual: boolean
  is_attachment: boolean
  is_long_term_leave: boolean
  is_reason_allow: boolean
  is_show_time_report: boolean
}

export type TimeOffTemplateUpdateParams = {
  id: string
  params: TimeOffTemplateCreateParams
}

export type TimeOffTemplateListResponse = {
  data: TimeOffTemplateItem[]
  meta: MetaPage
}

export type TimeOffTemplateAllResponse = {
  data: TimeOffTemplateItem[]
}

export type TimeOffTemplateResponse = {
  data: TimeOffTemplateItem
}

export type TimeOffTemplateItem = {
  id: string
  type: 'timeoff_template'
  attributes: {
    name: string
    description?: string
    pay_status: string
    range_type: string
    identifier: string
    duration_min: number | null
    duration_max: number | null
    is_deduct_from_vacation: boolean
    is_request_send_later: boolean
    is_attachment: boolean
    is_long_term_leave: boolean
    is_reason_allow: boolean
    is_show_time_report: boolean
    approval_template: {
      data: ApprovalTemplateItem
    }
  }
}

export type TimeOffTemplateLogItem = {
  id: string
  type: 'timeoff_template_log'
  attributes: {
    name: string
    description?: string
    pay_status: string
    range_type: string
    identifier: string
    is_deduct_from_vacation: boolean
    is_request_send_later: boolean
    is_attachment: boolean
    is_long_term_leave: boolean
    is_reason_allow: boolean
    is_show_time_report: boolean
    timeoff_template_id: string
    approval_template: {
      data: ApprovalTemplateItem
    }
  }
}

export const timeOffTemplateListService = async (
  params: FilterParams
): Promise<TimeOffTemplateListResponse> => {
  const { data } = await api.get<TimeOffTemplateListResponse>(
    URI.TIME_OFF_TEMPLATE,
    { params }
  )
  return data
}

export const timeOffTemplateAllService =
  async (): Promise<TimeOffTemplateAllResponse> => {
    const { data } = await api.get<TimeOffTemplateAllResponse>(
      URI.TIME_OFF_TEMPLATE_ALL
    )
    return data
  }

export const timeOffTemplateCreateService = async (
  params: TimeOffTemplateCreateParams
): Promise<TimeOffTemplateResponse> => {
  const { data } = await api.post<TimeOffTemplateResponse>(
    URI.TIME_OFF_TEMPLATE,
    params
  )
  return data
}

export const timeOffTemplateDetailsService = async (
  id: TimeOffTemplateIdParams
): Promise<TimeOffTemplateResponse> => {
  const { data } = await api.get<TimeOffTemplateResponse>(
    `${URI.TIME_OFF_TEMPLATE}/${id}`
  )
  return data
}

export const timeOffTemplateUpdateService = async (
  arg: TimeOffTemplateUpdateParams
): Promise<TimeOffTemplateResponse> => {
  const { data } = await api.put<TimeOffTemplateResponse>(
    `${URI.TIME_OFF_TEMPLATE}/${arg.id}`,
    arg.params
  )
  return data
}

export const timeOffTemplateDeleteService = async (
  id: TimeOffTemplateIdParams
): Promise<TimeOffTemplateResponse> => {
  const { data } = await api.delete<TimeOffTemplateResponse>(
    `${URI.TIME_OFF_TEMPLATE}/${id}`
  )
  return data
}

// TIME OFF REQUEST
export type TimeOffRequestIdParams = string

export type TimeOffRequestCreateParams = {
  timeoff_template_id: string
  approver_id?: string
  is_break_morning?: boolean
  start_at?: string
  end_at?: string
  attachment_url?: string
  reason?: string
  compensatory_day_ids?: string[] | null
}

export type TimeOffRequestUpdateParams = {
  id: string
  params: TimeOffRequestCreateParams
}

export type TimeOffRequestListResponse = {
  data: TimeOffRequestItem[]
  meta: MetaPage
}

export type TimeOffRequestResponse = {
  data: TimeOffRequestItem
}

export type TimeOffRequestItem = {
  id: string
  type: 'timeoff_request'
  attributes: TimeOffRequestAttributes
}

// export type TimeOffApproveListResponse = {
//   data: TimeOffRequestApproveItem[]
//   meta: MetaPage
// }

export type TimeOffRequestApproveItem = {
  id: string
  type: 'timeoff_request'
  attributes: TimeOffRequestAttributes & {
    is_my_step: boolean
    is_allow_reason: boolean
  }
}

export type TimeOffRequestAttributes = {
  created_at?: string
  attachment_url?: string
  reason?: string
  request_type?: string
  start_at: string
  end_at: string
  status: string
  total_steps: number
  current_step?: number
  is_break_morning: boolean
  deleted_at?: string
  deleted_by: {
    data?: EmployeeItem
  }
  user: {
    data: EmployeeItem
  }
  timeoff_template_log: {
    data: TimeOffTemplateLogItem
  }
  compensatory_day: {
    data?: CompensatoryDayItem
  }
  timeoff_approvers: {
    data: TimeOffApproverItem[]
  }
}

export type TimeOffApproverItem = {
  id: string
  type: 'timeoff_approver'
  attributes: {
    step_index: number
    status: string
    reason: null | string
    approver: {
      data?: EmployeeItem
    }
    next_approver: {
      data?: EmployeeItem
    }
  }
}

export const timeOffRequestListService = async (
  params: TimeOffListFilter
): Promise<TimeOffRequestListResponse> => {
  const { data } = await api.get<TimeOffRequestListResponse>(
    URI.TIME_OFF_REQUEST,
    { params }
  )
  return data
}

export const timeOffRequestCreateService = async (
  params: TimeOffRequestCreateParams
): Promise<TimeOffRequestResponse> => {
  const { data } = await api.post<TimeOffRequestResponse>(
    URI.TIME_OFF_REQUEST,
    params
  )
  return data
}

export const timeOffRequestDetailsService = async (
  id: TimeOffRequestIdParams
): Promise<TimeOffRequestResponse> => {
  const { data } = await api.get<TimeOffRequestResponse>(
    URI.TIME_OFF_DETAIL.replace(/:id/gi, id)
  )
  return data
}

// export const timeOffRequestUpdateService = async (
//   arg: TimeOffRequestUpdateParams
// ): Promise<TimeOffRequestResponse> => {
//   const { data } = await api.put<TimeOffRequestResponse>(
//     `${URI.TIME_OFF_REQUEST}/${arg.id}`,
//     arg.params
//   )
//   return data
// }

export type TimeOffApproveShortAttributes = {
  start_at: string
  end_at: string
  status: string
  total_steps: number
  current_step?: number
  is_my_step: true
  is_break_morning: boolean
  duration: number
  user: {
    data: ShortUserItem
  }
  timeoff_template_log: {
    data: TimeOffTemplateShortLogItem
  }
}

export type TimeOffTemplateShortLogItem = {
  id: string
  type: 'timeoff_template_log_list'
  attributes: {
    name: string
    range_type: string
    approval_template: {
      data: ApprovalTemplateItem
    }
  }
}

export type TimeOffApproveShortItem = {
  id: string
  type: 'timeoff_request'
  attributes: TimeOffApproveShortAttributes
}

export type TimeOffApproveListShortResponse = {
  data: TimeOffApproveShortItem[]
  meta: MetaPage
}

export const timeOffRequestDeleteService = async (
  id: TimeOffRequestIdParams
): Promise<TimeOffRequestResponse> => {
  const { data } = await api.delete<TimeOffRequestResponse>(
    `${URI.TIME_OFF_REQUEST}/${id}`
  )
  return data
}

export const timeOffRequestDeleteByApproverService = async (
  id: TimeOffRequestIdParams
): Promise<TimeOffRequestResponse> => {
  const { data } = await api.delete<TimeOffRequestResponse>(
    URI.TIME_OFF_REQUEST_DELETE_BY_APPROVER.replace(/:id/gi, id)
  )
  return data
}

// Timeoff Request Approval
export type TimeOffApproveParams = {
  id: string
  params?: {
    approver_id: string
  }
}

export type TimeOffDenyParams = {
  id: string
  params: {
    reason?: string
  }
}

export const timeOffApproveListService = async (
  params: TimeOffListFilter
): Promise<TimeOffApproveListShortResponse> => {
  const { data } = await api.get<TimeOffApproveListShortResponse>(
    URI.TIME_OFF_APPROVE_LIST,
    { params }
  )
  return data
}
export const timeOffApproveService = async (
  args: TimeOffApproveParams
): Promise<void> => {
  const { data } = await api.post<void>(
    URI.TIME_OFF_APPROVE.replace(/:id/gi, args.id),
    args.params
  )
  return data
}
export const timeOffDenyService = async (
  params: TimeOffDenyParams
): Promise<void> => {
  const { data } = await api.post<void>(
    URI.TIME_OFF_DENY.replace(/:id/gi, params.id),
    params.params
  )
  return data
}

export type ApprovalTemplateIdParam = string

export type ApprovalTemplateDetailItem = {
  id: string
  type: 'approval_template_detail'
  attributes: {
    is_manual: boolean
    allow_reason: boolean
    step: number
    positions: {
      data: PositionItem[]
    }
  }
}

export type ApprovalTemplateItem = {
  id: string
  type: 'approval_template'
  attributes: {
    name: string
    status: string
    approval_template_details: { data: ApprovalTemplateDetailItem[] }
  }
}

export type ApprovalTemplateListResponse = {
  meta: MetaPage
  data: ApprovalTemplateItem[]
}

export type ApprovalTemplateAllResponse = {
  data: ApprovalTemplateItem[]
}

export type ApprovalTemplateCreateParams = {
  name: string
}

export type ApprovalTemplateUpdateParams = {
  id: string
  params: ApprovalTemplateCreateParams
}

export type ApprovalTemplateResponse = {
  data: ApprovalTemplateItem
}

export type ApprovalTemplateDetailListResponse = {
  data: ApprovalTemplateDetailItem[]
}

export type ApprovalTemplateDetailResponse = {
  data: ApprovalTemplateDetailItem
}

export type ApprovalTemplateDetailCreateParams = {
  id: string
  param: {
    is_manual: boolean
    position_ids: number[]
    allow_reason: boolean
  }
}

export type ApprovalTemplateDetailUpdateParams = {
  template_id: string
  detail_id: string
  param: {
    is_manual: boolean
    position_ids: number[]
    allow_reason: boolean
  }
}

export type ApprovalTemplateDetailDeleteParams = {
  template_id: string
  detail_id: string
}

export type ApprovalTemplateStepParams = {
  approval_template_details: {
    id: string
    step: number
  }[]
  approval_template_id: string
}

export const approvalTemplateListService = async (
  params: FilterParams
): Promise<ApprovalTemplateListResponse> => {
  const { data } = await api.get<ApprovalTemplateListResponse>(
    URI.APPROVAL_TEMPLATES,
    {
      params: params,
    }
  )
  return data
}

export const approvalTemplateAllService =
  async (): Promise<ApprovalTemplateAllResponse> => {
    const { data } = await api.get<ApprovalTemplateAllResponse>(
      URI.APPROVAL_TEMPLATES_ALL
    )
    return data
  }

export const approvalTemplateCreateService = async (
  params: ApprovalTemplateCreateParams
): Promise<ApprovalTemplateResponse> => {
  const { data } = await api.post<ApprovalTemplateResponse>(
    URI.APPROVAL_TEMPLATES,
    params
  )
  return data
}

export const approvalTemplateUpdateService = async (
  params: ApprovalTemplateUpdateParams
): Promise<ApprovalTemplateResponse> => {
  const { data } = await api.put<ApprovalTemplateResponse>(
    `${URI.APPROVAL_TEMPLATES}/${params.id}`,
    params.params
  )
  return data
}

export const approvalTemplateShowService = async (
  params: ApprovalTemplateIdParam
): Promise<ApprovalTemplateResponse> => {
  const { data } = await api.get<ApprovalTemplateResponse>(
    `${URI.APPROVAL_TEMPLATES}/${params}`
  )
  return data
}

export const approvalTemplateDeleteService = async (
  id: ApprovalTemplateIdParam
): Promise<ApprovalTemplateResponse> => {
  const { data } = await api.delete<ApprovalTemplateResponse>(
    `${URI.APPROVAL_TEMPLATES}/${id}`
  )
  return data
}

export const approvalTemplateDetailService = async (
  id: ApprovalTemplateIdParam
): Promise<ApprovalTemplateDetailListResponse> => {
  const { data } = await api.get<ApprovalTemplateDetailListResponse>(
    `${URI.APPROVAL_TEMPLATES}/${id}/approval_template_details`
  )
  return data
}

export const approvalTemplateDetailCreateService = async (
  params: ApprovalTemplateDetailCreateParams
): Promise<ApprovalTemplateDetailResponse> => {
  const { data } = await api.post<ApprovalTemplateDetailResponse>(
    `${URI.APPROVAL_TEMPLATES}/${params.id}/approval_template_details`,
    params.param
  )
  return data
}

export const approvalTemplateDetailUpdateService = async (
  params: ApprovalTemplateDetailUpdateParams
): Promise<ApprovalTemplateDetailResponse> => {
  const { data } = await api.put<ApprovalTemplateDetailResponse>(
    `${URI.APPROVAL_TEMPLATES}/${params.template_id}/approval_template_details/${params.detail_id}`,
    params.param
  )
  return data
}

export const approvalTemplateDetailDeleteService = async (
  params: ApprovalTemplateDetailDeleteParams
): Promise<ApprovalTemplateDetailResponse> => {
  const { data } = await api.delete<ApprovalTemplateDetailResponse>(
    `${URI.APPROVAL_TEMPLATES}/${params.template_id}/approval_template_details/${params.detail_id}`
  )
  return data
}

export const approvalTemplateUpdateStepService = async (
  params: ApprovalTemplateStepParams
): Promise<ApprovalTemplateDetailListResponse> => {
  const { data } = await api.put<ApprovalTemplateDetailListResponse>(
    `${URI.STEP_UPDATE}`,
    params
  )
  return data
}

// Compensatory day
export type CompensatoryDaysResponse = {
  data: CompensatoryDayItem[]
}
export type CompensatoryDayItem = {
  id: string
  type: 'compensatory_day'
  attributes: {
    earned_day: number
    expire_at: string
    remaining_day: number
    timeoff_requests: {
      data: TimeOffRequestItem
    }
  }
}

export const compensatoryDaysService =
  async (): Promise<CompensatoryDaysResponse> => {
    const { data } = await api.get<CompensatoryDaysResponse>(
      URI.COMPENSATORY_DAYS
    )
    return data
  }

export type UserCompensatoryItem = {
  id: string
  type: 'user_compensatory_day'
  attributes: {
    earned_day: number
    remaining_day: number
    comment: string | null
    expire_at: string
    created_at: string
    user: {
      data: EmployeeShortItem
    }
    action_by: {
      data: EmployeeShortItem
    } | null
    timeoff_duration: number
    timeoff_end_at: string
    timeoff_name: string
    timeoff_start_at: string
  }
}

export type UserCompensatoryDaysListResponse = {
  data: UserCompensatoryItem[]
  meta: MetaPage
}

export const userCompensatoryDaysListService = async (
  params: FilterParams
): Promise<UserCompensatoryDaysListResponse> => {
  const { data } = await api.get<UserCompensatoryDaysListResponse>(
    `${URI.USER_COMPENSATORY_LIST}`,
    { params: params }
  )
  return data
}

export type UserCompensatoryDaysUpdateParams = {
  id: string
  params: {
    comment: string
    expire_at: Date
  }
}

export type UserCompensatoryDaysUpdateResponse = {
  data: UserCompensatoryItem
}

export const userCompensatoryDaysUpdateService = async (
  params: UserCompensatoryDaysUpdateParams
): Promise<UserCompensatoryDaysUpdateResponse> => {
  const { data } = await api.put<UserCompensatoryDaysUpdateResponse>(
    `${URI.USER_COMPENSATORY_LIST}/${params.id}`,
    params.params
  )
  return data
}

export type TimeOffTemplateShortItem = {
  id: string
  type: 'timeoff_template_short'
  attributes: {
    name: string
    description: string
  }
}

export type TimeOffReportTemplateListResponse = {
  data: TimeOffTemplateShortItem[]
}

export const timeOffReportTemplateAllService =
  async (): Promise<TimeOffReportTemplateListResponse> => {
    const { data } = await api.get<TimeOffReportTemplateListResponse>(
      URI.TIME_OFF_REPORT_TEMPLATE_LIST
    )
    return data
  }

export type TimeOffReportItem = {
  user_code: string
  name: string
  value: number
}

export type TimeOffReportResponse = {
  data: TimeOffReportItem[]
  meta: { meta: MetaPage }
}

export type TimeOffReportParams = {
  order_by: string
  direction: Direction
  per_page: string
  page: string
  start_at: Date
  end_at: Date
  timeoff_template_id: string
}

export const timeOffReportService = async (
  params: TimeOffReportParams
): Promise<TimeOffReportResponse> => {
  const { data } = await api.get<TimeOffReportResponse>(
    `${URI.TIME_OFF_REPORT_LIST}`,
    { params: params }
  )
  return data
}

export type TimeOffReportTotalParam = {
  months: string[]
  timeoff_templates: number[]
}

export type TimeOffReportTotalItem = {
  month: string
  data: [
    {
      timeoff_template_id: number
      timeoff_template_name: string
      total_count: number
    }
  ]
}
export type TimeOffReportTotalReponse = {
  data: TimeOffReportTotalItem[]
}

export const timeOffReportTotalService = async (
  params: TimeOffReportTotalParam
): Promise<TimeOffReportTotalReponse> => {
  const { data } = await api.get<TimeOffReportTotalReponse>(
    `${URI.TIME_OFF_REPORT_TOTAL_LIST}.json`,
    { params: params }
  )
  return data
}
export const timeOffReportTotalDownloadService = async (
  params: TimeOffReportTotalParam
): Promise<Blob> => {
  const { data } = await api.get<Blob>(
    `${URI.TIME_OFF_REPORT_TOTAL_LIST}.xlsx`,
    {
      params: params,
      responseType: 'blob',
    }
  )
  return data
}
