/* eslint-disable no-console */
import React, { useState, FC, useEffect } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  useMediaQuery,
  IconButton,
  Alert,
} from '@mui/material'
import { Close, CropFree, PlayArrow, Stop } from '@mui/icons-material'
import useCurrentTime from '@utils/hooks/useCurrentTime'
import _ from 'lodash'
import QrReader from './index'
import useTimeRecord from '@containers/@modules/Time/useTimeRecord'
import { WORK_TIME_ACTION } from '@constants/time.constants'
import { useTranslation } from 'react-i18next'
import useToast from '@utils/hooks/useToast'
import { TimeQrParams } from '@services/time.services'
import theme from '@theme/index'

export type QrDialogProps = {
  isDenyWorkStart: boolean
}

const QrDialog: FC<QrDialogProps> = ({ isDenyWorkStart }) => {
  const [open, setOpen] = useState<WORK_TIME_ACTION | null>(null)
  const [processing, setProcessing] = useState<string | null>(null)
  const [error, setError] = useState<any>(null)
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { addToast } = useToast()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const { isWorkInProgress, isWorkNotStarted } = useCurrentTime()

  const { startQrTime, endQrTime } = useTimeRecord()

  const handleClose = () => {
    setOpen(null)
  }

  useEffect(() => {
    setProcessing(null)
    setError(null)
  }, [open])

  const parseJSON = (jsonString: string) => {
    return new Promise((resolve, reject) => {
      try {
        const jsonObject = JSON.parse(jsonString)
        resolve(jsonObject)
      } catch (error) {
        reject(error)
      }
    })
  }

  useEffect(() => {
    if (!_.isEmpty(processing) && !_.isNull(processing)) {
      parseJSON(processing)
        .then((jsonObject) => {
          if (open === WORK_TIME_ACTION.start) {
            startQrTime(jsonObject as TimeQrParams)
              .unwrap()
              .then(() => setOpen(null))
              .catch((err: any) => {
                setError(err)
              })
          } else if (open === WORK_TIME_ACTION.end) {
            endQrTime(jsonObject as TimeQrParams)
              .unwrap()
              .then(() => setOpen(null))
              .catch((err: any) => {
                setError(err)
              })
          }
        })
        .catch(() => {
          // addToast({ message: t('WORKSPACE.invalid_qr'), severity: 'error' })
        })
    }
  }, [processing])

  const handleSuccess = (result: string) => {
    setProcessing(result)
  }

  const renderAttendanceButton = () => {
    if (isWorkNotStarted && !isDenyWorkStart) {
      return (
        <Box
          onClick={() => setOpen(WORK_TIME_ACTION.start)}
          sx={{ position: 'relative', width: 36, height: 36 }}
        >
          <PlayArrow
            sx={{
              position: 'absolute',
              top: 6,
              left: 6,
              color: (theme) => theme.palette.success.main,
            }}
          />
          <CropFree
            sx={{ fontSize: 36, color: (theme) => theme.palette.text.primary }}
          />
        </Box>
      )
    } else if (isWorkInProgress) {
      return (
        <Box
          onClick={() => setOpen(WORK_TIME_ACTION.end)}
          sx={{ position: 'relative', width: 36, height: 36 }}
        >
          <Stop
            sx={{
              position: 'absolute',
              top: 6,
              left: 6,
              color: (theme) => theme.palette.error.main,
            }}
          />
          <CropFree
            sx={{ fontSize: 36, color: (theme) => theme.palette.text.primary }}
          />
        </Box>
      )
    }
    return null
  }

  return (
    <div>
      {renderAttendanceButton()}
      <Dialog
        open={open ? true : false}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        fullScreen={isMobile}
      >
        <DialogTitle>
          {t('WORKSPACE.qr_title')}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 4,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {open && (
            <QrReader
              onSuccess={handleSuccess}
              onFail={(err) => {
                // eslint-disable-next-line no-console
                console.log(err)
              }}
            />
          )}
          <Box sx={{ mt: 1 }}>
            {error &&
              (error.error && error.error.message ? (
                <Alert severity="error" variant="outlined">
                  {t(`ERROR.${error.error.message}`)}
                </Alert>
              ) : (
                <Alert severity="error" variant="outlined">
                  {t('TOASTS.unknown_rejected')}
                </Alert>
              ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('SYSCOMMON.close')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default QrDialog
