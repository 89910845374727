import { EventTypes, FREQUENCY } from '@constants/common.constants'
import {
  EventResponse,
  MeetingRoomOrderResponse,
} from '@services/settings.services'
import { getShortName } from '@utils/helper/common.helper'
import _ from 'lodash'
import { convertDateToLocalSystem } from '@utils/helper/time.helper'
import { AppInit } from '@services/common.services'

export interface MergedEventType {
  id: string
  name: string
  description?: string | null
  type: EventTypes
  start: Date
  end: Date
  user?: {
    id: string
    avatar: string
    usercode: string
  }
}

export interface MeetingRoomOrderType {
  id: string
  name: string
  start: Date
  end: Date
  meeting_room_id: string
  created_by: number
  frequency: FREQUENCY
  interval?: number
  days_of_week?: number[]
  days_of_month?: number[]
  expire_date?: string | null
}

export const eventMerger = (
  res: EventResponse,
  appInit?: AppInit
): MergedEventType[] => {
  if (res === undefined) {
    return []
  }

  const currentYear = new Date().getFullYear()

  const generateBirthdayEvents = (
    data: any,
    years: number
  ): MergedEventType[] => {
    const item = data.attributes
    const birthday = new Date(item.birthday)
    const birthdayEvents: MergedEventType[] = []

    for (let i = 0; i < years; i++) {
      const date = new Date(
        currentYear + i,
        birthday.getMonth(),
        birthday.getDate()
      )
      date.setHours(0, 0, 0, 0) // Set start time to beginning of the day

      const endDate = new Date(date)
      endDate.setHours(23, 59, 59, 999) // Set end time to end of the day

      birthdayEvents.push({
        id: data.id + `_${i}`,
        name: getShortName(undefined, item?.firstname_en, item?.lastname_en),
        type: EventTypes.Birthday,
        start: date,
        end: endDate,
        user: {
          id: data.id,
          avatar: item.avatar || '',
          usercode: item.user_code,
        },
      })
    }

    return birthdayEvents
  }

  const birthdays: MergedEventType[] = res.birthday
    ? _.flatMap(res.birthday.data, (data) => {
        return generateBirthdayEvents(data, 3)
      })
    : []

  const events: MergedEventType[] = _.map(res.events.data, (data) => {
    const item = data.attributes

    return {
      id: data.id,
      name: item?.name,
      type: item.holiday_flag ? EventTypes.Holiday : EventTypes.Event,
      start: convertDateToLocalSystem(
        item?.start_date,
        _.get(appInit, 'attributes.system_timezone')
      ),
      end: convertDateToLocalSystem(
        item?.end_date,
        _.get(appInit, 'attributes.system_timezone')
      ),
      // start: item.start_date,
      // end: item.end_date,
      description: item.description,
    }
  })

  const mergedEvents: MergedEventType[] = [...events, ...birthdays]
  const sortedMergedEvents = mergedEvents

  return sortedMergedEvents
}

export const meetingRoomOrdersAttParser = (
  res?: MeetingRoomOrderResponse[]
): MeetingRoomOrderType[] => {
  if (!res && _.isEmpty(res)) {
    return []
  }
  const meetingRoomOrders: MeetingRoomOrderType[] = _.map(res, (item) => {
    const data = item
    return {
      id: data.id,
      name: data.attributes.subject,
      end: new Date(data.attributes.end_time),
      start: new Date(data.attributes.start_time),
      meeting_room_id: data.attributes.meeting_room_id,
      created_by: data.attributes.created_by,
      frequency: data.attributes.frequency,
      interval: data.attributes.recurrence_rule?.interval,
      days_of_week: data.attributes.recurrence_rule?.days_of_week,
      days_of_month: data.attributes.recurrence_rule?.days_of_month,
      expire_date: data.attributes?.expire_date,
    }
  })
  return meetingRoomOrders
}
