// English
export default {
  SYSCOMMON: {
    number_no: '№',
    yes: 'Yes',
    no: 'No',
    apply: 'Apply',
    confirm: 'OK',
    cancel: 'Cancel',
    save: 'Save',
    dashboard: 'Dashboard',
    project_management: 'Project Management',
    task_management: 'Task Management',
    projects: 'Project',
    gantt_chart: 'Gantt chart',
    sprint: 'Sprint',
    tasks: 'Tasks',
    kanban_board: 'Kanban Board',
    time_management: 'Time management',
    time_requests: 'Time requests',
    human_resource: 'Resource Management',
    resource_management: 'Human resource',
    skill_management: 'Skill Management',
    welcome: 'Welcome',
    milestone: 'Milestone',
    timesheet: 'Timesheet',
    teamwork: 'Team Work',
    members: 'Members',
    employees: 'Employee',
    notifications: 'Notifications',
    view_all: 'View All',
    im_not_robot: "I'm not a robot",
    partner_company: 'Partner Company',
    create: 'Create',
    name: 'Name',
    description: 'Description',
    back: 'Back',
    search: 'Search',
    delete: 'Delete',
    add: 'Add',
    edit: 'Edit',
    manage: 'Manage',
    project_list: 'Project List',
    start_date: 'Start date',
    end_date: 'End date',
    image: 'image',
    role: 'Role',
    member: 'Member',
    settings: 'Settings',
    update: 'Update',
    actions: 'Actions',
    delete_modal_title: 'Are you sure?',
    write: 'Write',
    read: 'Read',
    no_data: 'No results found',
    something_went_wrong: 'Something went wrong',
    additional_confirm_label: 'To confirm this action, type {{confirm}}',
    search_keyword: 'Search',
    filter_confirm: 'Confirm',
    id: 'ID',
    back_to_list: 'Back to list',
    no_resources: 'No {{resources}} ',
    no_matches: 'No matches',
    loading_resource: 'Loading {{resources}}',
    data: 'data',
    clear: 'Clear',
    cant_find: "We can't find a match.",
    copyright: 'Copyright TeamSync® ©',
    copy: 'Copy',
    copied: 'Copied',
    date: 'Date',
    company: 'Company',
    gender_male: 'Male',
    gender_female: 'Female',
    gender_other: 'Other',
    back_confirm_title: 'It looks like you have been editing something.',
    back_confirm_desc: 'If you leave before saving, your changes will be lost.',
    user_data:
      '  User data is corrupted. Please contact the admin for assistance.',
    contact_admin: 'Contact Admin via',
    failed_to_init_app: 'Failed to Initialize Application',
    sign_out_to_home: 'Sign out and go Home',
    not_found_code: '404',
    permission_denied_code: '403',
    denied_title: 'Permission Denied',
    denied_desc: "You don't have permission to access this page.",
    not_found_title: 'Page Not Found',
    not_found_desc:
      ' The page you are looking for does not exist or has been moved.',
    not_available_code: '503',
    not_available_title: 'Service is currently not available',
    go_to_home: 'Go to Home',
    details: 'Details',
    calendar: 'Calendar',
    event_list: 'Events',
    events: 'Event',
    list: 'List',
    categories: 'Categories',
    event: 'Event',
    download: 'Download',
    home: 'Home',
    skill_report: 'Skill Report',
    order_direction: 'Order Direction',
    order_by: 'Order By',
    order_by_desc: 'Change list order by selecting column.',
    created_date: 'Created Date',
    confirmed_date: 'Confirmed Date',
    status: 'Status',
    division_lead: 'Division Lead',
    filter: 'Filter',
    reports: 'Reports',
    skill_list: 'Skill',
    skill_categories: 'Skill Category',
    back_to_login: 'Back to login',
    page_size: 'Page size',
    order_asc: 'Ascending',
    order_desc: 'Descending',
    default: 'Default',
    change_order_page_title: 'Change {{item}} Order',
    change_order_breadcrumb_title: 'Change Order',
    update_order_modal_title: 'Are you sure you want to change the order?',
    update_order_modal_description: 'This process cannot be undone.',
    select_file: 'Select File',
    reset: 'Reset',
    day: '',
    other: 'Other',
    next: 'Next',
    send: 'Send',
    duration_day: ' day',
    duration_hour: ' hour',
    duration_days: ' days',
    duration_hours: ' hours',
    duration_min: ' min',
    duration_mins: ' mins',
    duration_am: 'AM',
    duration_pm: 'PM',
    template_settings: 'Template settings',
    time_off: 'Time off',
    hr_related: 'HR related',
    configuration: 'Configuration',
    more: 'More',
    remote_work: 'Remote work',
    reason: 'Reason',
    close: 'Close',
    tag: 'Type of work',
  },
  MODULES: {
    partner_company: 'Partner company',
    projects: 'Projects',
    report: 'Report',
    resource: 'Resource',
    settings: 'Settings',
    email: 'Email',
    time: 'Time Management',
    time_settings: 'Time Management Settings',
    remote: 'Remote work',
    resource_planning: 'Resource planning',
  },
  HOME: {
    home: 'Home',
    features: 'Features',
    tel: 'Tel',
    faq: 'Frequently asked questions',
    pricing: 'Pricing',
    contact: 'Contact',
    sign_in: 'Sign In',
    go_to_dashboard: 'Go to Dashboard',
    additional_content: 'Some additional content',
    intro_title: 'Time is gold, manage it wisely',
    intro_subtitle: 'Boost Team Productivity with Teamsync',
    intro_description1:
      'A mobile-friendly web platform for managing work hours, projects, resources, and time-off requests, built on modern technology and refined over 10+ years of internal use.',
    book_now: 'Book now',
    try_now: 'Try now',
    learn_more: 'Learn More',
    why_choose_us_title: 'Why Teamsync? Because we see time as an opportunity.',
    why: 'Why choose us',
    why_choose_us_description: `Our web platform is the perfect solution to streamline your business operations. It's a one-stop-shop for managing employee attendance, project management, resource allocation, time-off requests, and daily reporting – all in one place.

You can access all these features from any device – smartphone, tablet, or computer – without any extra hardware. Our website is fully responsive, ensuring a seamless experience across all screen sizes. We've fine-tuned this platform over 10+ years of use in our own company, so it's ready to meet all your business needs.
`,
    features_title: 'All Features',
    hr: 'HR',
    employee: 'employee',
    privacy_policy: 'Privacy policy',
    terms_of_condition: 'Terms of condition',
    support: 'Support',
    about: 'About Us',
    contact_us: 'Contact Us',
    email: 'Email',
    teamsync: 'Teamsync Platform',
    help: 'Help',
    more: 'More',
  },
  DASHBOARD: {
    hr: 'Administration Human Resources',
    se: 'Software engineer manager',
    operator: 'Operator',
    intern: 'Internship',
    developer: 'Developer',
    ceo: 'CEO (chief executive officer)',
    spg: 'Senior software engineer',
    designer: 'Designer/Front-end',
    total: 'Total',
    employees: 'Employees',
    projects: 'Projects',
    company_overview: 'Company Overview',
    upcoming_events: 'Upcoming Events',
    events_btn: 'Events',
    no_events: 'No Upcoming Events...',
    vacation_status: 'My Vacation Status',
    employees_status: 'Employees Status',
    daily_report_overview: 'Time spent overview',
    non_time_spent: 'Non time spent projects',
    no_data: 'No data',
    from_office: 'from Office',
    from_remote: 'from Remote',
  },
  LOGIN: {
    title: 'Sign In',
    slogan: 'All in one solution for your team and projects',
    email: 'Email',
    password: 'Password',
    remember_me: 'Remember me',
    forgot_password: 'Forgot Password?',
    login: 'Login',
    or_sign_in_with: 'Or sign in with',
    ERROR: {
      E000001: 'Login Failed',
    },
  },
  REQUIRED_CHANGE_PASSWORD: {
    title: 'Change Temporary Password',
    description:
      'Welcome to our platform! To ensure the security of your account, you need to change your temporary password.',
  },
  CALENDAR: {
    allDay: 'All Day',
    prev: 'Previous',
    next: 'Next',
    today: 'Today',
    month: 'Month',
    week: 'Week',
    day: 'Day',
    agenda: 'List',
    no_events: 'There are no events in this range.',
  },
  EVENT: {
    holiday: 'Holiday',
    event: 'Event',
    birthday: 'Birthday',
    happy_birthday: 'Happy Birthday',
    name_placeholder: 'Event name',
    description_placeholder: 'Event description',
    start: 'Start Date',
    end: 'End Date',
    details: 'Details',
    today: 'Today',
    remove: 'Delete event',
    delete_modal_title: 'Delete event',
    delete_modal_description: 'Are you sure you want to delete this event',
    create: 'Create Event',
    create_success_title: 'New event added',
    create_success_desc:
      'New event added with id: {{id}} and with name: {{name}}',
    show_holiday: 'View All holidays',
  },
  FORGOT_PASSWORD: {
    title: 'Forgot password?',
    description: 'Enter your email to receive a code',
    email: 'Email',
    back: 'Back to sign in',
    send: 'Send',
  },
  VERIFY_CODE: {
    title: 'Verification',
    description: 'Check your email and enter the code',
    no_code_received: "Didn't receive a code?",
    resend: 'Resend',
    back: 'Back to sign in',
    send: 'Send',
    code: 'Code',
  },
  RESET_PASSWORD: {
    title: 'Set new password',
    description:
      'Your new password must be different to previously used passwords',
    new_password: 'New Password',
    confirm_new_password: 'Confirm New Password',
    reset_password: 'Reset Password',
    password: 'Password',
  },
  USER_MENU: {
    profile: 'Profile',
    skill: 'My Skill',
    logout: 'Log Out',
  },
  PROFILE: {
    fullname: 'Full Name',
    position: 'Position',
    department: 'Department',
    my_phone_number: 'Phone number',
    phone_number: 'Phone',
    relation: 'Relation',
    email: 'Email',
    birthday: 'Birthday',
    edit_profile: 'Edit Profile',
    projects_tab: 'Active Projects',
    skills_tab: 'Skills',
    settings_tab: 'Settings',
    project_history_tab: 'Project History',
    picture: 'Profile picture',
    additional_phone_numbers: 'Additional Phone Numbers',
    add_phone: 'Add Phone',
    user_code: 'User Code',
    no_projects: 'No Projects',
    total_projects: 'Total Projects: ',
    total_cost: 'Total Cost: ',
    home_address: 'Home address',
    system_role: 'System role',
  },
  SETTINGS: {
    password: {
      title: 'Password',
      change_password: 'Change Password',
      old_password: 'Old Password',
      new_password: 'New Password',
      confirm_password: 'Confirm Password',
      confirmation_dialog_title: 'Are you sure?',
      confirmation_dialog_description: 'Your password is going to be changed.',
      confirmation_dialog_confirmation_text: 'Yes',
      confirmation_dialog_cancellation_text: 'No',
    },
    clear: {
      confirmation_dialog_title: 'Are you sure you want to clear all filters?',
      confirmation_dialog_description:
        'You will return all filter settings across all list pages to their default values',
    },
    filter_options: 'Filter Settings',
    filter_desc:
      "By clicking the 'Reset All Filters' button below, you will return all filter settings across all list pages to their default values. This action will clear any changes you've made to filter options such as sorting preferences, and page sizes. ",
    clear_filters: 'Reset All Filters',
    allow_location_title: 'Location Permission',
    my_location: 'My location coordinates are: ',
    latitude: 'Latitude: ',
    longitude: 'Longitude: ',
    denied_instruction_first:
      'You have already denied location permission before. Please reset browser permission',
    denied_instruction_second:
      "Next time your browser requests permission, please click 'Allow on every visit or Allow'.",
    undefined_instruction_first:
      "Click on the icon located at the top right of the address bar, asshown in the image. Then, click 'Allow' to grant permission.",
    undefined_instruction_second:
      "Next time your browser requests permission, please click 'Allow on every visit or Allow'",
    permission_instruction:
      "We do not store or utilize your location information for any purpose other than determining whether you've begun or ended work at our main office or a remote location. Your privacy is important to us, and we respect your data.",
  },
  CONFIRM: {
    logout_desc: 'Are you sure you want to log out?',
    logout_title: 'Trying to logout',
  },
  COMPANY: {
    companies: 'Partner Company',
    create_company: 'Create Company',
    name_placeholder: 'Company name',
    description_placeholder: 'Company description',
    list_page_title: 'Partner Company List',
    create: 'New Partner Company',
    title: 'Partner Company',
    no_column: 'No',
    name_column: 'Name',
    description_column: 'Description',
    divisions_column: 'Divisions',
    projects_column: 'Projects',
    actions_column: 'Actions',
    delete_modal_title: 'Are you sure?',
    delete_modal_description:
      'Do you really want to delete selected partner company? This process cannot be undone.',
    overview_page_title: 'Overview',
    you_have: 'The company has',
    divisions: 'divisions',
    projects: 'projects',
    create_success_title: 'New company added',
    create_success_desc: 'New company added with name: {{name}}',
    no_division: 'This company has no division yet.',
    save_confirm_title: 'Confirm Save Partner company Details',
    save_confirm_desc:
      'Do you wish to proceed? Make sure all details are accurate before saving.',
  },
  PARTNER_DIVISION: {
    list_page_title: 'Partner Division List',
    name_column: 'Name',
    no_column: 'No',
    projects_column: 'Projects',
    description_column: 'Description',
    actions_column: 'Actions',
    lead_column: 'Lead',
    division_name: 'Division Name',
    delete_modal_title: 'Are you sure?',
    delete_modal_description:
      'Do you really want to delete selected division? This process cannot be undone.',
    company_name_column: 'Company Name',
    division_create: 'Division Create',
    lead: 'Division Lead',
    company_name: 'Company Name',
    divisions: 'Partner Division',
    divisions_edit: 'Division Edit',
    name_placeholder: 'Division Name',
    description_placeholder: 'Division description',
    lead_placeholder: 'Lead name',
    create_success_title: 'New division added',
    create_success_desc:
      'New division added with id: {{id}} and with name: {{name}}',
    save_confirm_title: 'Confirm Save Partner division Details',
    save_confirm_desc:
      'Do you wish to proceed? Make sure all details are accurate before saving.',
    display_order: 'Display Order',
    order: 'Order',
  },
  PROJECT: {
    title: 'Project list',
    resource: 'Projects',
    list_title: 'Project list',
    project_name: 'Project Name',
    description_placeholder: 'Project description',
    description: 'Description',
    members: 'Members',
    member_management: 'Member Management',
    add_member: 'Add Member',
    manage_access: 'Manage access',
    status: 'Status',
    project_status: 'Project status',
    member_remove: 'Member Remove',
    cost_start: 'Cost start',
    cost_end: 'Cost end',
    partner_company: 'Partner Company',
    partner_division: 'Partner Division',
    cost: 'Cost',
    expire: 'Expire at',
    created_at: 'Created at',
    search_with_name_email: 'Search with name or email',
    member_delete_title: 'Project Member delete',
    member_delete_desc: 'Are you sure you want to delete this member?',
    member_add_title: 'Project Member Added',
    member_add_desc: '{{name}} has been added',
    edit_member: 'Edit member',
    report_title: 'Report',
    cost_report_title: 'Cost Report',
    role: 'Role',
    edit_help_title: 'Project Edit',
    member_help_title: 'Project Edit',
    report_help_title: 'Report',
    featured_image: 'Featured Image',
    settings: 'Project Settings',
    delete: 'Project delete',
    delete_modal_title: 'Are you sure you want to delete this project?',
    delete_modal_description:
      'Do you wish to proceed? This process cannot be undone.',
    cost_plan: 'Cost Plan',
    overview: 'Overview',
    no_description:
      'Welcome to this exciting project! While there might not be a detailed description here yet',
    total: 'Total: ',
    total_members: 'Total Members:',
    project: 'Project',
    total_cost: 'Total Cost',
    count: 'Count',
    create_title: 'Project Create',
    create_success_title: 'New project added',
    create_success_desc:
      'New project added with id: {{id}} and with name: {{name}}',
    owner: 'Owner',
    role_member: 'Member',
    role_manager: 'Manager',
    history: 'Activity History',
    quit_status: 'Quit',
    project_type: 'Project Type',
    updated_at: 'Updated date',
    status_on_hold: 'On Hold',
    status_new: 'New',
    status_in_progress: 'Working',
    status_completed: 'Completed',
    type_research: 'Research',
    type_new_project: 'New Project',
    type_maintenance: 'Maintenance',
    type_renewal: 'Renewal',
    read_owner_warning_title: "Can't edit",
    read_owner_warning_msg:
      'Although you are the owner of the project, you cannot edit things as your permission.',
    read_owner_warning_desc:
      'Please contact system administrator for permission update.',
    member_add_pre_dialog_title: 'The following member will be added',
    member_update_pre_dialog_title:
      'The member will be updated as the following',
    member_upsert_dialog: {
      project_name: 'Project Name:',
      name: 'Name:',
      role: 'Role:',
      position: 'Position:',
      cost: 'Cost:',
      cost_start_date: 'Cost Start Date:',
      expire_date: 'Expire Date:',
      start_date: 'Start Date:',
      end_date: 'End Date:',
    },
    save_confirm_title: 'Confirm Save Project Details',
    save_confirm_desc:
      'Do you wish to proceed? Make sure all details are accurate before saving.',
    joined: 'Joined',
    cost_changed: 'Cost changed',
    role_changed: 'Role changed',
    left: 'Left',
    project_started: 'Project started',
    cost_started: 'Cost started',
    cost_ended: 'Cost ended',
    project_ended: 'Project ended',
    project_created: 'Project created',
    cost_start_at_changed: 'Cost start at changed',
    project_name_changed: 'Project name changed',
    history_dialog_title: 'Confirm Removal of Cost History',
    history_dialog_desc:
      'Remove unwanted cost history. Please verify before proceeding',
    partner_purchase_order: 'Partner Purchase Order',
    filter_project_dialog_title: 'Filter Project',
    employee_filter: 'Employee',
    division_filter: 'Division',
    still_active: 'Still active',
    start_at_changed: 'Start at changed',
    member_start_at: 'Start date',
    member_expire_at: 'Expire date',
  },
  PROJECT_ACTIVITY: {
    joined: 'has joined with the role {{role}}',
    something_changed: '{{what}} changed from {{from}} to {{to}}',
    left: 'has left project.',
    project_started: 'Project start date',
    cost_started: 'Cost start date',
    cost_ended: 'Cost end date',
    project_ended: 'Project end date',
    project_created: 'Project created',
    cost_changed: 'member cost',
    performed_by: 'Performed by',
    role_changed: 'Role',
    cost_start_at_changed: 'Cost start date',
    unspecified: 'Not Specified',
    project_name_changed: 'Project name',
    start_at_changed: 'start at changed',
    member_filter_placeholder: 'Filter by member',
    action_filter_placeholder: 'Filter by action',
  },
  PROJECT_PARTNER_PURCHASE_ORDER: {
    title: 'Partner Purchase Order',
    add_title: 'Add Partner Purchase Order',
    position: 'Position',
    create_success_title: 'New Partner Purchase Order added',
    create_success_desc:
      'New Partner Purchase Order added with position: {{position}}',
    save_confirm_title: 'Confirm Save Partner Purchase Order Details',
    save_confirm_desc:
      'Do you wish to proceed? Make sure all details are accurate before saving.',
    delete_modal_title:
      'Are you sure you want to delete this partner purchase order?',
    delete_modal_description:
      'Do you wish to proceed? This process cannot be undone.',
  },
  POSITION: {
    menu_title: 'Position',
    page_title: 'Positions',
    list_title: 'Position list',
    position_name: 'Position name',
    no_column: 'No',
    name_column: 'Name',
    visibility_column: 'Visibility',
    hidden: 'Hidden',
    shown: 'Shown',
    members: 'Members',
    create_title: 'Create Position',
    delete_modal_title: 'Are you sure you want to delete this position?',
    delete_modal_description:
      'Do you really want to delete selected position? This process cannot be undone',
    create_success_title: 'New position added',
    create_success_desc:
      'New position added with id: {{id}} and with name: {{name}}',
    save_confirm_title: 'Confirm Save Position Details',
    save_confirm_desc:
      'Do you wish to proceed? Make sure all details are accurate before saving.',
    change_order: 'Change Order',
    order_title: 'Change Order',
    display_order: 'Display Order',
    order: 'Order',
    short_name: 'Short Name',
    is_default_filter: 'Filter from resource plan',
    is_default_filter_desc:
      'If this box is checked, users with this position will not be automatically included in the resource plan list.',
  },
  ROLE: {
    menu_title: 'Role',
    list: 'Role List',
    create_role: 'Create Role',
    no_column: 'No',
    name_column: 'Name',
    actions_column: 'Actions',
    delete_modal_title: 'Are you sure you want to delete this role?',
    delete_modal_description:
      'Do you really want to delete selected role? This process cannot be undone.',
    name_placeholder: 'Role name',
    resource_management: 'Resource Managment',
    time_management: 'Time Managment',
    division: 'Division',
    project_management: 'Project Managment',
    task_management: 'Task Managment',
    create_success_title: 'New role added',
    create_success_desc:
      'New role added with id: {{id}} and with name: {{name}}',
    create_duplicate_title: 'Do you want to copy this role before creating?',
    create_duplicate_desc:
      'All role permission will be copied before creating.',
    copy_of: 'This is copy of: {{name}}',
    copy_text: 'This is copy of ',
    save_confirm_title: 'Confirm Save Role Details',
    save_confirm_desc:
      'Do you wish to proceed? Make sure all details are accurate before saving.',
    write: 'Write',
    read: 'Read',
    admin: 'Admin',
  },
  EMPLOYEE: {
    list: 'Employee list',
    create_page_title: 'Create Employee',
    edit_page_title: 'Edit Employee information',
    firstname: 'Firstname',
    firstname_placeholder: 'Employee firstname',
    firstname_en: 'Firstname EN',
    firstname_en_placeholder: 'Employee EN firstname',
    lastname: 'Lastname',
    lastname_placeholder: 'Employee lastname',
    lastname_en: 'Lastname EN',
    lastname_en_placeholder: 'Employee EN lastname',
    employee_name: 'Employee name',
    email: 'Email',
    email_placeholder: 'Employee email',
    user_code: 'User code',
    user_code_placeholder: 'Employee user code',
    gender: 'Gender',
    position: 'Position',
    department: 'Department',
    name_placeholder: 'Employee name',
    phone_placeholder: 'Employee phone',
    create_success_title: 'New employee added',
    create_success_desc: 'New employee added with name: {{name}}',
    edit_success_title: 'Employee information updated',
    edit_success_desc: 'The following employee updated with name: {{name}}',
    resource: 'Employees',
    name_column: 'Name',
    user_code_column: 'User Code',
    phone_column: 'Phone',
    gender_column: 'Gender',
    status_column: 'Status',
    role_column: 'Role',
    position_column: 'Position',
    department_column: 'Department',
    actions_column: 'Actions',
    status: 'Status',
    status_deleted: 'Deleted',
    status_active: 'Active',
    status_maternity_leave: 'Maternity Leave',
    status_quit: 'Quit',
    status_superadmin: 'Super Admin',
    role: 'Role',
    relation: 'Relation',
    delete_modal_title: 'Are you sure?',
    delete_modal_description:
      'Do you really want to delete selected employee? This process cannot be undone.',
    skills_tab: 'Skills',
    project_history_tab: 'Project History',
    projects_tab: 'Active Projects',
    project_history_empty: `This user hasn't joined any project yet.`,
    ndsh_months: 'NDSH Months',
    hired_date: 'Hired Date',
    quit_date: 'Quit Date',
    filter_employees_dialog_title: 'Filter Employees',
    quit_modal_title: 'Do you want to submit the quit form? ',
    quit_modal_desc:
      'You can also change the quit date or return to active user later.',
    personal_details: 'Personal Details',
    company_related: 'Company Related',
    contact_details: 'Contact Details',
    status_settings_dialog_title: 'Status settings',
    ndsh_total: 'NDSH Total',
    ndsh_placeholder: 'Total month',
    save_confirm_title: 'Confirm Save Employee Details',
    save_confirm_desc:
      'Do you wish to proceed? Make sure all details are accurate before saving.',
    home_address: 'Home address',
    home_address_placeholder: 'Employee home address',
    quit_reason: 'Quit reason',
    current_status_deleted: 'Deleted',
    current_status_active: 'Active',
    current_status_maternity_leave: 'Maternity Leave',
    current_status_quit: 'Quit',
    current_status_long_term_leave: 'Long Term Leave',
    current_status_day: 'TimeOff',
    current_status_day_am: 'TimeOff AM',
    current_status_day_pm: 'TimeOff PM',
    current_status_compensatory_day: 'Compensatory timeoff',
    current_status_holiday_work: 'Holiday Work',
    current_status_workation: 'Working during timeoff',
    current_status_compensatory_request: 'Compensatory timeoff',
    current_status_special: 'Special TimeOff',
    back_to_work_from_long_term_leave: 'End Long Term Leave',
    back_to_work_date: 'Back to work date',
    long_term_start_date: 'Long Term Leave Start: ',
    impairment: 'Impaired employee?',
    impaired_desc: 'Checking this box indicates you have a physical disability',
    time_required: 'Time register required',
    import: 'Import',
    template: 'Template',
    row_errors: 'Row Errors',
    column_errors: 'Column Errors',
  },
  EMPLOYEE_CURRENT_DAY_STATUS: {
    current_not_started_work: 'Not started work',
    current_working: 'Working',
    current_work_done: 'Work done',
    current_vacation: 'on Vacation',
    current_holiday: 'on Holiday',
  },
  DEPARTMENT: {
    menu_title: 'Department',
    title: 'Department',
    list_title: 'Department list',
    name_hint: 'Enter name',
    name_column: 'Department Name',
    lead_column: 'Department Lead',
    delete_modal_title: 'Are you sure?',
    delete_modal_description:
      'Do you really want to delete selected department? This process cannot be undone.',
    create_success_title: 'New department added',
    create_success_desc: 'New department added with name: {{name}}',
    create_title: 'Create a department',
    save_confirm_title: 'Confirm Save Department Details',
    save_confirm_desc:
      'Do you wish to proceed? Make sure all details are accurate before saving.',
    order: 'Order',
  },
  SKILL_CATEGORY: {
    title: 'Skill Category',
    create_success_title: 'New Skill Category Added',
    create_success_desc: '{{name}} is succesfully added',
    delete_modal_title:
      'All the skills under this category will be deleted as well!',
    delete_modal_description:
      'Are you sure you want to delete the category? This process cannot be undone.',
    name_placeholder: 'Category name',
    description_placeholder: 'Category description',
    list_page_title: 'Skill Category list',
    create: 'Create a Skill Category',
    save_order: 'Save Order',
    save_confirm_title: 'Confirm Save Skill category Details',
    save_confirm_desc:
      'Do you wish to proceed? Make sure all details are accurate before saving.',
    display_order_column: 'Display order',
    name_column: 'Name',
    change_order: 'Change Order',
    order: 'Order',
    target_placeholder: 'Target',
    target_all: 'All',
    target_specific: 'Specific',
    target_positions: 'Target Positions',
  },
  SKILL: {
    title: 'Skills',
    menu_title: 'Skill',
    create_success_title: 'New Skill Added',
    create_success_desc: '{{name}} is succesfully added',
    delete_modal_title: 'Are you sure you want to delete this skill?',
    delete_modal_description:
      'Do you wish to proceed? This process cannot be undone',
    name_placeholder: 'Skill name',
    description_placeholder: 'Skill description',
    list_page_title: 'Skill list',
    create: 'Create a Skill',
    filter: 'Filter',
    skill_report: 'Skill Report',
    save_confirm_title: 'Confirm Save Skill Details',
    save_confirm_desc:
      'Do you wish to proceed? Make sure all details are accurate before saving.',
  },
  TAG: {
    title: 'Type of work',
    menu_title: 'Type of work',
    create_success_title: 'New type of work Added',
    create_success_desc: '{{name}} is succesfully added',
    delete_modal_title: 'Are you sure you want to delete this type of work?',
    delete_modal_description:
      'Do you wish to proceed? This process cannot be undone',
    name_placeholder: 'Name of work type',
    description_placeholder: 'Description of work type',
    list_page_title: 'List of work type',
    create: 'Create a type of work',
    filter: 'Filter',
    save_confirm_title: 'Confirm Save Details of work type',
    save_confirm_desc:
      'Do you wish to proceed? Make sure all details are accurate before saving.',
  },
  USER_SKILL: {
    title: 'Add or Edit your skills',
    add_button: 'Add a skill',
    skill: 'Skill',
    create_success_title: 'New skills added',
    create_success_desc: 'Your skill have been successfully added.',
    confirm_edit_modal_title: 'Are you sure you want to save the changes?',
    confirm_edit_modal_desc: 'This process cannot be reverted.',
    delete_modal_title: 'Are you sure?',
    delete_modal_desc: 'This process cannot be undone.',
    add_title: 'Add Your Skills',
    skill_empty: `This user hasn't added any skills yet.`,
    save_confirm_title: 'Confirm Save Skill Details',
    save_confirm_desc:
      'Do you wish to proceed? Make sure all details are accurate before saving.',
  },
  SKILL_REPORT: {
    position_filter_hint: 'Filter by employee positions',
  },
  RESOURCE_PLAN: {
    list_title: 'Resource Plan List',
    title: 'Resource Plan',
    menu_title: 'Resource Plan',
    name: 'Plan Name',
    create: 'Create new plan',
    name_placeholder: 'Name',
    name_column: 'Name',
    confirmed_column: 'Confirmed',
    created_column: 'Created',
    status_column: 'Status',
    actions_column: 'Actions',
    create_success_title: 'New resource plan added',
    create_success_desc: 'Your resource plan has been successfully added.',
    status_planning: 'Planning',
    status_confirmed: 'Confirmed',
    confirm: 'Confirm',
    unconfirm: 'Unconfirm',
    copy_modal_title: 'Are you sure you want to copy this plan?',
    copy_modal_description:
      'This process will create a copy of the selected plan',
    copy_success_title: 'Succesfully copied',
    copy_success_description: 'Your copied plan has been succesfully added.',
    delete_modal_title: 'Are you sure?',
    delete_modal_description: 'This process cannot be undone.',
    confirm_plan_modal_title: 'Are you sure you want to confirm the plan?',
    confirm_plan_modal_description:
      'Confirmed plan is not editable but you can also unconfirm later.',
    unconfirm_modal_title: 'Are you sure you want to unconfirm the plan?',
    unconfirm_modal_description: 'You can also confirm later.',
    filter_employees_dialog_title: 'Filter Employees',
    filter_employees_dialog_desc:
      "Choose positions that you don't want to see on the list:",
    total: 'Total',
    not_include: "Total employee cost (Some positions aren't included)",
    total_employee_cost: 'Total employee cost',
    target_month: 'Target Month',
    copy_dialog_title: ' Enter the target month for your new copy',
    source: 'Source: ',
    confirm_error_modal_title: "Couldn't confirm",
    empty_project_alert_title: 'Empty project list',
    empty_project_alert_desc:
      'Project list is empty, unable to show grid data.',
    error_alert_title: 'Error',
    error_alert_desc:
      'There was an error loading initial data for the data grid.',
    rename_title: 'Rename title',
    calculate: 'calculate',
    recalculate: 'recalculate',
    calculate_modal_title: 'Are you sure you want to calculate the plan?',
    calculate_modal_description:
      'Calculated plan is not editable but you can also modify later.',
    modify_button: 'modify',
    new_employee_cell:
      'New Hire: 2-3 months. Consider adjusting costs soon for this employee.',
    activity_log_button: 'activity history',
    activity_history_title: 'Activity history',
    calculated_column: 'Calculated',
    confirm_add_user_title: 'Add User',
    confirm_add_user_desc: 'Are you sure to add user to resource plan?',
    confirm_remove_user_title:
      'Are you sure you want to remove user(s) from resource plan?',
    completed: 'completed',
  },
  RESOURCE_PLAN_DATAGRID: {
    employees: 'Employees',
    custom_position: 'Resource Position',
    total: 'Total',
  },
  RESOURCE_ACTIVITY: {
    created: 'Created',
    rename: 'Renamed',
    confirm: 'Confirmed',
    unconfirm: 'Unconfirmed',
    calculated: 'Calculated',
    updated: 'Updated',
    unknown: '',
  },
  COST_REPORT: {
    filter_by_divisions: 'Filter by Partner divisions',
    start_date: 'Start date',
    end_date: 'End date',
    month: 'Month',
    date_range_label: 'Date range',
    total_partner_division: 'Total by partner division',
    purchased_count: 'Purchase count',
    position_by_division: 'Positions by partner division',
    total_count: 'Total count',
    total_cost: 'Total Cost',
    purchase_total_count: 'Planned count',
    purchase_total_cost: 'Planned Cost',
    ordered_cost: 'Ordered Cost',
    partner_division: 'Partner division',
    division_name: 'Division name',
    actual_cost: 'Actual cost',
    total_ordered_cost: 'Ordered Cost',
    list_title: 'Cost Reports',
  },
  ACTUAL_COST_REPORT: {
    title: 'Actual cost report',
    employee: 'Employee',
    planned: 'Planned',
    actual: 'Actual',
    ordered: 'Ordered',
    positions: 'Positions',
    other: 'Other',
    total: 'Total',
    timeoff_days: 'TimeOff days',
    work_dates: 'Work dates: {{days}}',
    holidays: 'Holidays: {{days}}',
    within_invoice: 'Projects with invoice report',
    within_invoice_timeoff: 'Projects with invoice report (exclude timeoff)',
    actual_work_date: 'Work report',
    projects: 'All Projects report (exclude timeoff)',
    all_worked: 'Work report (exclude timeoff)',
    detail_title: 'Actual cost report details',
  },
  MEETING_ROOM_ORDER: {
    title: 'Book meeting room',
    side_content_title: 'Meeting',
    start_time: 'Start time',
    end_time: 'End time',
    create_title: 'Book meeting room',
    order_button: 'Book',
    delete: 'Delete meeting',
    delete_modal_title: 'Delete meeting room booking',
    delete_modal_description:
      "Are you sure you want to delete this meeting room's booking?",
    subject: 'Subject',
    meeting_room: 'Meeting room',
    create_success_title: 'Meeting room booking successfull',
    create_desc: 'Meeting room booked with subject: {{subject}}',
    create_fail_title: 'The time coincides',
    create_fail_desc:
      'There is another meeting scheduled in this room at {{startTime}} to {{endTime}}.',
    ordered_by: 'Booked by',
    no_meeting_room: 'No meeting room found',
    none: 'Does not repeat',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    mon: 'Mon',
    tue: 'Tue',
    wed: 'Wed',
    thu: 'Thu',
    fri: 'Fri',
    sat: 'Sat',
    sun: 'Sun',
    week: 'week',
    month: 'month',
    repeat_every: 'Repeat every',
    repeat_on: 'Repeat on',
    days_of_month: 'Days of month',
    ends: 'Ends',
    expire_date: 'Expire date',
    frequency: 'Frequency',
    is_recurring: 'Repeatable',
    delete_all_title: 'Delete all？',
    delete_all_description:
      'Do you want to change all meeting room reservations？',
    change_all_title: 'You want to Change all？',
    change_all_description:
      'Do you want to change all meeting room reservations？If you click only this event will become non recurring',
    only_this: 'Only this',
    delete_all: 'Delete all',
    change_all: 'Change all',
    help_title: 'How to set repeat frequency',
    help_text: '1. Enter number of repeats\n2. Select time unit',
    example: 'Example: 2 weeks = Repeat every [2] Frequency [Weekly]',
    expire_required: 'Default: 1 year',
    repeat_type: 'Frequency',
    change_recurrence_title: 'Change Recurrence Rule',
    change_recurrence_description:
      'If you change the recurrence rule of this event, it will affect this and following events.',
  },
  MEETING_ROOM: {
    meeting_room: 'Meeting Room',
    list_title: 'Meeting Room list',
    order: 'Order',
    name_column: 'Name',
    description_column: 'Description',
    name_placeholder: 'Meeting room name',
    description_placeholder: 'Meeting room description',
    change_order: 'Change order',
    save_order: 'Save order',
    delete_modal_title: 'Are you sure?',
    delete_modal_description:
      'Do you really want to delete selected meeting room? This process cannot be undone.',
    create_success_title: 'New meeting room added',
    create_success_desc: 'New meeting room added with name: {{name}}',
    create_title: 'Create a Meeting Room',
    save_confirm_title: 'Confirm Save Meeting Room Details',
    save_confirm_desc:
      'Do you wish to proceed? Make sure all details are accurate before saving.',
  },
  SYSTEM_SETTINGS: {
    system_settings: 'System settings',
    name: 'Name',
    value: 'Value',
    value_type: 'Value type',
    number: 'Number',
    string: 'String',
    time: 'Time',
    date: 'Date',
    json: 'JSON',
    save_confirm_title: 'Confirm Save System ',
    save_confirm_desc:
      'Do you wish to proceed? Make sure all details are accurate before saving.',
    cancel_confirm_title: 'It looks like you have been editing something.',
    cancel_confirm_desc:
      'If you leave before saving, your changes will be lost.',
  },
  APPROVAL_TEMPLATES: {
    title: 'Approval Templates',
    name: 'Name',
    name_placeholder: 'Approval Template name',
    status: 'Status',
    active: 'Active',
    inactive: 'Inactive',
    details_title: 'Approval Template Details',
    change_step: 'Change Step',
    step: 'Step',
    is_manual: 'Manual',
    positions: 'Positions',
    order_title: 'Change Step Order',
    save_order: 'Save order',
    is_manual_placeholder: 'Is manual',
    positions_placeholder: 'Approve positions',
    save_confirm_title: 'Confirm Approval Template Details',
    create: 'Create a Approval Template',
    create_step: 'Create a Approval Template Step',
    save_title: 'Confirm Approval Template',
    save_desc:
      'Do you wish to proceed? Make sure Approval Template name is accurate before saving.',
    save_confirm_desc:
      'Do you wish to proceed? Make sure all details are accurate before saving.',
    cancel_confirm_title: 'It looks like you have been editing something.',
    cancel_confirm_desc:
      'If you leave before saving, your changes will be lost.',
    create_success_title: 'New approval template created',
    create_success_desc: 'New approval template created with name: {{name}}.',
    create_detail_success_title: 'New step added',
    create_detail_success_desc:
      'New step added with id: {{id}} and with step: {{step}}',
    delete_modal_title: 'Delete Approval Template',
    delete_modal_description:
      'Are you sure you want to delete this Approval Template?',
    allow_reason: 'Allow deny reason',
  },
  VACATION_BALANCES: {
    title: 'Vacation balances',
    employees: 'Employee',
    hired_date: 'Hired date',
    ndsh_before: 'NDSH before',
    ndsh_total: 'NDSH total',
    days: 'Days',
    remaining_days: 'Remaining days',
    used_days: 'Used days',
    create: 'Add',
    active: 'Active',
    status: 'Status',
    inactive: 'Inactive',
    year_placeholder: 'Vacation year',
    user_placeholder: 'Employee',
    days_placeholder: 'Total days',
    remaining_days_placeholder: 'Remaining days',
    start_at_placeholder: 'Start at',
    end_at_placeholder: 'End at',
    create_success_title: 'New Employee added Vacation Balances',
    create_success_desc:
      'New Employee added with year: {{year}} and with days: {{days}}',
    calculate: 'Calculate',
    save_confirm_title: 'Confirm Save Employee Vacation',
    save_confirm_desc:
      'Do you wish to proceed? Make sure all details are accurate before saving.',
    add_user: 'Add employee',
    dialog_title: 'Calculate vacation balance',
    all_employee: 'Calculate for all employee',
    new_employee: 'The vacation balance of this employee should be calculated',
    remaining_days_total: 'Remaining days total',
    user_history_dialog_title: '{{year}} vacation details',
    start_date: 'Start date',
    end_date: 'End date',
    timeoff_type: 'TimeOff type',
    duration: 'Duration',
    total_vacation_days: 'Total vacation days',
  },
  VACATION_REPORT: {
    report_title: 'Vacation Report',
    report_summary: 'Total Vacation',
    pre_paid: 'Pre-Paid',
    post_paid: 'Post-Paid',
    vacation_report: 'Employee Vacation Overview',
    employee: 'Employee',
    remaining_days: 'Remaining days',
    used_days: 'Used days',
    year: 'Year',
    more: 'Details',
    type: 'Vacation type',
    nodata: 'No records found',
  },
  TIMESHEET: {
    menu_title: 'Timesheet',
    date: 'Date',
    start_time: 'Start time',
    end_time: 'End time',
    break_start: 'Break start',
    break_end: 'Break end',
    worked_hours: 'Worked hours',
    overtime: 'Overtime',
    early_leave: 'Early leave',
    late_arrival: 'Late arrival',
    absense: 'Absense',
    total_deduct: 'Total deduction',
    monday: 'Mon',
    tuesday: 'Tue',
    wednesday: 'Wed',
    thursday: 'Thu',
    friday: 'Fri',
    saturday: 'Sat',
    sunday: 'Sun',
    total: 'Total',
    dialog_title: '{{date}} time correction',
    timeoff: 'Time off',
    reason: 'Reason',
    break_records: 'Break records',
    add_break_record: 'Add break record',
    work_start_placeholder: 'Work start',
    work_end_placeholder: 'Work end',
    break_start_placeholder: 'Break start',
    break_end_placeholder: 'Break end',
    save_confirm_title: 'Confirm save time correction request',
    save_confirm_desc:
      'Do you wish to proceed? Make sure all details are accurate before saving.',
    create_detail_success_title: 'Time correction request sent.',
    create_detail_success_desc: 'Time correction request sent on {{date}}',
    weekend: 'Weekend',
    holiday: 'Holiday',
    invalid_record: 'Invalid record',
    employee: 'Employee',
    deduction_days: 'Deduction days: {{days}} days',
    holiday_work_days: 'Weekend working days: {{days}} days',
    deduction_hours: 'Deduction hours: {{hours}} hours',
    holiday_overtime: 'Holiday overtime: {{hours}} hours',
    delete_request: 'Delete time record',
    edit_request: 'Edit time record',
    time_correction_disable_placeholder:
      "This request's 1 or more steps are already approved. See step details from My time correction",
    remote: 'Remote',
    no_location: 'Location unknown',
    workspace: 'Workspace status',
    work_days: 'Work days: {{days}} days',
  },
  VACATION_INFO: {
    show: 'Show vacation info',
    hide: 'Hide vacation info',
    employee: 'User code/Name:',
    hired_date: 'Hired Date:',
    ndsh_months_before: 'NDSH months/before/:',
    ndsh_months_total: 'NDSH months/total/:',
    vacation_days: 'Vacation days:',
    remaining_days: 'Vacation balance:',
    info: 'Depending on the number of years working under normal working conditions, the employee shall be entitled to the following additional days of vacation on top of the basic vacation days. Default vacation days - {{day}} days.',
    yearly: '{{year}} or more years: {{day}} days',
    month: '{{month}} month(s)',
    days_in_year: '{{day}} day(s) ({{year}}), ',
    compensatory_balance: 'Compensatory balance:',
    year: 'Year',
    table_vacation_days: 'Vacation days',
    table_remaining_days: 'Vacation balance',
  },
  TOASTS: {
    forgot_password_fulfilled: 'Verification code was sent to your email.',
    forgot_password_rejected: 'Failed to send a request.',
    password_reset_fulfilled: 'Your password has been reset',
    password_reset_rejected: 'Failed to reset password',
    change_password_fulfilled: 'Your password has changed succesfully.',
    change_password_rejected: 'Failed to change password',
    switch_lang_rejected: 'Failed to save default language to user profile',
    // Company
    create_company_rejected: 'Failed to add a company',
    update_company_fulfilled: 'Company updated successfully',
    update_company_rejected: 'Failed to update company',
    delete_company_fulfilled: 'Successfully deleted company',
    delete_company_rejected: 'Failed to delete company',
    // Division
    update_division_fulfilled: 'Successfully updated division.',
    update_division_rejected: 'Failed to update current division.',
    create_division_fulfilled: 'Successfully added a division.',
    create_division_rejected: 'Failed to add a division.',
    delete_division_fulfilled: 'Successfully deleted division.',
    delete_division_rejected: 'Failed to delete a division.',
    update_division_order_rejected: 'Failed to update order',
    update_division_order_fulfilled: 'Division order updated',
    // Position
    update_position_fulfilled: 'Successfully updated position.',
    update_position_rejected: 'Failed to update position.',
    delete_position_fulfilled: 'Successfully deleted position.',
    delete_position_rejected: 'Failed to delete position.',
    update_position_order_rejected: 'Failed to update order',
    update_position_order_fulfilled: 'Position order updated',
    // Department
    create_department_fulfilled: 'Successfully added a department.',
    create_department_rejected: 'Failed to add a department.',
    update_department_fulfilled: 'Successfully updated department.',
    update_department_rejected: 'Failed to update department.',
    delete_department_fulfilled: 'Successfully deleted department.',
    delete_department_rejected: 'Failed to delete department.',
    // Project
    delete_project_fulfilled: 'Project successfully deleted.',
    delete_project_rejected: 'Failed to delete project.',
    create_user_rejected: 'Failed to add user.',
    create_user_fulfilled: 'User successfully added.',
    update_user_rejected: 'Failed to update user.',
    update_user_fulfilled: 'User successfully updated.',
    delete_project_user_rejected: 'Failed to delete project user.',
    delete_project_user_fulfilled: 'Project user successfully deleted.',
    update_project_fulfilled: 'Project successfully updated.',
    update_project_rejected: 'Failed to update project.',
    update_project_image_fulfilled: 'Project image successfully uploaded.',
    update_project_image_rejected: 'Failed to upload project image.',
    //Partner purchase order
    create_partner_purchase_order_fulfilled:
      'Successfully added a partner purchase order.',
    create_partner_purchase_order_rejected:
      'Failed to add a partner purchase order.',
    update_partner_purchase_order_fulfilled:
      'Successfully updated partner purchase order.',
    update_partner_purchase_order_rejected:
      'Failed to update partner purchase order.',
    delete_partner_purchase_order_fulfilled:
      'Successfully deleted partner purchase order.',
    delete_partner_purchase_order_rejected:
      'Failed to delete partner purchase order.',
    // Role
    delete_role_rejected: 'Failed to delete role.',
    delete_role_fulfilled: 'Role successfully deleted.',
    update_role_rejected: 'Failed to update role.',
    update_role_fulfilled: 'Role successfully updated.',
    // Event
    event_update_fulfilled: 'Event updated',
    event_update_rejected: 'Failed to update',
    event_delete_rejected: 'Failed to delete',
    event_delete_fulfilled: 'Event deleted',
    // Skill
    update_skill_rejected: 'Failed to update',
    update_skill_fulfilled: 'Skill updated',
    delete_skill_rejected: 'Failed to delete',
    delete_skill_fulfilled: 'Skill deleted',
    // Skill category
    update_skill_category_rejected: 'Failed to update',
    update_skill_category_fulfilled: 'Skill category updated',
    update_skill_category_order_rejected: 'Failed to update order',
    update_skill_category_order_fulfilled: 'Skill category order updated',
    delete_skill_category_rejected: 'Failed to delete',
    delete_skill_category_fulfilled: 'Skill category deleted',
    // User skill
    delete_user_skill_by_id_rejected: 'Failed to delete',
    delete_user_skill_by_id_fulfilled: 'Skill deleted',
    update_user_skills_rejected: 'Failed to update',
    update_user_skills_fulfilled: 'Skills are updated',
    update_single_skill_rejected: 'Failed to update',
    update_single_skill_fulfilled: 'Skill updated',
    // Resource Plan
    create_resource_plan_rejected: 'Failed to create a new plan',
    rename_resource_plan_fulfilled: 'Renamed successfully',
    rename_resource_plan_rejected: 'Failed to rename the plan',
    confirm_resource_plan_fulfilled: 'Confirmed successfully',
    confirm_resource_plan_rejected: 'Failed to confirm the plan',
    refuse_resource_plan_fulfilled: 'Unconfirmed successfully',
    refuse_resource_plan_rejected: 'Failed to unconfirm the plan',
    delete_resource_plan_fulfilled: 'Deleted successfully',
    delete_resource_plan_rejected: 'Failed to delete the plan',
    // Employee
    update_employee_rejected: 'Failed to update',
    update_employee_fulfilled: 'Employee updated',
    delete_employee_rejected: 'Failed to delete',
    delete_employee_fulfilled: 'Employee deleted',

    quit_employee_rejected: 'Failed to submit quit form',
    quit_employee_fulfilled: 'Quit form submitted',
    cell_update_failed: 'Cell update failed ',
    calculate_resouse_plan_fulfilled: 'Resource plan calculated successfully',
    update_quit_reason_rejected: 'Failed to update',
    update_quit_reason_fulfilled: 'Quit reason updated',

    reset_password_employee_fulfilled:
      'Employee password has been successfully reset.',
    reset_password_employee_rejected: 'Failed to reset the employee password',
    // Meeting room
    create_meeting_room_fulfilled: 'Successfully added a meeting room.',
    create_meeting_room_rejected: 'Failed to add a meeting room.',
    update_meeting_room_fulfilled: 'Successfully updated meeting room.',
    update_meeting_room_rejected: 'Failed to update meeting room.',
    delete_meeting_room_fulfilled: 'Successfully deleted meeting room.',
    delete_meeting_room_rejected: 'Failed to delete meeting room.',
    meeting_room_update_order_rejected: 'Failed to update order',
    meeting_room_update_order_fulfilled: 'Meeting room order updated',
    //Book Meeting room
    create_meeting_room_order_rejected: 'Failed to book meeting room',
    update_meeting_room_order_fulfilled: 'Meeting room book updated',
    update_meeting_room_order_rejected: 'Failed to update meeting room book',
    delete_meeting_room_order_fulfilled: 'Meeting room order deleted',
    delete_meeting_room_order_rejected: 'Failed to delete meeting room book',
    update_system_settings_fulfilled: 'System settings updated.',
    update_system_settings_rejected: 'Failed to update system settings.',
    //Email campaign
    create_email_campaign_fulfilled: 'Successfully added a email campaign.',
    create_email_campaign_rejected: 'Failed to add a email campaign.',
    update_email_campaign_fulfilled: 'Successfully updated email campaign.',
    update_email_campaign_rejected: 'Failed to update email campaign.',
    delete_email_campaign_fulfilled: 'Successfully deleted email campaign.',
    delete_email_campaign_rejected: 'Failed to delete email campaign.',
    send_bulk_failed: 'Failed to send',
    send_bulk_success: 'Successfully Deliveried',
    //Email template
    create_email_template_fulfilled: 'Successfully added a email template.',
    create_email_template_rejected: 'Failed to add a email template.',
    update_email_template_fulfilled: 'Successfully updated email template.',
    update_email_template_rejected: 'Failed to update email template.',
    delete_email_template_fulfilled: 'Successfully deleted email template.',
    delete_email_template_rejected: 'Failed to delete email template.',
    unknown_rejected: 'Something went wrong',
    // Timeoff template
    update_timeoff_template_rejected: 'Failed to update timeoff template.',
    update_timeoff_template_fulfilled: 'Successfully updated timeoff template.',
    delete_timeoff_template_rejected: 'Failed to delete timeoff template.',
    delete_timeoff_template_fulfilled: 'Successfully deleted timeoff template.',
    //Approval template
    update_approval_template_fulfilled:
      'Successfully updated a approval template.',
    update_approval_template_rejected: 'Failed to update a approval template.',
    delete_approval_template_fulfilled:
      'Successfully deleted a approval template.',
    delete_approval_template_rejected: 'Failed to delete a approval template.',
    //Approval template details
    update_approval_template_details_fulfilled:
      'Successfully updated a approval template details.',
    update_approval_template_details_rejected:
      'Failed to update a approval template details.',
    delete_approval_template_details_fulfilled:
      'Successfully deleted a approval template details.',
    delete_approval_template_details_rejected:
      'Failed to delete a approval template details.',
    update_approval_template_step_fulfilled:
      'Successfully updated approval template steps',
    update_approval_template_step_rejected:
      'Failed to update approval template steps.',
    create_approval_template_step_rejected:
      'Failed to create approval template steps.',
    // Timeoff request
    delete_timeoff_request_fulfilled: 'Successfully deleted the request.',
    delete_timeoff_request_rejected: 'Failed to delete the request.',
    time_off_request_failed: 'Failed to send the request.',
    // Vacation balances
    calculate_vacation_balance_fulfilled:
      'Successfully calculated vacation balance',
    calculate_vacation_balance_rejected: 'Failed to calculate vacation balance',
    // Daily report
    daily_report_update_fulfilled: 'Daily report updated',
    daily_report_update_rejected: 'Daily report update failed',
    daily_report_create_rejected: 'Daily report create failed',
    // time correction
    time_correction_create_rejected: 'Request failed',
    time_correction_update_rejected: 'Edit request failed',
    approve_time_correction_rejected: 'Approve time correction failed',
    deny_time_correction_rejected: 'Deny time correciton failed',
    // time correction templatea
    time_correction_template_fulfulled:
      'Successfully updated time correction template',
    time_correction_template_rejected:
      'Failed to update time correction template',
    // Our rules
    company_rules_update_fulfulled: 'Successfully updated Our rules',
    company_rules_update_rejected: 'Failed to update Our rules',
    // Veryify workspace
    workspace_verify_fulfulled: 'Successfully changed workspace',
    workspace_verify_rejected: 'Failed to change workspace',
    regenerate_workspace_token_fulfilled:
      'Successfully generated workspace UID',
    regenerate_workspace_token_rejected: 'Failed to generated workspace UID',
    update_workspace_fulfilled: 'Successfully updated workspace',
    update_workspace_rejected: 'Failed to update workspace',
    delete_workspace_fulfilled: 'Successfully deleted workspace',
    update_remote_work_settings_fulfilled:
      'Successfully updated remote work settings',
    update_remote_work_settings_rejected:
      'Failed to update remote work settings',
    delete_remote_work_settings_fulfilled:
      'Successfully deleted remote work settings',
    delete_remote_work_settings_rejected:
      'Failed to delete remote work settings',
    timeqr_start_fulfilled: 'Successfully work started from office',
    timeqr_end_fulfilled: 'Successfully work ended from office',
    delete_remote_work_request_fulfilled:
      'Successfully deleted remote work request',
    delete_remote_work_request_rejected: 'Failed to delete remote work request',
    update_remote_work_request_fulfilled:
      'Successfully updated remote work request',
    update_remote_work_request_rejected: 'Failed to update remote work request',
    create_remote_work_settings_rejected:
      'Failed to disable remote work request',
    update_user_compensatory_days_fulfilled:
      'Successfully updated user compensatory days',
    update_user_compensatory_days_rejected:
      'Failed to update user compensatory days',
    // tags
    update_tag_rejected: 'Failed to update',
    update_tag_fulfilled: 'Type of work updated',
    delete_tag_rejected: 'Failed to delete',
    delete_tag_fulfilled: 'Type of work deleted',
    create_tag_rejected: 'Type of work create failed',
  },
  UPLOAD: {
    title: 'Image upload',
    upload: 'Upload',
    file: 'File Upload',
  },
  EMAIL: {
    templates: 'Templates',
    campaigns: 'Campaigns',
    email_settings: 'Email Settings',
    edit_design: 'Edit Design',
    title: 'Email',
    send_email: 'Send email',
    send_email_title: 'Select template to start',
    create_template: 'Create Template',
    use_template: 'Use template',
    template_name: 'Template name',
    subject: 'Email Subject',
    template_type: 'Template type',
    bulk: 'Bulk',
    salary: 'Salary',
    recipient: 'Email Recipient',
    content: 'Email Content',
    send_warning_title: 'You dont have permission to send email.',
    send_warning_msg:
      'if you want to use email campaign feature contact Administrator',
    unsaved_title: 'You have unsaved Changes',
    unsaved_desc: 'Do you want to go back before save?',
    back: 'Back to Settings',
    save: 'Save Design',
    desktop: 'Desktop',
    mobile: 'Mobile',
    empty_content: 'Empty content',
    step_one: 'Select Template',
    step_settings: 'Edit settings',
    step_import_data: 'Import Salary Data',
    step_sent: 'Completed',
    send_salary: 'Send Email',
    desktop_version: 'Desktop version',
    mobile_version: 'Mobile version',
    completed_title: 'This campaign completed',
    completed_desc:
      'If you want to send email again please create new campaign',
    completed_create: 'Create New Campaign',
    import_data_dialog: {
      row_span_config: 'Row Span Config',
      col_span_config: 'Column Span Config',
      add_row_span_config: 'Add Row Span Config',
      add_col_span_config: 'Add Column Span Config',
      row: 'Row Index',
      column: 'Column Index',
      row_span: 'Row Span',
      col_span: 'Column Span',
      other: 'Other',
      other_than: 'Other Than',
      data_index: 'Data Index',
      data_slice: 'Data Slice',
      user_code_index: 'User Code Index',
    },
    recipients: 'Recipients',
    sent: 'Sent',
    failed: 'Failed',
  },
  EMAIL_CAMPAIGN: {
    campaign_create_title: 'Campaign Create',
    display_order: 'Display Order',
    status_draft: 'Draft',
    status_sent: 'Sent',
    status_failed: 'Failed',
    status_all: 'All',
    status_pending: 'Pending',
    name_column: 'Name',
    created_at_column: 'Created at',
    status_column: 'Status',
    template_name_column: 'Template Name',
    template_type: 'Email Type',
    delete: 'Delete Email campaign',
    delete_modal_title: 'Delete Email campaign',
    delete_modal_description:
      'Are you sure you want to delete this Email campaign?',
    save_confirm_title: 'Confirm Save Email Campaign Name',
    save_confirm_desc:
      'Do you wish to proceed? Make sure all details are accurate before saving.',
    create_success_title: 'New Email Campaign added',
    create_success_desc:
      'New Email Campaign added with id: {{id}} and with name: {{name}}',
    name_placeholder: 'Email Campaign Name',
    send: 'Send Email',
    import_data: 'Import Data',
    settings: 'Campaign Settings',
    campaign_name: 'Campaign Name',
    send_confirm_title: 'Are you sure you want to send email',
    send_confirm_desc:
      'Do you wish to proceed? Sending to total of {{count}} recipients. Make sure all details are accurate before sending.',
    send_confirm_bulk_desc:
      'Do you wish to proceed? Sending to {{recipient}}. Make sure all details are accurate before sending.',
    fail_log_title: 'Failed Recipient Logs for Campaign',
    mark_as_complete: 'Mark as complete',
    complete_title: 'Confirm Mark as Complete',
    complete_desc:
      'Are you sure you want to mark this email campaign as complete? This action will close the campaign and change its status to "Completed". Once marked as complete, you won\'t be able to make further edits or send additional communications through this campaign.',
  },
  EMAIL_TEMPLATE: {
    thumbnail_column: 'Thumbnail',
    name_column: 'Name',
    created_at_column: 'Created at',
    updated_at_column: 'Updated at',
    created_by_column: 'Created by',
    delete: 'Delete Email template',
    delete_modal_title: 'Delete Email template',
    delete_modal_description:
      'Are you sure you want to delete this Email template?',
    save_confirm_title: 'Confirm Save Email Template Details',
    save_confirm_desc:
      'Do you wish to proceed? Make sure all details are accurate before saving.',
  },
  EMAIL_SETTINGS: {
    name: 'Name',
    edit_name_button: 'Edit Name',
    template_name: 'Template name',
    template_type: 'Template type',
    to: 'To',
    add_recipient_button: 'Add Recipients',
    empty_recipient_text: 'Who are you sending this email to?',
    recipients: 'Recipients',
    send_to_all: 'Send to All',
    type: 'Type',
    edit_type_button: 'Edit type',
    subject: 'Subject',
    edit_subject_button: 'Edit Subject',
    not_value_text: 'What`s the subject line for this email?',
    subject_placeholder: 'Write subject',
    subject_word_warning: 'Try to use no more than 9 words',
    subject_char_warning: 'Avoid using more than 60 characters',
    content: 'Content',
    edit_content_button: 'Edit design',
    view_content_button: 'View design',
    content_placeholder: 'Design the Content for your email.',
    campaign_name: 'Campaign Name',
    all_employee: 'All Employee',
    position_select_all:
      'You can check position to select all employees in each group.',
    thumbnail: 'Thumbnail',
    thumbnail_placeholder: 'Upload thumbnail for your template.',
    view_thumbnail_button: 'View Thumbnail',
    edit_thumbnail_button: 'Edit Thumbnail',
    thumbnail_succesfully_changed:
      "Template's thumbnail has succesfully changed.",
    preview: 'Preview',
  },
  EMAIL_DESIGN: {
    content: 'Content',
    dynamic_content: 'Dynamic Content',
    salary_table: 'Salary Table',
    layout: 'Layout',
    save_confirm_title: 'Data is Saved',
    save_confirm_desc: 'Back to settings page ?',
    confirmation_text: 'Continue',
    cancellation_text: 'Keep Editing',
    mobile_edit_warning: 'Editor only work on desktop',
  },
  TIMEOFF_TEMPLATE: {
    special: 'Exceptional TimeOff',
    menu_title: 'Time-Off Templates',
    list_title: 'Time-Off Templates',
    name: 'Name',
    create_title: 'Time-Off Template Create',
    approval_template: 'Approval Template',
    description: 'Description',
    pay_status: 'Pay status',
    range_type: 'Range type',
    identifier_label: 'Template Type',
    identifier_other: 'Default',
    identifier_mytime: 'My Time',
    identifier_holidaywork: 'Work on weekend',
    identifier_workation: 'Work during vacation',
    identifier_compensatory_request: 'Compensatory request',
    is_request_send_later: 'Allow send request later',
    is_long_term_leave: 'Long Term Leave',
    is_deduct_from_vacation: 'Deduct From Vacation',
    is_attachment: 'Allow Attachment',
    is_reason_allow: 'Allow Reason Input',
    pay_status_pre: 'Pre Paid',
    pay_status_post: 'Post Paid',
    pay_status_non_paid: 'Non Paid',
    range_type_time: 'By Time',
    range_type_am: 'Half Day AM',
    range_type_pm: 'Half Day PM',
    range_type_day: 'By Day',
    delete_modal_title: 'Delete Time-Off Template',
    delete_modal_description:
      'Are you sure you want to delete this time-off template?',
    create_success_title: 'New TimeOff Template Added',
    create_success_desc: 'New TimeOff Template added with name: {{name}}',
    save_confirm_title: 'Confirm Save Time-Off Template Details',
    save_confirm_desc:
      'Do you wish to proceed? Make sure all details are accurate before saving.',
    is_show_time_report: 'Save record in time report',
    duration_max: 'Max duration',
    duration_min: 'Min duration',
  },
  TIMEOFF_REQUEST: {
    current_status: 'Current Status : ',
    duration_hour: ' hour(s)',
    duration_min: ' min',
    half_day_am_duration: 'Half Day, AM',
    half_day_pm_duration: 'Half Day, PM',
    total_remaining_comp_balance: 'Total remaining days: ',
    total_remaining_comp_balance_desc:
      'Total compensatory balance / Total earned days',
    selected_day_info: 'Days to be used: ',
    selected_day_info_desc:
      'Selected days in calendar / Selected Days from list',
    estimated_remaining_compdays:
      'Estimated remaining compensatory days: {{days}}',
    selected_compday_remainder: 'Selected days to be compensated: {{days}}',
    not_specified: 'Not specified',
    not_enough_balance: '(Not enough balance)',
    not_enough_compday:
      '(Not enough balance. Choose more items from list or reduce the selected duration from calendar.)',
    use_compday_alert:
      'You have {{day}} days of compensatory balance. Please use it first.',
    special: 'Exceptional TimeOff',
    any_position: 'all',
    remainder: 'Remainder: ',
    compday_list_label:
      'The following is a list of your previously worked days on holidays or weekends. Choose which day is to be compensated.',
    step: 'Step',
    list_title: 'My TimeOff Requests',
    menu_title: 'My TimeOffs',
    choose_template: 'Choose a TimeOff type',
    create_title: 'Create a Time-Off Request',
    status: 'Status',
    request_type: 'Request Type',
    template_type: 'TimeOff Type',
    is_break_morning: 'Break from the start',
    start_at: 'Start',
    end_at: 'End',
    current_step: 'Step',
    approver: 'Approver',
    reason: 'Reason',
    pay_status: 'Pay method:',
    range_type: 'Duration type:',
    selected_duration: 'Total Duration:',
    duration: 'Duration',
    is_deduct_from_vacation: 'Deduct from vacation: ',
    no_template: 'No template to choose from..',
    status_pending: 'Pending',
    status_rejected: 'Rejected',
    status_accepted: 'Accepted',
    status_deleted: 'Deleted',
    create_success_title: 'New Time-Off Request Created',
    create_success_desc:
      'New Time-Off Request Created from {{start_at}} to {{end_at}}.',
    delete_modal_title: 'Delete Time-Off Request',
    delete_modal_description:
      'Are you sure you want to delete this time-off request?',
    save_confirm_title: 'Confirm Save Time-Off Request Details',
    save_confirm_desc:
      'Do you wish to proceed? Make sure all details are accurate before saving.',
    employee: 'Employee',
    approve_list: 'TimeOff Approve List',
    approve_list_menu: 'Approve List',
    approve_modal_title: 'Approve request',
    approve_modal_description: 'Are you sure you want to approve this request?',
    approve_success_title: 'Approved',
    approve_success_description:
      'Successfully approved the request by {{employee}}',
    deny_modal_title: 'Reject request',
    deny_modal_description: 'Are you sure you want to reject this request?',
    deny_success_title: 'Rejected',
    deny_success_description:
      'Successfully rejected the request by {{employee}}',
    approve: 'Approve',
    approver_dialog_warning: "You can't undo this action.",
    next_approver_placeholder: 'Next approver',
    next_approver_label: "Choose the next step's approver for this request",
    detail_step_by: 'by ',
    current_total_step: 'Current Step / Total Steps',
    no_step_approver_data: '...',
    attachment: 'Attachment',
    detail_btn: 'Details',
    delete_btn: 'Delete',
    approve_btn: 'Approve',
    deny_btn: 'Deny',
    created_at: 'Created At',
    compensatory_earned_days: 'Earned compensatory days: {{day}}',
    compensatory_remaining_days: 'Remaining compensatory days: {{day}}',
    filter_title: 'Filter TimeOff Requests',
    eligible_positions: 'Eligible positions for approver: ',
    your_approval: 'Waiting for your approval',
    year: 'Year',
    vacation_days: 'Vacation days',
    remainig_days: 'Remaining days',
    action_by_me: 'Action by me',
    comp_expire_at: 'The last date to use compensatory leave: ',
  },
  TIMEOFF_TOTAL_REPORT: {
    title: 'Monthly TimeOff Report ',
    submit: 'SEARCH',
    select_year: 'Select year',
    select_months: 'Select Months',
    select_vacation: 'Select Vacation Type',
    month: 'Month',
    year: 'Year',
    vacations: 'Vacation',
  },
  DAILY_REPORT: {
    my_daily_report: 'My daily report',
    team_daily_report: 'Team report',
    all_daily_report: 'All daily report',
    create: 'Add report',
    menu_title: 'Daily report',
    page_title: 'Daily reports',
    list_title: 'Daily report list',
    no_daily_report: 'No daily report found',
    report: 'Daily report description',
    report_placeholder: 'What have you done ?',
    project_label: 'Related project',
    project_placeholder: 'Search by project',
    yesterday: 'Yesterday',
    today: 'Today',
    list_view: 'List view',
    calendar_view: 'Time view',
    project: 'Project',
    view_label: 'Time entry view',
    list_view_btn: 'List',
    calendar_view_btn: 'Time',
    not_within_range: 'Daily report date must fall within the work date.',
    in_progress: 'Working',
    total_works_label: 'Total Work Hours',
    other: 'Other',
    report_entries: 'Report Entries',
    projects: 'Projects',
    not_within_rang: 'Out of range',
    delete_modal_title: 'Report delete',
    delete_modal_description: 'Are you sure?',
    no_time_card: 'No working hours',
    entry_chart_desc:
      'The pie chart above visually represents the distribution of daily report entries by work type, showing the percentage of entries based on time',
    widget_title: 'Daily Activity Summary.',
    end_at: 'End at',
    start_at: 'Start at',
    report_detail: 'Report Detail',
    select_project: 'Select Project',
    page_title_team: 'Team daily report',
    page_title_hr: 'All project daily report',
    very_low: 'Very low',
    low: 'Low',
    moderate: 'Moderate',
    high: 'High',
    very_high: 'Very High',
    very_flexible: 'Very flexible',
    flexible: 'Flexible',
    firm: 'Firm',
    very_firm: 'Very Firm',
    complexity: 'Complexity',
    deadline: 'Deadline',
    tags: 'Type of work',
    tag_placeholder: 'Please choose type',
    help_complexity:
      '"Complexity: Rate the difficulty level of the task from 1 to 5. Low numbers represent simpler tasks, while higher numbers indicate more challenging ones."',
    help_deadline:
      'Deadline: Indicate the urgency of the task from 1 to 5. Low numbers mean flexible deadlines, while higher numbers imply stricter timelines."',
    may_include_break_time: 'This duration may include break time.',
    team_widget_title: 'Week Activity Summary',
    work_load_stress_title: 'Work load stress',
    low_stress: 'Low stress',
    moderate_stress: 'Medium stress',
    high_stress: 'High stress',
    all_members: 'All members',
    no_report: 'No report',
    stress_desc:
      ' This indicator does not reflect an individual`s emotional or personal stress level. Instead, it is based solely on objective data related to workload, distractions, and similar factors.',
    time_spent: 'Time spent',
    score: 'Score:',
    missing_title: 'Missing Daily Reports Notification',
    go_to_date: 'Write report',
    write: 'Write report',
    missing_limit: 'Missing Daily Report Limit',
    editable_period: 'Editable Time Period',
    missing_limit_desc:
      ' If a person fails to submit their daily report for more than {{number}} work days, they will not be able to start work the next day.',
    editable_period_desc:
      'Daily report entries can be edited within {{number}} work days from today.',
    report_warning:
      'Please submit your daily reports on time. Failure to do so will result in you being unable to start work the next day.Thank you.',
    complete_desc: 'All daily report entries are completed. ',
  },
  TIME_RECORDS: {
    work_start: 'Work start',
    work_end: 'Work end',
    shift_start: 'Shift start',
    shift_end: 'Shift end',
    time_off: 'Time off',
    work_time_info: 'Info',
    work_done: 'Work Done',
    end_confirm_title: 'Are you sure you want to end work',
    end_confirm_desc: 'Please check work time before proceeding',
    break_start_confirm_title: 'Are you sure want to start shift time',
    break_start_confirm_desc: 'Please check before proceeding',
    break_end_confirm_title: 'Are you sure you want to end shift time',
    break_end_confirm_desc: 'Please check before proceeding',
    attendance: 'Attendance',
    work_hours: 'Working hours',
    shift_hours: 'Shift records',
    pending: 'Pending',
    change_date_fields: 'Change time fields before submitting',
    shift_limit: 'Limit reached',
    permission_undefined: 'Location permission unavailable',
    permission_denied: 'Location permission denied',
    permission_allowed: 'Location permission allowed',
  },
  TIME_REPORT: {
    menu_title: 'Time report',
    employee: 'Employee',
    late_arrival: 'Late arrival',
    early_leave: 'Early leave',
    absence: 'Absence',
    time_off: 'Time off',
    total_deduct: 'Total deduction',
    worked_hours: 'Worked hours',
    overtime: 'Overtime',
    overtime_holiday: 'Overtime holiday',
    overtime_weekend: 'Overtime weekend',
    actual_overtime: 'Actual overtime',
    employee_placeholder: 'Select employees',
    filter_placeholder: 'Filter by overtime',
    year_month: 'Year and Month',
    total: 'Total',
    deduction_days: 'Deduction days',
    lunch_count: 'Lunch count',
    timeoff_report_title: 'TimeOff report',
    work_status_report: 'Work Status Report',
    remote_work: 'Remote work',
    office_work: 'Office work',
    insufficient_office: 'Day to work from office',
    insufficient_office_explanation:
      'Represents the days that were supposed to be worked from the office but were incomplete.',
    remote_without_approve_explanation:
      'Represents the days worked when the remote work request was not approved.',
    remote_without_approve: 'Days worked remotely without approval',
    days_off_work: 'Unauthorized absense',
    timeOff: 'TimeOff days',
    report: 'Report',
    work_status_summary: 'Work  Status Summary',
    accepted_remote_work: 'Approved remote work days',
    summary: 'Overview of employees work locations',
    total_work_days: 'Total workdays',
    summary_report: 'Overview',
    searched_date: 'Showing {{week}} weeks data between {{start}} and {{end}}',
    unrecorded_day: 'Unrecorded day',
    no_data: 'Data not found',
  },

  TIME_CORRECTION: {
    menu_title: 'Approve Time Correction',
    title: 'Time Correction Approve List',
    work_date: 'Work date',
    status: 'Status',
    reason: 'Reason',
    created_at: 'Created at',
    pending: 'Pending',
    rejected: 'Rejected',
    accepted: 'Accepted',
    deleted: 'Deleted',
    approve_modal_title: 'Approve request',
    approve_modal_description: 'Are you sure you want to approve this request?',
    approve_success_title: 'Approved',
    approve_success_description:
      'Successfully approved the request by {{employee}}',
    deny_modal_title: 'Reject request',
    deny_modal_description: 'Are you sure you want to reject this request?',
    deny_success_title: 'Rejected',
    deny_success_description:
      'Successfully rejected the request by {{employee}}',
    employee: 'Employee',
    time_record: 'Time record',
    filter_title: 'Filter Time Correction requests.',
    my_list_title: 'My Time Correction',
    delete_modal_title: 'Delete time correction request',
    delete_modal_description: 'Are you sure you want to delete this request?',
    delete_success_title: 'Deleted',
    delete_success_description: 'Successfully deleted the request in {{date}}',
    user_code: 'User Code',
    current_status: 'Current status',
  },
  TIME_CORRECTION_TEMPLATE: {
    title: 'Time correction template',
    name: 'Name',
    template_name: 'Template name',
    approval_template: 'Approval Template',
    save_confirm_title: 'Confirm save time correction template details',
    save_confirm_desc:
      'Do you wish to proceed? Make sure all details are accurate before saving.',
  },
  REMOTE_WORK_TEMPLATE: {
    title: 'Remote work template',
    menu_title: 'Template settings',
    name: 'Name',
    approval_template: 'Approval template',
    template_name: 'Template name',
    save_confirm_title: 'Confirm save remote work template details',
    save_confirm_desc:
      'Do you wish to proceed? Make sure all details are accurate before saving.',
    limit_exit_template: 'Additional approval template',
  },
  REMOTE_WORK_REQUEST: {
    title: 'Remote work requests',
    menu_title: 'My requests',
    create_success_title: 'Remote work request created',
    description: 'To do',
    description_by_date: 'To do on {{date}}',
    work_date: 'Work date',
    create_title: 'Create Remote work request',
    update_title: 'Update Remote work request',
    add_date: 'Add date',
    approve_title: 'Approve list',
    approve_all: 'Approve all',
    all_same_description: 'All dates same description',
    limit: 'Limit',
    delete_modal_title:
      'Are you sure you want to delete this remote work request?',
    delete_modal_description:
      'Do you wish to proceed? This process cannot be undone.',
    filter_title: 'Filter remote work requests',
    remote_dates: 'Remote work request dates',
    deleted_by: 'Delete by',
    denied_by: 'Rejected by',
    possible_my_time: 'Available duration for taking My time',
    total_time: 'Total duration',
    start_work: 'Please start your work.',
    started_work: 'Already started your work',
    reason_required_desc:
      'Write reason if any remote work day is not approved.',
  },
  REMOTE_WORK_SETTINGS: {
    title: 'Disable Remote work',
    menu_title: 'Disable',
    name: 'Employee name',
    start_date: 'Start date',
    end_date: 'End date',
    reason: 'Reason',
    create_title: 'Disable remote work request',
    info: 'A remote work request has been created for the following days at the date you entered',
    force_create: 'Force create',
    force_update: 'Force update',
    save_confirm_title: 'Confirm save Remote work disable',
    save_confirm_desc:
      'Do you wish to proceed? Make sure all details are accurate before saving.',
    delete_modal_title: 'Enable Remote work',
    delete_modal_description:
      'Are you sure you want to enable remote work request ?',
    create_success_title: 'Remote work request disabled',
    create_success_desc: 'Remote work request disabled {{start}} to {{end}}.',
    disabled_by: 'Disabled by',
    disabled_date: 'Disabled date',
    disabled_log_title: 'Remote work disabled log',
    disabled_log_menu: 'Disabled log',
    filter_title: 'Filter Remote work disabled ',
  },
  NOTIFICATIONS: {
    mark_all_as_read: 'Mark all as read',
    mark_as_read: 'Mark as read',
  },

  MESSAGES: {
    NT000001: 'has recently added you to the {{name}} project',
    NT000002: 'has recently removed you from the {{name}} project	',
    NT000004: 'has recently requested {{name}}.',
    NT000005: 'has recently approved {{name}} request.',
    NT000006: 'has recently rejected {{name}} request.',
    NT000007: 'has recently requested time correction on {{date}}.',
    NT000008: 'has recently approved your time correction request on {{date}}.',
    NT000009: 'has recently rejected your time correction request on {{date}}.',
    NT000010: 'Remote work request received',
    NT000011: 'Remote work request accepted',
    NT000012: 'Remote work request rejected',
    NT000013: 'Remote work request permission disabled. Between {{date}}',
  },
  QUIT_USERS: {
    title: 'Quit employees',
    employee_name: 'Name',
    quit_date: 'Quit date',
    reason: 'Reason',
    start_date: 'Start date',
    end_date: 'End date',
    filter_title: 'Filter quit users by date',
    save_confirm_title: 'Confirm update Quit Reason',
    save_confirm_desc:
      'Do you wish to proceed? Make sure all details are accurate before updating.',
  },
  INFO_LOG: {
    title: 'Employee info changes',
    menu_title: 'Info changes',
    count: '{{number}} changes',
    firstname: 'firstname',
    lastname: 'lastname',
    firstname_en: 'firtsname EN',
    lastname_en: 'lastname EN',
    birthday: 'birthday',
    gender: 'gender',
    home_address: 'home address',
    phone: 'phone',
    email: 'email',
    phone_secondary: 'phone secondaries',
    user_code: 'user code',
    role_id: 'role',
    position_id: 'position',
    division_id: 'division',
    hired_date: 'hired date',
    ndsh_months: 'NDSH months',
    ndsh_total: 'NDSH total',
    status: 'status',
    empty: 'empty',
    deleted: 'deleted',
    created: 'created',
    employee_label: 'Employee',
    something_changed: '{{what}} changed.',
    performed_by: 'Performed by',
    performed_on: 'on',
  },
  COMPANY_RULE: {
    title: 'Our rules',
    back_confirm_title: 'It looks like you have been editing something.',
    back_confirm_desc: 'If you leave before saving, your changes will be lost.',
    updated_by: 'Last updated on {{date}}, updated by {{employee}}',
    save_confirm_title: 'Confirm save Our rules',
    save_confirm_desc:
      'Do you wish to proceed? Make sure all details are accurate before saving.',
  },
  WORKSPACE: {
    title: 'Workspace',
    name: 'Name',
    description: 'Description',
    location: 'Location',
    ips: 'IPs',
    status: 'Status',
    create_title: 'Create Workspace',
    create_success_title: 'New workspace added',
    create_success_desc:
      'New event added with id: {{id}} and with name: {{name}}',
    save_confirm_title: 'Confirm Save Workspace',
    save_confirm_desc:
      'Do you wish to proceed? Make sure all details are accurate before saving.',
    delete_modal_title: 'Delete workspace',
    delete_modal_description: 'Are you sure you want to delete this workspace',
    change_status_modal_title: '{{action}} workspace',
    change_status_modal_description:
      'Are you sure you want to {{action}} this workspace',
    activate: 'Activate',
    deactivate: 'Deactivate',
    deactivated_date: 'Deactivated at',
    active: 'Active',
    in_active: 'Inactive',
    map_breadcrumb: 'Workspace location',
    map_title: "{{workspace}}'s location",
    inactive_at: 'Inactive at',
    ips_placeholder: '1.1.1.1, 2.2.2.2, etc',
    work_start_location: 'Work start location',
    work_end_location: 'Work end location',
    remote_placeholder: 'We have registered you as working remotely',
    office_placeholder: 'We have registered you as working from {{office}}',
    verification_code: 'Verification code',
    change_location: 'Change location',
    uid: 'UID',
    no_location_placeholder:
      'We cannot determine your location because you did not allow location permission.',
    no_camera: 'No device detected or no permission granted',
    invalid_qr: 'The scanned QR code is invalid',
    qr_title: 'QR Scanner',
  },
  COMPENSATORY_DAYS: {
    title: 'Compensatory days',
    created_date: 'Created date',
    expire_date: 'Expire date',
    earned_days: 'Earned day(s)',
    remaining_days: 'Remaining day(s)',
    comment: 'Comment',
  },
  DEMO: {
    notification: 'You are using demo version of app',
    demo_users: 'Choose one of the demo users to instantly log in:',
    demo_desc: 'Use the credentials below to log in and try our app:',
  },
  ERROR: {
    E000001: '{{field}} is required',
    E000002: 'Field is required',
    E000003: 'Passwords should match',
    E000004: '{{field}} must be in the future',
    E000005: 'End date must be greater',
    E000006: 'Invalid Date',
    E000007: 'Start at must be lesser',
    E000008: 'Minimum limit is 30 mins',
    E000009: 'Change to compensatory request',
    E000010: 'Both dates must be on same day',
    E000046: '{{field}} must be within {{number}} characters',
    E000047: '{{field}} must be at least {{number}} characters',
    E000048: '{{field}} must be valid',
    E000049: '{{field}} has invalid character(s)',
    E000050: '{{field}} needs to be {{number}} character(s)',
    E000051: '{{field}} must contain number',
    E000052: '{{field}} must contain lowercase letter',
    E000053: '{{field}} must contain uppercase letter',
    E000054: '{{field}} must contain at least one of these !@#$%^&*()-+',
    E000055: 'Field values is empty',
    E000056: 'Enter valid phone number',
    E000057: '{{field}} must contain a letter',
    E000058: 'Enter different password than old password',
    E000060: 'Start date is required',
    E000061: 'Start end dates must be same month',
    E000062: 'Failed to delete item',
    E000063: 'Enter valid number',
    E000064: 'Cost start date must be lesser than expire date',
    E000065: "Can't select any date before previous month",
    E000066: 'Failed to upload',
    E000067: 'End time must be greater',
    E000068: 'Start time must be in future',
    E000069: '{{field}} must be on or after 7:00 AM',
    E000070: 'At least 1 email recipient must be selected',
    E000071: 'Year must be current year or next year',
    E000072: 'The start date and end date must be the same year as the year',
    E000073: 'The end time must be after at least 15 min.',
    E000074: 'Break time must be during work hours.',
    BE000001: 'Cant be blank',
    BE000002: 'Field is required',
    BE000003: 'Field is required',
    BE000021: 'Not exist',
    BE000022: 'Field value is taken',
    BE000046: 'Field value is too long',
    BE000047: 'Field value is too short',
    BE000048: 'Date validation is failed',
    BE000049: 'Date validation is failed',
    BE000050: 'Value is out of range',
    BE000052: 'Year must be number',
    BE000059: 'Not valid type',
    BE000060: 'Start time must be in future',
    BE000061: 'Selected days must be same day.',
    BE000062: 'There is an overlap during the date',
    BE000063: 'Unable to perform the action. Work has not started yet.',
    BE000065: 'Owner only can delete its own record',
    BE000066: 'Work already started',
    BE000067: 'Finish break time before submitting',
    BE000068: 'Start break time first',
    BE000073: 'Shift time limit reached',
    BE000078: 'Not enough compensatory days',
    BE000079: 'My time limit exceed',
    BE000080: 'Vacation balance not exist',
    BE000081: 'Not enough vacation balance',
    BE000082: 'Invalid IPs',
    BE000051: 'Cant be blank',
    BE000054: 'The selected option is not valid',
    BE000055: 'Must be a string',
    BE000056: 'Must be an integer',
    BE000057: 'Must be a valid date',
    BE000058: 'Must be valid JSON',
    BE000064: 'Must be greater than or equal to the specified value',
    BE000069: 'Only one of them is required',
    BE000070: 'The project is not yours',
    BE000072: 'At least one record should exist',
    BE000074: 'Must be equal to the specified value',
    BE000075: 'Compensatory days already exist',
    BE000076: 'Must be the next interval',
    BE000077: 'Unable to perform calculation',
    BE000083: 'Must be a valid time',
    AE000001:
      'This item cannot be deleted because it is associated with other data.',
    AE000002:
      'Cannot confirm because there is already one confirmed plan on {{date}}',
    AE000003:
      'Unable to delete or update ownership. A project must have at least one owner at all times.',
    AE000004:
      'The resource plan you are attempting to delete has been confirmed and cannot be removed.',
    AE000005: 'The resource plan has not been confirmed.',
    AE000006: 'The resource plan target month is wrong.',
    AE000007:
      'Between start date and end date, there is a cost at the position.',
    AE000008: 'A meeting has been booken in that room',
    AE000009: 'Your vacation balance is not enough.',
    AE000010: 'You have no compensatory day balance.',
    AE000011: 'You have no MyTime balance.',
    AE000012: "Couldn't find the approver.",
    AE000014:
      'The workation request failed because it is either not within the vacation period or it is a duplicate request.',
    AE0000016: 'A Employee vacation balance is already created.',
    AE000020: 'There is a pending request that uses this template.',
    AE000021: 'Approval template details update failed.',
    AE000029: "Selected dates can't be in the past",
    AE000028: 'You already have long term leave',
    AE100033:
      'Your entered password is the same as your old password, enter different password.',
    AE000030: 'The time limit for sending time correction request has passed',
    AE000031: 'Verification code is wrong, Please try again',
    AE000032: 'Past orders cannot be deleted.',
    AE000033: 'Reason required for deny request',
    AE000034: 'Remote work request exists',
    AE000035: 'Remote work request already accepted',
    AE000036: 'Remote work template not found',
    AE000038: 'Remote work request can not be delete',
    AE000039: 'Remote work date overlap',
    AE000040: 'Remote work disabled',
    AE000042: 'Missing params',
    AE000043: 'Workspace not found',
    AE000044: 'Remote work disable date overlap',
    AE000045: 'A remote work request must have at least 1 day',
    AE000046: 'Missing daily report limit reached',
    AE000047:
      'This report falls outside of the allowable edit period and cannot be modified.',
    AE000048: 'The duration is not within the minimum and maximum range.',
    AE000049: 'My time request duration exceeded',
    AE000071: 'Not in valid time range',
    AE000050: 'End date must be greater',
    AE000051: 'Sync error',
    AE000052: 'Invalid Format',
    AE000053: 'This step has already been added',
    AE000054: 'The period for using compensatory day has expired',
    AE000056: 'There is a daily report that uses this work type.',
    ABE000004: 'Field is required',
    AE100034: 'Passwords should match',
    ABE100014: 'Enter different password than old password',
  },
}
