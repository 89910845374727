import { URI } from '@constants/uri.constants'
import { api, publicApi } from './api'
import { EmployeeShortItem } from './user.services'
import {
  DAYS,
  FilterParams,
  FREQUENCY,
  MetaPage,
} from '@constants/common.constants'
import { WORK_TIME_ACTION } from '@constants/time.constants'

// PAGE HELPER SERVICES

export type ContentResponse = {
  title: string
  content: string
}

export const getPageContent = async (id: string): Promise<any> => {
  const { data } = await publicApi.get<ContentResponse>(`/posts/${id}`)
  return data
}

// Events

export type BirthdayItem = {
  id: string
  type: 'birthday'
  attributes: {
    user_code: string
    firstname: string
    lastname: string
    firstname_en: string
    lastname_en: string
    avatar: null | string
    birthday: Date
  }
}
export type EventItem = {
  id: string
  type: 'event'
  attributes: {
    name: string
    description: string
    holiday_flag: boolean
    start_date: Date
    end_date: Date
  }
}

export type EventCreateParams = {
  name: string
  description: string
  holiday_flag: boolean
  start_date: string
  end_date: string
}

export type EventUpdateParams = {
  id: string
  params: EventCreateParams
}

export type EventResponse = {
  events: { data: EventItem[] }
  birthday: { data: BirthdayItem[] }
}

export type EventCreateResponse = {
  data: EventItem
}

export type MeetingRoomSelectType = {
  id: number
  name: string
}

export type MeetingRoomItem = {
  id: string
  type: string
  attributes: {
    name: string
    description?: string
    display_order: number
  }
}
export type MeetingRoomResponse = {
  data: MeetingRoomItem
}

export type MeetingRoomOrderListResponse = {
  data: MeetingRoomOrderResponse[]
}

export type MeetingRoomOrderResponse = {
  id: string
  type: 'meeting_room_order'
  attributes: {
    subject: string
    start_time: Date
    end_time: Date
    meeting_room_id: string
    created_by: number
    frequency: FREQUENCY
    recurrence_rule?: {
      interval: number
      days_of_week: number[]
      days_of_month: number[]
    }
    expire_date?: string
  }
}
export type MeetinRoomOrderCreateResponse = {
  data: MeetingRoomOrderResponse
}

export type MeetingRoomOrderCreateParams = {
  subject: string
  start_time: Date
  end_time: Date
  meeting_room_id: string
  expire_date?: string
  frequency: FREQUENCY
  recurrence_rule?: {
    interval: number
    days_of_week: DAYS[]
    days_of_month: number[]
  }
}

export type MeetingRoomOrderUpdateFields = {
  subject: string
  start_time: Date
  end_time: Date
  meeting_room_id: string
  expire_date?: string
  frequency: FREQUENCY
  recurrence_rule?: {
    interval: number
    days_of_week: DAYS[]
    days_of_month: number[]
  }
  is_update_all?: boolean
}

export type MeetingRoomOrderUpdateParams = {
  id: string
  params: MeetingRoomOrderUpdateFields
}

export type MeetingRoomOrderParams = {
  meeting_room_id: string
  start_date?: Date
  end_date?: Date
}

export type EventParams = {
  holiday_flag: boolean
}

export type SystemSettingsItem = {
  id: string
  type: 'company_settings'
  attributes: {
    name: string
    value: string
    value_type: string
    description: string
  }
}

export type SystemSettingsListResponse = {
  data: SystemSettingsItem[]
  meta: MetaPage
}

export type SystemSettingsParams = {
  id: string
  params: {
    name: string
    value: string
    value_type: any
  }
}

export type SystemSettingsRes = {
  message: string
}

export const eventListAllService = async (
  params: EventParams
): Promise<EventResponse> => {
  const { data } = await api.get<EventResponse>(URI.EVENT_LIST, {
    params: params,
  })
  return data
}

export const eventListPublicService = async (): Promise<EventResponse> => {
  const { data } = await api.get<EventResponse>(URI.EVENTS)
  return data
}

export const eventCreateService = async (
  params: EventCreateParams
): Promise<EventCreateResponse> => {
  const { data } = await api.post<EventCreateResponse>(URI.EVENTS, params)
  return data
}

export const eventUpdateService = async (
  params: EventUpdateParams
): Promise<EventCreateResponse> => {
  const { data } = await api.put<EventCreateResponse>(
    `${URI.EVENTS}/${params.id}`,
    params.params
  )
  return data
}

export const eventDeleteService = async (id: string): Promise<any> => {
  const { data } = await api.delete<any>(`${URI.EVENTS}/${id}`)
  return data
}

//Meeting rooms

export type MeetingRoomCreateParams = {
  name: string
  description: string
}

export type MeetingRoomUpdateParams = {
  id: string
  params: MeetingRoomCreateParams
}

export type MeetingRoomUpdateOrderParams = {
  meeting_rooms: { id: string; display_order: number }[]
}

export type MeetingRoomIdParams = string

export type MeetingRoomListResponse = { data: MeetingRoomItem[] }

export type RoomDeleteParams = {
  id: string
  payload?: {
    start_time: Date | string
    end_time: Date | string
  }
}
export const meetingRoomListService =
  async (): Promise<MeetingRoomListResponse> => {
    const { data } = await api.get<MeetingRoomListResponse>(URI.MEETING_ROOM)
    return data
  }

export const meetingRoomDetailService = async (
  params: MeetingRoomIdParams
): Promise<MeetingRoomResponse> => {
  const { data } = await api.get<MeetingRoomResponse>(
    `${URI.MEETING_ROOM}/${params}`
  )
  return data
}

export const meetingRoomCreateService = async (
  params: MeetingRoomCreateParams
): Promise<MeetingRoomResponse> => {
  const { data } = await api.post<MeetingRoomResponse>(URI.MEETING_ROOM, params)
  return data
}

export const meetingRoomUpdateService = async (
  params: MeetingRoomUpdateParams
): Promise<MeetingRoomResponse> => {
  const { data } = await api.put<MeetingRoomResponse>(
    `${URI.MEETING_ROOM}/${params.id}`,
    params.params
  )
  return data
}

export const meetingRoomUpdateOrderService = async (
  params: MeetingRoomUpdateOrderParams
): Promise<MeetingRoomListResponse> => {
  const { data } = await api.put<MeetingRoomListResponse>(
    URI.MEETING_ROOM_UPDATE_ORDER,
    params
  )
  return data
}

export const meetingRoomDeleteService = async (
  params: MeetingRoomIdParams
): Promise<any> => {
  const data = api.delete<any>(`${URI.MEETING_ROOM}/${params}`)
  return data
}

export const meetingRoomOrderListService = async (
  params: MeetingRoomOrderParams
): Promise<MeetingRoomOrderListResponse> => {
  const { data } = await api.get<MeetingRoomOrderListResponse>(
    `${URI.MEETING_ROOM_ORDER}`,
    { params: params }
  )
  return data
}
//Time table

export type TimeTableCreateParams = {
  start_at: string
  end_at: string
  confirmed_by: number
  status: number
  created_by: number
}

export type TimeTableUpdateParams = {
  id: string
  params: TimeTableCreateParams
}

export type TimeTableIdParams = string

export type TimeTableItem = {
  id: string
  type: string
  attributes: {
    start_at: string
    end_at: string
    confirmed_by: number
    status: string
    created_by: number
  }
}

export type TimeTableResponse = {
  data: TimeTableItem
}

export type TimeTableListResponse = {
  data: TimeTableItem[]
}

export const timeTableListService =
  async (): Promise<TimeTableListResponse> => {
    const { data } = await api.get<TimeTableListResponse>(URI.TIME_TABLE)
    return data
  }

export const timeTableDetailService = async (
  params: TimeTableIdParams
): Promise<TimeTableResponse> => {
  const { data } = await api.get<TimeTableResponse>(
    `${URI.MEETING_ROOM}/${params}`
  )
  return data
}

export const meetingRoomOrderCreateService = async (
  params: MeetingRoomOrderCreateParams
): Promise<MeetingRoomOrderResponse> => {
  const { data } = await api.post<MeetingRoomOrderResponse>(
    URI.MEETING_ROOM_ORDER,
    params
  )
  return data
}

export const timeTableCreateService = async (
  params: TimeTableCreateParams
): Promise<TimeTableResponse> => {
  const { data } = await api.post<TimeTableResponse>(URI.TIME_TABLE, params)
  return data
}

export const timeTableUpdateService = async (
  params: TimeTableUpdateParams
): Promise<TimeTableResponse> => {
  const { data } = await api.put<TimeTableResponse>(
    `${URI.TIME_TABLE}/${params.id}`,
    params.params
  )
  return data
}

export const meetingRoomOrderDetailService = async (
  params: MeetingRoomIdParams
): Promise<MeetingRoomOrderResponse> => {
  const { data } = await api.get<MeetingRoomOrderResponse>(
    `${URI.MEETING_ROOM_ORDER}/${params}`
  )
  return data
}

export const meetingRoomOrderUpdateService = async (
  arg: MeetingRoomOrderUpdateParams
): Promise<MeetingRoomOrderResponse> => {
  const { data } = await api.put<MeetingRoomOrderResponse>(
    `${URI.MEETING_ROOM_ORDER}/${arg.id}`,
    arg.params
  )
  return data
}

export const meetingRoomOrderDeleteService = async (
  params: RoomDeleteParams
): Promise<any> => {
  const data = api.delete<any>(`${URI.MEETING_ROOM_ORDER}/${params.id}`, {
    params: params?.payload,
  })
  return data
}

export const timeTableDeleteService = async (
  params: TimeTableIdParams
): Promise<any> => {
  const { data } = await api.delete<any>(`${URI.TIME_TABLE}/${params}`)
  return data
}

export const systemSettingsListService = async (
  params: FilterParams
): Promise<SystemSettingsListResponse> => {
  const { data } = await api.get<SystemSettingsListResponse>(
    URI.SYSTEM_SETTINGS,
    { params: params }
  )
  return data
}

export const systemSettingsCreateService = async (
  params: SystemSettingsParams
): Promise<SystemSettingsRes> => {
  const { data } = await api.post<SystemSettingsRes>(
    URI.SYSTEM_SETTINGS,
    params
  )
  return data
}

export const systemSettingsUpdateService = async (
  params: SystemSettingsParams
): Promise<SystemSettingsRes> => {
  const { data } = await api.put<SystemSettingsRes>(
    `${URI.SYSTEM_SETTINGS}/${params.id}`,
    params.params
  )
  return data
}

export type CompanyRuleItem = {
  id: string
  type: 'company_rule'
  attributes: {
    content: string
    updated_at: string
    updated_by: {
      data: EmployeeShortItem
    }
  }
}

export type CompanyRuleParams = {
  content: string
}

export type CompanyRulesResponse = {
  data: CompanyRuleItem
}

export const fetchCompanyRulesService =
  async (): Promise<CompanyRulesResponse> => {
    const { data } = await api.get<CompanyRulesResponse>(URI.COMPANY_RULES)
    return data
  }

export const companyRulesUpdateService = async (
  params: CompanyRuleParams
): Promise<CompanyRulesResponse> => {
  const { data } = await api.post<CompanyRulesResponse>(
    URI.COMPANY_RULES,
    params
  )
  return data
}

export type WorkspaceItem = {
  id: string
  type: 'workspace'
  attributes: {
    name: string
    description: string | null
    location: any[]
    ips: string | null
    inactive_at: Date
    uid: string
  }
}

export type WorkspaceSelectType = {
  id: number
  name: string
}

export type WorkspaceListResponse = {
  data: WorkspaceItem[]
  meta: MetaPage
}

export type WorkspaceAllListResponse = {
  data: WorkspaceItem[]
}
export type WorkspaceResponse = {
  data: WorkspaceItem
}

export type WorkspaceCreateParams = {
  name: string
  description?: string
  location?: string
  ips?: string
}

export type WorkspaceVerifyParams = {
  id: string
  params: {
    action_type: WORK_TIME_ACTION
    time_record_id: string
    code: string
  }
}

export type WorkspaceUpdateParams = {
  id: string
  params: {
    name?: string
    description?: string
    location?: any[] | null
    ips?: string
    inactive_at?: Date | null
  }
}

export type CoordinateType = {
  lng: number
  lat: number
}

export const fetchWorkspaceListService = async (
  params: FilterParams
): Promise<WorkspaceListResponse> => {
  const { data } = await api.get<WorkspaceListResponse>(URI.WORKSPACE, {
    params: params,
  })
  return data
}

export const createWorkspaceService = async (
  params: WorkspaceCreateParams
): Promise<WorkspaceResponse> => {
  const { data } = await api.post<WorkspaceResponse>(URI.WORKSPACE, params)
  return data
}

export const updateWorkspaceService = async (
  params: WorkspaceUpdateParams
): Promise<WorkspaceResponse> => {
  const { data } = await api.put<WorkspaceResponse>(
    `${URI.WORKSPACE}/${params.id}`,
    params.params
  )
  return data
}

export const showWorkspaceService = async (
  idParams: string
): Promise<WorkspaceResponse> => {
  const { data } = await api.get<WorkspaceResponse>(
    `${URI.WORKSPACE}/${idParams}`
  )
  return data
}

export const deleteWorkspaceService = async (params: string): Promise<any> => {
  const data = api.delete<any>(`${URI.WORKSPACE}/${params}`)
  return data
}

export const workSpaceStatusChangeService = async (
  params: string
): Promise<WorkspaceResponse> => {
  const { data } = await api.post<WorkspaceResponse>(
    `${URI.WORKSPACE}/${params}/status_change`
  )
  return data
}

export const verifyWorkspaceTokenService = async (
  params: WorkspaceVerifyParams
): Promise<any> => {
  const { data } = await api.post<any>(
    `${URI.WORKSPACE}/${params.id}/verify_token`,
    params.params
  )
  return data
}

export const fetchAllWorkspaceListService =
  async (): Promise<WorkspaceAllListResponse> => {
    const { data } = await api.get<WorkspaceAllListResponse>(
      `${URI.WORKSPACE}/list`
    )
    return data
  }
export const regenerateWorkspaceUidService = async (
  params: string
): Promise<WorkspaceResponse> => {
  const { data } = await api.post<WorkspaceResponse>(
    `${URI.WORKSPACE}/${params}/regenerate_token`
  )
  return data
}
