/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Badge,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { BreakRecordItem, TimeRecordItem } from '@services/time.services'
import { formatDate } from '@utils/helper/common.helper'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { BedRounded, Close, ReadMore } from '@mui/icons-material'

interface MyComponentProps {
  timeRecord: TimeRecordItem // Replace YourRecordType with the actual type of your record
  breakRecords?: BreakRecordItem[]
  onMore?: () => void
  isMobile: boolean
}

const TimeStatusTable: React.FC<MyComponentProps> = ({
  timeRecord,
  breakRecords,
  onMore,
  isMobile,
}) => {
  const { t } = useTranslation()
  const latestBreakRecordItem = _.maxBy(breakRecords, 'attributes.start_at')
  const [open, setOpen] = useState<boolean>(false)
  const isMore = breakRecords && breakRecords.length > 1

  return (
    <Box
      sx={{
        position: 'relative',
        background: '#fff',
        width: 'auto',
        textAlign: 'center',
      }}
    >
      <Table
        sx={{
          width: !isMobile ? 180 : 160,
          border: '1px solid #ccc',
          borderBottom: '0 none',
          '& .MuiTableCell-root': {
            lineHeight: 1.2,
            py: '2px',
            px: 0,
            borderRight: '1px solid #ccc',
            fontSize: 12,
            minWidth: !isMobile ? 45 : 30,
            width: !isMobile ? 45 : 30,
            maxWidth: !isMobile ? 45 : 30,
            textAlign: 'center',
            overflow: 'inherit',
          },
        }}
      >
        <TableHead
          sx={{
            background: (theme) => theme.palette.secondary.light,
            color: '#fff',
          }}
        >
          <TableRow>
            <TableCell colSpan={4}>
              {t('TIME_RECORDS.attendance')}{' '}
              {formatDate(
                _.get(timeRecord, 'attributes.work_date', ''),
                'YYYY-MM-DD'
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        {/* Your component JSX here */}
        <TableBody>
          <TableRow>
            <TableCell>
              {formatDate(
                _.get(timeRecord, 'attributes.start_at', ''),
                'HH:mm'
              )}
            </TableCell>
            <TableCell>
              {formatDate(
                _.get(latestBreakRecordItem, 'attributes.start_at', ''),

                'HH:mm'
              )}
            </TableCell>
            <TableCell>
              <Badge
                onClick={() => isMore && setOpen(true)}
                badgeContent={isMore ? breakRecords?.length : 0}
                sx={{
                  cursor: isMore ? 'pointer' : 'inherit',
                  verticalAlign: 'top',
                  '& .MuiBadge-badge': {
                    fontSize: 10,
                    width: 15,
                    height: 15,
                    left: 15,
                  },
                }}
                color="primary"
              >
                {formatDate(
                  _.get(latestBreakRecordItem, 'attributes.end_at', ''),
                  'HH:mm'
                )}
              </Badge>
            </TableCell>
            <TableCell>
              {formatDate(_.get(timeRecord, 'attributes.end_at', ''), 'HH:mm')}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Dialog maxWidth={false} open={open} onClose={() => setOpen(false)}>
        <DialogTitle sx={{ borderBottom: '1px solid #ccc' }}>
          {formatDate(
            _.get(timeRecord, 'attributes.work_date', ''),
            'YYYY-MM-DD'
          )}
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 4,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent
          sx={{
            width: '100%',
            maxWidth: 'auto',
            minWidth: '300px',
          }}
        >
          <Box sx={{ width: '100%' }}>
            {' '}
            <Table
              sx={{
                width: '100%',
                border: '1px solid #ccc',
                borderBottom: '0 none',
                mb: 1,
                '& .MuiTableCell-root': {
                  lineHeight: 1.2,
                  py: '2px',
                  px: '8px',
                  borderRight: '1px solid #ccc',
                  fontSize: 14,
                  minWidth: 45,
                  width: 45,
                  maxWidth: 45,
                  textAlign: 'center',
                  overflow: 'inherit',
                },
              }}
            >
              <TableHead
                sx={{
                  background: (theme) => theme.palette.secondary.main,
                  color: '#fff',
                }}
              >
                <TableRow>
                  <TableCell sx={{ color: '#fff' }} colSpan={2}>
                    {t('TIME_RECORDS.work_hours')}
                  </TableCell>
                </TableRow>
              </TableHead>
              {/* Your component JSX here */}
              <TableBody>
                <TableRow>
                  <TableCell>
                    {formatDate(
                      _.get(timeRecord, 'attributes.start_at', ''),
                      'HH:mm'
                    )}
                  </TableCell>
                  <TableCell>
                    {formatDate(
                      _.get(timeRecord, 'attributes.end_at', ''),
                      'HH:mm'
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table
              sx={{
                width: '100%',
                border: '1px solid #ccc',
                borderBottom: '0 none',
                '& .MuiTableCell-root': {
                  lineHeight: 1.2,
                  py: '2px',
                  px: '8px',
                  borderRight: '1px solid #ccc',
                  fontSize: 14,
                  minWidth: 45,
                  width: 45,
                  maxWidth: 45,
                  textAlign: 'center',
                  overflow: 'inherit',
                },
              }}
            >
              <TableHead
                sx={{
                  background: (theme) => theme.palette.secondary.main,
                  color: '#fff',
                }}
              >
                <TableRow>
                  <TableCell sx={{ color: '#fff' }} colSpan={2}>
                    {t('TIME_RECORDS.shift_hours')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {breakRecords &&
                  breakRecords.map((b) => {
                    return (
                      <TableRow key={b.id}>
                        <TableCell>
                          {formatDate(
                            _.get(b, 'attributes.start_at', ''),
                            'HH:mm'
                          )}
                        </TableCell>
                        <TableCell>
                          {formatDate(
                            _.get(b, 'attributes.end_at', ''),
                            'HH:mm'
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default TimeStatusTable
