import i18n from '@locales/i18n'
import { ReactNode } from 'react'
import { MODULES } from './modules.constants'
import { ROUTE } from './route.constants'
import CustomIcon from '@components/@material-extend/CustomIcon'

export type MenuItemChildren = {
  uri?: string
  label: string
  type: 'nav-item' | 'collapsible'
  icon: ReactNode
  module?: MODULES
  children?: MenuItemChildren[]
  isExact?: boolean
  matchGroups?: string[]
  isAdminOnly?: boolean
}

export type MenuItem = {
  label: string
  type: string
  children: MenuItemChildren[]
  module?: MODULES
  isExact?: boolean
  matchGroups?: string[]
}

const generateMenus = (): MenuItem[] => {
  const menuArray: MenuItem[] = [
    {
      label: i18n.t('SYSCOMMON.home'),
      type: 'section',
      children: [
        {
          uri: ROUTE.DASHBOARDS,
          label: i18n.t('SYSCOMMON.dashboard'),
          type: 'nav-item',
          icon: <CustomIcon iconName="dashboard" />,
        },
        {
          uri: ROUTE.EMPLOYEE.ROOT,
          label: i18n.t('SYSCOMMON.employees'),
          type: 'nav-item',
          icon: <CustomIcon iconName="employee" />,
          isExact: false,
          matchGroups: [ROUTE.EMPLOYEE.ROOT, ROUTE.EMPLOYEE.DETAILS],
        },
        {
          uri: ROUTE.PROJECT.ROOT,
          label: i18n.t('SYSCOMMON.projects'),
          type: 'nav-item',
          module: MODULES.PROJECTS,
          icon: <CustomIcon iconName="project" />,
          isExact: false,
          matchGroups: [
            ROUTE.PROJECT.ROOT,
            ROUTE.PROJECT.OVERVIEW,
            ROUTE.PROJECT.SETTINGS,
            ROUTE.PROJECT.MEMBER,
            ROUTE.PROJECT.AVTIVITY,
          ],
        },
        {
          uri: ROUTE.BOOK_MEETING_ROOM,
          label: i18n.t('MEETING_ROOM_ORDER.title'),
          type: 'nav-item',
          icon: <CustomIcon iconName="meeting-room" />,
        },
        {
          uri: ROUTE.EVENTS.ROOT,
          label: i18n.t('SYSCOMMON.events'),
          type: 'nav-item',
          icon: <CustomIcon iconName="event" />,
        },
        {
          uri: ROUTE.COMPANY_RULES,
          label: i18n.t('COMPANY_RULE.title'),
          type: 'nav-item',
          icon: <CustomIcon iconName="rules" />,
        },
      ],
    },
    {
      label: i18n.t('DAILY_REPORT.menu_title'),
      type: 'section',
      module: MODULES.TIME,
      children: [
        {
          uri: ROUTE.DAILY_REPORT.ROOT,
          label: i18n.t('DAILY_REPORT.my_daily_report'),
          type: 'nav-item',
          icon: <CustomIcon iconName="daily-report" />,
        },
        {
          uri: ROUTE.DAILY_REPORT.TEAM,
          label: i18n.t('DAILY_REPORT.page_title_team'),
          type: 'nav-item',
          icon: <CustomIcon iconName="team-report" />,
        },
        {
          uri: ROUTE.DAILY_REPORT.HR,
          label: i18n.t('DAILY_REPORT.all_daily_report'),
          type: 'nav-item',
          icon: <CustomIcon iconName="all-report" />,
          module: MODULES.SETTINGS,
        },
      ],
    },
    {
      label: i18n.t('SYSCOMMON.time_management'),
      type: 'section',
      children: [
        {
          uri: ROUTE.TIMESHEET.ROOT,
          label: i18n.t('TIMESHEET.menu_title'),
          type: 'nav-item',
          module: MODULES.TIMESHEET,
          icon: <CustomIcon iconName="timesheet" />,
        },
        {
          uri: ROUTE.TIME_CORRECTION.REQUEST_LIST,
          label: i18n.t('TIME_CORRECTION.my_list_title'),
          type: 'nav-item',
          module: MODULES.TIME,
          icon: <CustomIcon iconName="time-correction" />,
        },
        {
          uri: ROUTE.TIME_CORRECTION.APPROVE_LIST,
          label: i18n.t('TIME_CORRECTION.menu_title'),
          type: 'nav-item',
          module: MODULES.TIME,
          icon: <CustomIcon iconName="approve-time-correction" />,
        },
        {
          label: i18n.t('SYSCOMMON.hr_related'),
          type: 'collapsible',
          icon: <CustomIcon iconName="hr_related" />,
          module: MODULES.TIME_SETTINGS,
          children: [
            {
              uri: ROUTE.VACATION_BALANCES.ROOT,
              label: i18n.t('VACATION_BALANCES.title'),
              type: 'nav-item',
              module: MODULES.TIME,
              icon: <CustomIcon iconName="vacation-report" />,
            },
            {
              uri: ROUTE.COMPENSATORY_DAYS.ROOT,
              label: i18n.t('COMPENSATORY_DAYS.title'),
              type: 'nav-item',
              module: MODULES.TIME,
              icon: <CustomIcon iconName="timesheet" />,
            },
            {
              uri: ROUTE.TIME.REPORT,
              label: i18n.t('TIME_REPORT.menu_title'),
              type: 'nav-item',
              module: MODULES.TIME,
              icon: <CustomIcon iconName="monthly-timeoff-report" />,
            },
            {
              uri: ROUTE.TIMEOFF_REPORT.ROOT,
              label: i18n.t('TIME_REPORT.timeoff_report_title'),
              type: 'nav-item',
              module: MODULES.TIME,
              icon: <CustomIcon iconName="monthly-timeoff-report" />,
            },
          ],
        },
      ],
    },
    {
      label: i18n.t('SYSCOMMON.time_off'),
      type: 'section',
      children: [
        {
          uri: ROUTE.TIMEOFF_REQUEST.LIST,
          label: i18n.t('TIMEOFF_REQUEST.menu_title'),
          type: 'nav-item',
          module: MODULES.TIME,
          icon: <CustomIcon iconName="mytime-offs" />,
        },
        {
          uri: ROUTE.TIMEOFF_APPROVE.LIST,
          label: i18n.t('TIMEOFF_REQUEST.approve_list_menu'),
          type: 'nav-item',
          module: MODULES.TIME,
          icon: <CustomIcon iconName="approve-list-2" />,
        },
        {
          label: i18n.t('SYSCOMMON.template_settings'),
          type: 'nav-item',
          icon: <CustomIcon iconName="template-settings" />,
          module: MODULES.TIME_SETTINGS,
          uri: ROUTE.TIMEOFF_TEMPLATE.LIST,
          isExact: false,
          matchGroups: [
            ROUTE.TIMEOFF_TEMPLATE.LIST,
            ROUTE.TIME_CORRECTION.TEMPLATE,
            ROUTE.APPROVAL_TEMPLATE.LIST,
          ],
        },
      ],
    },
    {
      label: i18n.t('SYSCOMMON.remote_work'),
      type: 'section',
      module: MODULES.REMOTE,
      children: [
        {
          uri: ROUTE.REMOTE_WORK.REQUEST,
          label: i18n.t('REMOTE_WORK_REQUEST.menu_title'),
          type: 'nav-item',
          module: MODULES.REMOTE,
          icon: <CustomIcon iconName="remote-request" />,
          isExact: false,
          matchGroups: [
            ROUTE.REMOTE_WORK.REQUEST,
            ROUTE.REMOTE_WORK.DISABLED_LOG,
          ],
        },
        {
          uri: ROUTE.REMOTE_WORK.APPROVE,
          label: i18n.t('REMOTE_WORK_REQUEST.approve_title'),
          type: 'nav-item',
          module: MODULES.REMOTE,
          icon: <CustomIcon iconName="approve-list" />,
        },
        {
          uri: ROUTE.REMOTE_WORK.SETTINGS,
          label: i18n.t('REMOTE_WORK_SETTINGS.menu_title'),
          type: 'nav-item',
          module: MODULES.REMOTE,
          isAdminOnly: true,
          icon: <CustomIcon iconName="settings" />,
        },
        {
          uri: ROUTE.REMOTE_WORK.TEMPLATE,
          label: i18n.t('REMOTE_WORK_TEMPLATE.menu_title'),
          type: 'nav-item',
          icon: <CustomIcon iconName="template-settings" />,
          isAdminOnly: true,
          module: MODULES.REMOTE,
        },
      ],
    },
    {
      label: i18n.t('SYSCOMMON.human_resource'),
      type: 'section',
      module: MODULES.RESOURCE,
      children: [
        {
          uri: ROUTE.EMPLOYEE.LIST,
          label: i18n.t('SYSCOMMON.employees'),
          type: 'nav-item',
          icon: <CustomIcon iconName="employee" />,
          isExact: false,
          matchGroups: [
            ROUTE.EMPLOYEE.LIST,
            ROUTE.EMPLOYEE.CREATE,
            ROUTE.EMPLOYEE.EDIT,
            ROUTE.INFO_LOG,
            ROUTE.QUIT_USERS,
          ],
        },
        {
          label: i18n.t('SYSCOMMON.settings'),
          icon: <CustomIcon iconName="settings" />,
          type: 'nav-item',
          uri: ROUTE.POSITIONS.LIST,
          isExact: false,
          matchGroups: [
            ROUTE.POSITIONS.LIST,
            ROUTE.POSITIONS.ORDER,
            ROUTE.DEPARTMENT.LIST,
            ROUTE.ROLE,
            ROUTE.SKILL.ROOT,
            ROUTE.SKILL.CATEGORY,
            ROUTE.SKILL.CATEGORY_ORDER,
          ],
        },
      ],
    },
    {
      label: i18n.t('EMAIL.title'),
      type: 'section',
      module: MODULES.EMAIL,
      children: [
        {
          uri: ROUTE.EMAIL.ROOT,
          label: i18n.t('EMAIL.send_email'),
          type: 'nav-item',
          icon: <CustomIcon iconName="send-email" />,
          isExact: false,
          matchGroups: [
            ROUTE.EMAIL.ROOT,
            ROUTE.EMAIL.TEMPLATES,
            ROUTE.EMAIL.CAMPAIGNS,
            ROUTE.EMAIL.CAMPAIGN_SETTINGS,
            ROUTE.EMAIL.TEMPLATE_SETTINGS,
          ],
        },
      ],
    },
    {
      label: i18n.t('SYSCOMMON.reports'),
      type: 'section',
      module: MODULES.RESOURCE,
      children: [
        {
          uri: ROUTE.SKILL.REPORT,
          label: i18n.t('SYSCOMMON.skill_report'),
          type: 'nav-item',
          icon: <CustomIcon iconName="skill-report" />,
        },
        {
          uri: ROUTE.REMOTE_REPORT.ROOT,
          label: i18n.t('TIME_REPORT.work_status_report'),
          type: 'nav-item',
          icon: <CustomIcon iconName="work-status-report" />,
        },
        {
          uri: ROUTE.VACATION_REPORT.ROOT,
          label: i18n.t('VACATION_REPORT.report_title'),
          type: 'nav-item',
          icon: <CustomIcon iconName="vacation-report" />,
        },
        {
          uri: ROUTE.TIMEOFF_TOTAL_REPORT.ROOT,
          label: i18n.t('TIMEOFF_TOTAL_REPORT.title'),
          type: 'nav-item',
          icon: <CustomIcon iconName="monthly-timeoff-report" />,
        },
      ],
    },
    {
      label: i18n.t('MODULES.resource_planning'),
      type: 'section',
      module: MODULES.RESOURCE_PLANNING,
      children: [
        {
          uri: ROUTE.RESOURCE_PLAN.ROOT,
          label: i18n.t('RESOURCE_PLAN.title'),
          type: 'nav-item',
          icon: <CustomIcon iconName="resource-plan" />,
          isExact: false,
          matchGroups: [ROUTE.RESOURCE_PLAN.ROOT, ROUTE.RESOURCE_PLAN.DETAILS],
        },
        {
          uri: ROUTE.PARTNER_PROJECT_ORDER.LIST,
          label: i18n.t('PROJECT_PARTNER_PURCHASE_ORDER.title'),
          type: 'nav-item',
          icon: <CustomIcon iconName="partner-purchase-order" />,
          isExact: false,
          matchGroups: [ROUTE.PARTNER_PROJECT_ORDER.LIST],
        },
        {
          uri: ROUTE.COST_REPORT.LIST,
          label: i18n.t('PROJECT.cost_report_title'),
          type: 'nav-item',
          icon: <CustomIcon iconName="cost-report" />,
          isExact: false,
          matchGroups: [ROUTE.COST_REPORT.LIST, ROUTE.COST_REPORT.DETAIL],
        },
        {
          uri: ROUTE.ACTUAL_COST_REPORT,
          label: i18n.t('ACTUAL_COST_REPORT.title'),
          type: 'nav-item',
          icon: <CustomIcon iconName="actual-cost-report" />,
          isExact: false,
          matchGroups: [
            ROUTE.ACTUAL_COST_REPORT,
            ROUTE.ACTUAL_COST_REPORT_DETAIL,
          ],
        },
      ],
    },
    {
      label: i18n.t('SYSCOMMON.other'),
      type: 'section',
      module: MODULES.SETTINGS,
      children: [
        {
          label: i18n.t('SYSCOMMON.configuration'),
          type: 'nav-item',
          module: MODULES.SETTINGS,
          uri: ROUTE.COMPANY.ROOT,
          icon: <CustomIcon iconName="configuration" />,
          isExact: false,
          matchGroups: [
            ROUTE.COMPANY.ROOT,
            ROUTE.MEETING_ROOM.ROOT,
            ROUTE.WORKSPACE.ROOT,
            ROUTE.WORKSPACE.LOCATION,
            ROUTE.SYSTEM_SETTINGS.ROOT,
            ROUTE.COMPANY.DIVISION,
            ROUTE.COMPANY.DIVISION_ORDER,
          ],
        },
      ],
    },
  ]

  return menuArray
}

export default generateMenus
