export const ROUTE = {
  ROOT: '/',
  PERMISSION_DENIED: '/403',
  NOT_FOUND: '/404',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  VERIFY_CODE: '/verify-code',
  RESET_PASSWORD: '/reset-password',
  DASHBOARDS: '/',
  MEMBERS: '/members',
  BOOK_MEETING_ROOM: '/book_meeting_room',
  COMPANY_RULES: '/rules',
  PROJECT: {
    ROOT: '/project',
    OVERVIEW: '/project/:id',
    SETTINGS: '/project/:id/edit',
    MEMBER: '/project/:id/access',
    AVTIVITY: '/project/:id/activity',
  },
  EVENTS: {
    ROOT: '/events',
  },
  COMPANY: {
    ROOT: '/company',
    OVERVIEW: '/company/:id/overview',
    DIVISION: '/company/divisions',
    DIVISION_ORDER: '/company/divisions/order',
  },
  TIME: {
    ROOT: '/time',
    TIMESHEET: '/time/timesheet',
    REQUESTS: '/time/requests',
    TEAMWORK: '/time/teamwork',
    REPORT: '/time/report',
  },

  COST_REPORT: {
    LIST: '/resource_planning/cost/list',
    DETAIL: '/resource_planning/cost/report/:id',
  },
  ACTUAL_COST_REPORT: '/resource_planning/cost/actual_report',
  ACTUAL_COST_REPORT_DETAIL: '/resource_planning/cost/actual_report/:id/detail',
  SKILL: {
    ROOT: '/resource/skill',
    CATEGORY: '/resource/skill/category',
    REPORT: '/resource/skill/report',
    CATEGORY_ORDER: '/resource/skill/category/order',
  },
  TAG: {
    ROOT: '/resource/tag',
  },
  PROFILE: {
    ROOT: '/profile',
    SKILLS: '/profile/skills',
  },
  EMPLOYEE: {
    ROOT: '/employee',
    DETAILS: '/employee/:id',
    CREATE: '/resource/create',
    EDIT: '/resource/:id/edit',
    LIST: '/resource/list',
  },
  QUIT_USERS: '/resource/quit_users',
  INFO_LOG: '/resource/info_log',
  RESOURCE_PLAN: {
    ROOT: '/resource_planning/resource_plan',
    DETAILS: '/resource_planning/resource_plan/:id',
  },
  DEPARTMENT: {
    LIST: '/resource/department/',
    OVERVIEW: '/resource/department/:id/overview',
  },
  POSITIONS: {
    LIST: '/resource/position/',
    ORDER: '/resource/position/order',
  },
  ROLE: '/resource/role',
  MEETING_ROOM: {
    ROOT: '/settings/meeting_rooms',
    ORDER: '/settings/meeting_rooms/order',
  },
  EMAIL: {
    ROOT: '/email',
    CAMPAIGNS: '/email/campaigns',
    CAMPAIGN_SETTINGS: '/email/campaigns/:id/settings',
    CAMPAIGN_CONTENT: '/f/email/campaigns/:id/content',
    CAMPAIGN_IMPORT_DATA: '/f/email/campaigns/:id/import_data',
    TEMPLATES: '/email/templates',
    TEMPLATE_SETTINGS: '/email/templates/:id/settings',
    TEMPLATE_CONTENT: '/f/email/templates/:id/content',
  },
  SYSTEM_SETTINGS: {
    ROOT: '/settings/system_settings',
  },
  TIMEOFF_TEMPLATE: {
    LIST: '/timeoff/templates',
  },
  TIMEOFF_REQUEST: {
    LIST: '/timeoff/requests',
    OVERVIEW: '/timeoff/requests/:id',
  },
  TIMEOFF_APPROVE: {
    LIST: '/timeoff/approve_list',
  },
  TIMEOFF_REPORT: {
    ROOT: '/timeoff/report',
  },
  APPROVAL_TEMPLATE: {
    LIST: '/timeoff/approval_templates',
    EDIT: '/timeoff/approval_templates/:id',
    TEMPLATE_STEP: '/timeoff/approval_templates/:id/step',
  },
  NOTIFICATION: {
    LIST: '/notifications',
  },
  VACATION_BALANCES: {
    ROOT: '/timesettings/vacation_balances',
  },
  VACATION_REPORT: {
    ROOT: '/resource/vacationreport',
  },
  REMOTE_REPORT: {
    ROOT: '/resource/remoteworkreport',
  },
  TIMEOFF_TOTAL_REPORT: {
    ROOT: '/resource/timeofftotalreport',
  },
  COMPENSATORY_DAYS: {
    ROOT: '/timesettings/compensatory_days',
  },
  TIMESHEET: {
    ROOT: '/timesheet',
  },
  DAILY_REPORT: {
    ROOT: '/dailyreport',
    TEAM: '/dailyreport/team',
    HR: '/dailyreport/hr',
  },
  TIME_CORRECTION: {
    APPROVE_LIST: '/timecorrection/approve',
    REQUEST_LIST: '/timecorrection/requests',
    TEMPLATE: '/timecorrection/template',
  },
  WORKSPACE: {
    ROOT: '/settings/workspace',
    LOCATION: '/settings/workspace/:id/location',
  },
  REMOTE_WORK: {
    TEMPLATE: '/remotework/template',
    REQUEST: '/remotework/requests',
    DISABLED_LOG: '/remotework/disabled_log',
    APPROVE: '/remotework/approve',
    SETTINGS: '/remotework/settings',
  },
  PARTNER_PROJECT_ORDER: {
    LIST: 'resource_planning/partner_purchase_order',
  },
}
