import StringAvatar from '@components/@material-extend/StringAvatar'
import { generateIcons } from '@constants/notifyicons'
import {
  Badge,
  Box,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material'
import { NotifyItem, ReadParams } from '@services/notification.services'
import { formatHumanDate, getShortName } from '@utils/helper/common.helper'
import _ from 'lodash'
import React from 'react'
import { Link, matchPath, useLocation } from 'react-router-dom'
import MessageContent from './MessageContent'
import { renderLink } from './Link'

interface Props {
  item: NotifyItem
  markAsRead?: (params: ReadParams) => void
}

const NotificationItem: React.FC<Props> = ({ item, markAsRead }) => {
  const actionBy = _.get(
    item,
    'attributes.notification.data.attributes.action_by.data'
  )
  const avatar = _.get(
    actionBy,
    'attributes.avatar_thumbnail',
    '/images/default-150x150.png'
  )
  const content = _.get(item, 'attributes.notification.data')
  const isRead = _.get(item, 'attributes.is_read', false)
  const uri = renderLink(content)
  const location = useLocation()

  return (
    <ListItemButton
      component={Link}
      to={uri ? uri : ''}
      reloadDocument={!_.isNull(matchPath(uri ? uri : '', location.pathname))}
      alignItems={'flex-start'}
      onClick={() => markAsRead && markAsRead({ id: item.id })}
      disableRipple
      sx={{
        backgroundColor: !isRead ? '#eee' : '#fff',
        borderBottom: '1px solid #eee',
        pr: '40px',
      }}
    >
      <ListItemAvatar>
        <Badge
          badgeContent={generateIcons(
            _.get(item, 'attributes.notification.data.attributes.target_type')
          )}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          overlap="circular"
          sx={{
            '& .MuiBadge-badge': {
              background: '#fff',
              p: 0,
              boxShadow: 1,
            },
          }}
        >
          <StringAvatar src={avatar} alt={actionBy?.attributes?.firstname_en} />
        </Badge>
      </ListItemAvatar>
      <ListItemText sx={{ color: '#000' }}>
        {getShortName(actionBy)}
        <MessageContent content={content} />
        <Typography
          component="span"
          sx={{
            display: 'flex',
            fontSize: '90%',
            mt: 0.5,
          }}
        >
          <Box
            sx={{
              color: '#616161',
              ml: 1,
            }}
          >
            {formatHumanDate(_.get(item, 'attributes.created_at', ''))}
          </Box>
        </Typography>
      </ListItemText>
      <ListItemSecondaryAction>
        <Box>
          {!isRead && (
            <Box
              onClick={(e) => {
                e.preventDefault()
                markAsRead && markAsRead({ id: item.id })
              }}
              sx={{
                width: 8,
                height: 8,
                borderRadius: 12,
                background: (theme) => theme.palette.primary.main,
              }}
            />
          )}
        </Box>
      </ListItemSecondaryAction>
    </ListItemButton>
  )
}

export default NotificationItem
