import { ROUTE } from '@constants/route.constants'
import i18n from '@locales/i18n'
import {
  ConfirmPasswordParams,
  ForgotPasswordParams,
} from '@services/auth.services'
import {
  changeCurrentPassword,
  logOut,
  resetForgotPassword,
  sendConfirmationEmail,
  switchLang,
  verifyConfirmationCode,
} from '@store/auth/actions'
import { addToast, toastParams } from '@store/common/actions'
import {
  createCompany,
  createPartnerDivision,
  deleteCompany,
  deletePartnerDivision,
  fetchCompanyDetails,
  updateCompany,
  updatePartnerDivision,
  updatePartnerDivisionOrder,
} from '@store/company/actions'
import {
  createDailyReport,
  createTag,
  deleteTags,
  updateDailyReport,
  updateTags,
} from '@store/dailyreport/actions'
import {
  emailCampaignBulkSend,
  emailCampaignCreate,
  emailCampaignDelete,
  emailCampaignUpdate,
  emailTemplateCreate,
  emailTemplateDelete,
  emailTemplateDetail,
  emailTemplateUpdate,
} from '@store/email/actions'
import {
  addUser,
  createPartnerPurchaseOrder,
  deletePartnerPurchaseOrder,
  deleteProject,
  deleteProjectUser,
  memberList,
  projectActivity,
  setProjectImage,
  showProject,
  updatePartnerPurchaseOrder,
  updateProject,
  updateUser,
} from '@store/project/actions'
import {
  addResourceUsers,
  batchResourcePlan,
  confirmResourcePlan,
  createDepartment,
  createResourcePlan,
  deleteDepartment,
  deletePosition,
  deleteResourcePlan,
  deleteRole,
  refuseResourcePlan,
  removeResourceUsers,
  renameResourcePlan,
  showDepartment,
  showResourcePlan,
  updateDepartment,
  updatePosition,
  updatePositionOrder,
  updateRole,
} from '@store/resource/actions'
import {
  deleteWorkspace,
  eventDelete,
  eventUpdate,
  meetingRoomCreate,
  meetingRoomDelete,
  meetingRoomOrderCreate,
  meetingRoomOrderDelete,
  meetingRoomOrderUpdate,
  meetingRoomUpdate,
  regenerateWorkspaceUid,
  updateCompanyRules,
  updateMeetingRoomOrder,
  updateSystemSettings,
  updateWorkspace,
  verifyWorkspace,
} from '@store/settings/actions'
import {
  deleteSkill,
  deleteSkillCategory,
  deleteUserSkillById,
  updateSingleSkill,
  updateSkill,
  updateSkillCategory,
  updateSkillCategoryOrder,
  updateUserSkills,
} from '@store/skill/actions'
import {
  approveRemoteWorkRequest,
  approveTimeCorrection,
  breakEnd,
  breakStart,
  createRemoteWorkRequest,
  createTimeCorrection,
  deleteRemoteWorkRequest,
  deleteApproverRemoteWorkRequest,
  deleteRemoteWorkSettings,
  denyTimeCorrection,
  forceUpdateRemoteWorkSettings,
  timeEnd,
  timeQrEnd,
  timeQrStart,
  timeStart,
  updateRemoteWorkSettings,
  updateTimeCorrection,
  updateTimeCorrectionTemplate,
  updateRemoteWorkRequest,
  createRemoteWorkSettings,
  updateRemoteWorkTemplateDetail,
  fetchRemoteOfficeReport,
  denyRemoteWorkRequest,
} from '@store/time/actions'
import {
  approveTimeOffRequest,
  createApprovalTemplateDetail,
  createTimeOffRequest,
  deleteApprovalTemplate,
  deleteApprovalTemplateDetail,
  deleteTimeOffRequest,
  deleteTimeOffTemplate,
  denyTimeOffRequest,
  updateApprovalTemplate,
  updateApprovalTemplateDetail,
  updateApprovalTemplateStep,
  updateTimeOffTemplate,
  updateUserCompensatoryDays,
} from '@store/timeoff/actions'
import {
  deleteEmployee,
  fetchProfile,
  quitEmployee,
  resetPassword,
  showEmployee,
  updateEmployee,
  updateQuitReason,
} from '@store/user/actions'
import {
  calculateUserVacationBalance,
  calculateVacationBalance,
  createVacationBalance,
} from '@store/vacation/actions'
import customHistory from 'customHistory'
import { AnyAction } from 'redux'

type MessageType = {
  [x: string]: toastParams
}

export const generateMessages = (): MessageType => {
  const messages = {
    [`${sendConfirmationEmail.fulfilled}`]: {
      message: i18n.t('TOASTS.forgot_password_fulfilled'),
      severity: 'success',
    },
    [`${sendConfirmationEmail.rejected}`]: {
      message: i18n.t('TOASTS.forgot_password_rejected'),
      severity: 'error',
    },
    [`${resetForgotPassword.fulfilled}`]: {
      message: i18n.t('TOASTS.password_reset_fulfilled'),
      severity: 'success',
    },
    [`${changeCurrentPassword.fulfilled}`]: {
      message: i18n.t('TOASTS.change_password_fulfilled'),
      severity: 'success',
    },
    [`${changeCurrentPassword.rejected}`]: {
      message: i18n.t('TOASTS.change_password_rejected'),
      severity: 'error',
    },
    [`${switchLang.rejected}`]: {
      message: i18n.t('TOASTS.switch_lang_rejected'),
      severity: 'warning',
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
    },
    // Company
    [`${createCompany.rejected}`]: {
      message: i18n.t('TOASTS.create_company_rejected'),
      severity: 'error',
    },
    [`${updateCompany.fulfilled}`]: {
      message: i18n.t('TOASTS.update_company_fulfilled'),
      severity: 'success',
    },
    [`${updateCompany.rejected}`]: {
      message: i18n.t('TOASTS.update_company_rejected'),
      severity: 'error',
    },
    [`${deleteCompany.fulfilled}`]: {
      message: i18n.t('TOASTS.delete_company_fulfilled'),
      severity: 'success',
    },
    // Division
    [`${updatePartnerDivision.fulfilled}`]: {
      message: i18n.t('TOASTS.update_division_fulfilled'),
      severity: 'success',
    },
    [`${updatePartnerDivision.rejected}`]: {
      message: i18n.t('TOASTS.update_division_rejected'),
      severity: 'error',
    },
    [`${createPartnerDivision.fulfilled}`]: {
      message: i18n.t('TOASTS.create_division_fulfilled'),
      severity: 'success',
    },
    [`${createPartnerDivision.rejected}`]: {
      message: i18n.t('TOASTS.create_division_rejected'),
      severity: 'error',
    },
    [`${deletePartnerDivision.fulfilled}`]: {
      message: i18n.t('TOASTS.delete_division_fulfilled'),
      severity: 'success',
    },
    [`${updatePartnerDivisionOrder.fulfilled}`]: {
      message: i18n.t('TOASTS.update_division_order_fulfilled'),
      severity: 'success',
    },
    [`${updatePartnerDivisionOrder.rejected}`]: {
      message: i18n.t('TOASTS.update_division_order_rejected'),
      severity: 'error',
    },
    // Position
    [`${updatePosition.fulfilled}`]: {
      message: i18n.t('TOASTS.update_position_fulfilled'),
      severity: 'success',
    },
    [`${updatePosition.rejected}`]: {
      message: i18n.t('TOASTS.update_position_rejected'),
      severity: 'error',
    },
    [`${deletePosition.fulfilled}`]: {
      message: i18n.t('TOASTS.delete_position_fulfilled'),
      severity: 'success',
    },
    [`${updatePositionOrder.fulfilled}`]: {
      message: i18n.t('TOASTS.update_position_order_fulfilled'),
      severity: 'success',
    },
    [`${updatePositionOrder.rejected}`]: {
      message: i18n.t('TOASTS.update_position_order_rejected'),
      severity: 'error',
    },
    // [`${deletePosition.rejected}`]: {
    //   message: i18n.t('TOASTS.delete_position_rejected'),
    //   severity: 'error',
    // },
    // Department
    [`${createDepartment.fulfilled}`]: {
      message: i18n.t('TOASTS.create_department_fulfilled'),
      severity: 'success',
    },
    [`${createDepartment.rejected}`]: {
      message: i18n.t('TOASTS.create_department_rejected'),
      severity: 'error',
    },
    [`${updateDepartment.fulfilled}`]: {
      message: i18n.t('TOASTS.update_department_fulfilled'),
      severity: 'success',
    },
    [`${updateDepartment.rejected}`]: {
      message: i18n.t('TOASTS.update_department_rejected'),
      severity: 'error',
    },
    [`${deleteDepartment.fulfilled}`]: {
      message: i18n.t('TOASTS.delete_department_fulfilled'),
      severity: 'success',
    },
    // Project
    [`${deleteProject.fulfilled}`]: {
      message: i18n.t('TOASTS.delete_project_fulfilled'),
      severity: 'success',
    },
    [`${updateUser.fulfilled}`]: {
      message: i18n.t('TOASTS.update_user_fulfilled'),
      severity: 'success',
    },
    [`${deleteProjectUser.fulfilled}`]: {
      message: i18n.t('TOASTS.delete_project_user_fulfilled'),
      severity: 'success',
    },
    [`${updateProject.rejected}`]: {
      message: i18n.t('TOASTS.update_project_rejected'),
      severity: 'error',
    },
    [`${updateProject.fulfilled}`]: {
      message: i18n.t('TOASTS.update_project_fulfilled'),
      severity: 'success',
    },
    [`${setProjectImage.rejected}`]: {
      message: i18n.t('TOASTS.update_project_image_rejected'),
      severity: 'error',
    },
    [`${setProjectImage.fulfilled}`]: {
      message: i18n.t('TOASTS.update_project_image_fulfilled'),
      severity: 'success',
    },
    //Partner purchase order
    [`${createPartnerPurchaseOrder.fulfilled}`]: {
      message: i18n.t('TOASTS.create_partner_purchase_order_fulfilled'),
      severity: 'success',
    },
    [`${updatePartnerPurchaseOrder.fulfilled}`]: {
      message: i18n.t('TOASTS.update_partner_purchase_order_fulfilled'),
      severity: 'success',
    },
    [`${deletePartnerPurchaseOrder.fulfilled}`]: {
      message: i18n.t('TOASTS.delete_partner_purchase_order_fulfilled'),
      severity: 'success',
    },
    [`${deletePartnerPurchaseOrder.rejected}`]: {
      message: i18n.t('TOASTS.delete_partner_purchase_order_rejected'),
      severity: 'error',
    },
    // Role
    [`${deleteRole.fulfilled}`]: {
      message: i18n.t('TOASTS.delete_role_fulfilled'),
      severity: 'success',
    },
    [`${updateRole.rejected}`]: {
      message: i18n.t('TOASTS.update_role_rejected'),
      severity: 'error',
    },
    [`${updateRole.fulfilled}`]: {
      message: i18n.t('TOASTS.update_role_fulfilled'),
      severity: 'success',
    },
    // Event
    [`${eventUpdate.fulfilled}`]: {
      message: i18n.t('TOASTS.event_update_fulfilled'),
      severity: 'success',
    },
    [`${eventUpdate.rejected}`]: {
      message: i18n.t('TOASTS.event_update_rejected'),
      severity: 'error',
    },
    [`${eventDelete.rejected}`]: {
      message: i18n.t('TOASTS.event_delete_rejected'),
      severity: 'error',
    },
    [`${eventDelete.fulfilled}`]: {
      message: i18n.t('TOASTS.event_delete_fulfilled'),
      severity: 'success',
    },
    //Skill
    [`${updateSkill.rejected}`]: {
      message: i18n.t('TOASTS.update_skill_rejected'),
      severity: 'error',
    },
    [`${updateSkill.fulfilled}`]: {
      message: i18n.t('TOASTS.update_skill_fulfilled'),
      severity: 'success',
    },
    [`${deleteSkill.rejected}`]: {
      message: i18n.t('TOASTS.delete_skill_rejected'),
      severity: 'error',
    },
    [`${deleteSkill.fulfilled}`]: {
      message: i18n.t('TOASTS.delete_skill_fulfilled'),
      severity: 'success',
    },
    //Skill category
    [`${updateSkillCategory.rejected}`]: {
      message: i18n.t('TOASTS.update_skill_category_rejected'),
      severity: 'error',
    },
    [`${updateSkillCategory.fulfilled}`]: {
      message: i18n.t('TOASTS.update_skill_category_fulfilled'),
      severity: 'success',
    },
    [`${updateSkillCategoryOrder.rejected}`]: {
      message: i18n.t('TOASTS.update_skill_category_order_rejected'),
      severity: 'error',
    },
    [`${updateSkillCategoryOrder.fulfilled}`]: {
      message: i18n.t('TOASTS.update_skill_category_order_fulfilled'),
      severity: 'success',
    },
    [`${deleteSkillCategory.rejected}`]: {
      message: i18n.t('TOASTS.delete_skill_category_rejected'),
      severity: 'error',
    },
    [`${deleteSkillCategory.fulfilled}`]: {
      message: i18n.t('TOASTS.delete_skill_category_fulfilled'),
      severity: 'success',
    },
    //User skill
    [`${deleteUserSkillById.rejected}`]: {
      message: i18n.t('TOASTS.delete_user_skill_by_id_rejected'),
      severity: 'error',
    },
    [`${deleteUserSkillById.fulfilled}`]: {
      message: i18n.t('TOASTS.delete_user_skill_by_id_fulfilled'),
      severity: 'success',
    },
    [`${updateUserSkills.rejected}`]: {
      message: i18n.t('TOASTS.update_user_skills_rejected'),
      severity: 'error',
    },
    [`${updateUserSkills.fulfilled}`]: {
      message: i18n.t('TOASTS.update_user_skills_fulfilled'),
      severity: 'success',
    },
    [`${updateSingleSkill.rejected}`]: {
      message: i18n.t('TOASTS.update_single_skill_rejected'),
      severity: 'error',
    },
    [`${updateSingleSkill.fulfilled}`]: {
      message: i18n.t('TOASTS.update_single_skill_fulfilled'),
      severity: 'success',
    },
    // Resource Plan
    [`${createResourcePlan.rejected}`]: {
      message: i18n.t('TOASTS.create_resource_plan_rejected'),
      severity: 'error',
    },
    [`${renameResourcePlan.fulfilled}`]: {
      message: i18n.t('TOASTS.rename_resource_plan_fulfilled'),
      severity: 'success',
    },
    [`${renameResourcePlan.rejected}`]: {
      message: i18n.t('TOASTS.rename_resource_plan_rejected'),
      severity: 'error',
    },
    [`${confirmResourcePlan.fulfilled}`]: {
      message: i18n.t('TOASTS.confirm_resource_plan_fulfilled'),
      severity: 'success',
    },
    [`${refuseResourcePlan.fulfilled}`]: {
      message: i18n.t('TOASTS.refuse_resource_plan_fulfilled'),
      severity: 'success',
    },
    [`${refuseResourcePlan.rejected}`]: {
      message: i18n.t('TOASTS.refuse_resource_plan_rejected'),
      severity: 'error',
    },
    [`${deleteResourcePlan.fulfilled}`]: {
      message: i18n.t('TOASTS.delete_resource_plan_fulfilled'),
      severity: 'success',
    },
    [`${batchResourcePlan.fulfilled}`]: {
      message: i18n.t('TOASTS.calculate_resouse_plan_fulfilled'),
      severity: 'success',
    },
    // Employee
    [`${updateEmployee.rejected}`]: {
      message: i18n.t('TOASTS.update_employee_rejected'),
      severity: 'error',
    },
    [`${updateEmployee.fulfilled}`]: {
      message: i18n.t('TOASTS.update_employee_fulfilled'),
      severity: 'success',
    },
    [`${deleteEmployee.rejected}`]: {
      message: i18n.t('TOASTS.delete_employee_rejected'),
      severity: 'error',
    },
    [`${deleteEmployee.fulfilled}`]: {
      message: i18n.t('TOASTS.delete_employee_fulfilled'),
      severity: 'success',
    },
    [`${quitEmployee.rejected}`]: {
      message: i18n.t('TOASTS.quit_employee_rejected'),
      severity: 'error',
    },
    [`${quitEmployee.fulfilled}`]: {
      message: i18n.t('TOASTS.quit_employee_fulfilled'),
      severity: 'success',
    },
    [`${addUser.rejected}`]: {
      message: i18n.t('TOASTS.create_user_rejected'),
      severity: 'error',
    },
    //Meeting room
    [`${meetingRoomCreate.fulfilled}`]: {
      message: i18n.t('TOASTS.create_meeting_room_fulfilled'),
      severity: 'success',
    },
    [`${meetingRoomCreate.rejected}`]: {
      message: i18n.t('TOASTS.create_meeting_room_rejected'),
      severity: 'error',
    },
    [`${meetingRoomUpdate.fulfilled}`]: {
      message: i18n.t('TOASTS.update_meeting_room_fulfilled'),
      severity: 'success',
    },
    [`${meetingRoomUpdate.rejected}`]: {
      message: i18n.t('TOASTS.update_meeting_room_rejected'),
      severity: 'error',
    },
    [`${meetingRoomDelete.fulfilled}`]: {
      message: i18n.t('TOASTS.delete_meeting_room_fulfilled'),
      severity: 'success',
    },
    [`${updateMeetingRoomOrder.fulfilled}`]: {
      message: i18n.t('TOASTS.meeting_room_update_order_fulfilled'),
      severity: 'success',
    },
    [`${updateMeetingRoomOrder.rejected}`]: {
      message: i18n.t('TOASTS.meeting_room_update_order_rejected'),
      severity: 'error',
    },
    //Meeting room order
    [`${meetingRoomOrderCreate.rejected}`]: {
      message: i18n.t('TOASTS.create_meeting_room_order_rejected'),
      severity: 'error',
    },
    [`${meetingRoomOrderUpdate.fulfilled}`]: {
      message: i18n.t('TOASTS.update_meeting_room_order_fulfilled'),
      severity: 'success',
    },
    [`${meetingRoomOrderUpdate.rejected}`]: {
      message: i18n.t('TOASTS.update_meeting_room_order_rejected'),
      severity: 'error',
    },
    [`${meetingRoomOrderDelete.fulfilled}`]: {
      message: i18n.t('TOASTS.delete_meeting_room_order_fulfilled'),
      severity: 'success',
    },

    //System settings
    [`${updateSystemSettings.fulfilled}`]: {
      message: i18n.t('TOASTS.update_system_settings_fulfilled'),
      severity: 'success',
    },
    [`${updateSystemSettings.rejected}`]: {
      message: i18n.t('TOASTS.update_system_settings_rejected'),
      severity: 'error',
    },
    //Email campaign
    [`${emailCampaignCreate.fulfilled}`]: {
      message: i18n.t('TOASTS.create_email_campaign_fulfilled'),
      severity: 'success',
    },
    [`${emailCampaignCreate.rejected}`]: {
      message: i18n.t('TOASTS.create_email_campaign_rejected'),
      severity: 'error',
    },
    [`${emailCampaignUpdate.fulfilled}`]: {
      message: i18n.t('TOASTS.update_email_campaign_fulfilled'),
      severity: 'success',
    },
    [`${emailCampaignUpdate.rejected}`]: {
      message: i18n.t('TOASTS.update_email_campaign_rejected'),
      severity: 'error',
    },
    [`${emailCampaignDelete.fulfilled}`]: {
      message: i18n.t('TOASTS.delete_email_campaign_fulfilled'),
      severity: 'success',
    },
    [`${emailCampaignDelete.rejected}`]: {
      message: i18n.t('TOASTS.delete_email_campaign_rejected'),
      severity: 'error',
    },
    //Email template
    [`${emailTemplateCreate.fulfilled}`]: {
      message: i18n.t('TOASTS.create_email_template_fulfilled'),
      severity: 'success',
    },
    [`${emailTemplateCreate.rejected}`]: {
      message: i18n.t('TOASTS.create_email_template_rejected'),
      severity: 'error',
    },
    // [`${emailTemplateUpdate.fulfilled}`]: {
    //   message: i18n.t('TOASTS.update_email_template_fulfilled'),
    //   severity: 'success',
    // },
    [`${emailTemplateUpdate.rejected}`]: {
      message: i18n.t('TOASTS.update_email_template_rejected'),
      severity: 'error',
    },
    [`${emailTemplateDelete.fulfilled}`]: {
      message: i18n.t('TOASTS.delete_email_template_fulfilled'),
      severity: 'success',
    },
    [`${emailTemplateDelete.rejected}`]: {
      message: i18n.t('TOASTS.delete_email_template_rejected'),
      severity: 'error',
    },
    [`${emailCampaignBulkSend.rejected}`]: {
      message: i18n.t('TOASTS.send_bulk_failed'),
      severity: 'error',
    },
    [`${emailCampaignBulkSend.fulfilled}`]: {
      message: i18n.t('TOASTS.send_bulk_success'),
      severity: 'success',
    },
    // Time Off Template
    [`${updateTimeOffTemplate.rejected}`]: {
      message: i18n.t('TOASTS.update_timeoff_template_rejected'),
      severity: 'error',
    },
    [`${createTimeOffRequest.rejected}`]: {
      message: i18n.t('TOASTS.time_off_request_failed'),
      severity: 'error',
    },
    [`${updateTimeOffTemplate.fulfilled}`]: {
      message: i18n.t('TOASTS.update_timeoff_template_fulfilled'),
      severity: 'success',
    },
    [`${deleteTimeOffTemplate.rejected}`]: {
      message: i18n.t('TOASTS.delete_timeoff_template_rejected'),
      severity: 'error',
    },
    [`${deleteTimeOffTemplate.fulfilled}`]: {
      message: i18n.t('TOASTS.delete_timeoff_template_fulfilled'),
      severity: 'success',
    },
    [`${updateApprovalTemplate.fulfilled}`]: {
      message: i18n.t('TOASTS.update_approval_template_fulfilled'),
      severity: 'success',
    },
    [`${deleteApprovalTemplate.fulfilled}`]: {
      message: i18n.t('TOASTS.delete_approval_template_fulfilled'),
      severity: 'success',
    },
    [`${updateApprovalTemplateDetail.fulfilled}`]: {
      message: i18n.t('TOASTS.update_approval_template_details_fulfilled'),
      severity: 'success',
    },
    [`${deleteApprovalTemplateDetail.fulfilled}`]: {
      message: i18n.t('TOASTS.delete_approval_template_details_fulfilled'),
      severity: 'success',
    },
    [`${updateApprovalTemplateStep.fulfilled}`]: {
      message: i18n.t('TOASTS.update_approval_template_step_fulfilled'),
      severity: 'success',
    },
    [`${calculateVacationBalance.fulfilled}`]: {
      message: i18n.t('TOASTS.calculate_vacation_balance_fulfilled'),
      severity: 'success',
    },
    [`${calculateVacationBalance.rejected}`]: {
      message: i18n.t('TOASTS.calculate_vacation_balance_rejected'),
      severity: 'error',
    },
    [`${calculateUserVacationBalance.fulfilled}`]: {
      message: i18n.t('TOASTS.calculate_vacation_balance_fulfilled'),
      severity: 'success',
    },
    [`${calculateUserVacationBalance.rejected}`]: {
      message: i18n.t('TOASTS.calculate_vacation_balance_rejected'),
      severity: 'error',
    },

    [`${deleteTimeOffRequest.fulfilled}`]: {
      message: i18n.t('TOASTS.delete_timeoff_request_fulfilled'),
      severity: 'success',
    },
    [`${deleteTimeOffRequest.rejected}`]: {
      message: i18n.t('TOASTS.delete_timeoff_request_rejected'),
      severity: 'error',
    },

    // [`${updateDailyReport.rejected}`]: {
    //   message: i18n.t('TOASTS.daily_report_update_rejected'),
    //   severity: 'error',
    // },
    // [`${createDailyReport.rejected}`]: {
    //   message: i18n.t('TOASTS.daily_report_create_rejected'),
    //   severity: 'error',
    // },
    [`${updateTimeCorrection.rejected}`]: {
      message: i18n.t('TOASTS.time_correction_update_rejected'),
      severity: 'error',
    },
    [`${denyTimeCorrection.rejected}`]: {
      message: i18n.t('TOASTS.time_correction_update_rejected'),
      severity: 'error',
    },
    [`${updateTimeCorrectionTemplate.fulfilled}`]: {
      message: i18n.t('TOASTS.time_correction_template_fulfulled'),
      severity: 'success',
    },
    [`${updateCompanyRules.fulfilled}`]: {
      message: i18n.t('TOASTS.company_rules_update_fulfulled'),
      severity: 'success',
    },
    [`${verifyWorkspace.fulfilled}`]: {
      message: i18n.t('TOASTS.workspace_verify_fulfulled'),
      severity: 'success',
    },
    [`${regenerateWorkspaceUid.fulfilled}`]: {
      message: i18n.t('TOASTS.regenerate_workspace_token_fulfilled'),
      severity: 'success',
    },
    [`${regenerateWorkspaceUid.rejected}`]: {
      message: i18n.t('TOASTS.regenerate_workspace_token_rejected'),
      severity: 'error',
    },
    [`${updateWorkspace.fulfilled}`]: {
      message: i18n.t('TOASTS.update_workspace_fulfilled'),
      severity: 'success',
    },
    [`${updateWorkspace.rejected}`]: {
      message: i18n.t('TOASTS.update_workspace_rejected'),
      severity: 'error',
    },
    [`${deleteWorkspace.fulfilled}`]: {
      message: i18n.t('TOASTS.delete_workspace_fulfilled'),
      severity: 'success',
    },
    [`${updateRemoteWorkSettings.fulfilled}`]: {
      message: i18n.t('TOASTS.update_remote_work_settings_fulfilled'),
      severity: 'success',
    },
    [`${updateRemoteWorkSettings.rejected}`]: {
      message: i18n.t('TOASTS.update_remote_work_settings_rejected'),
      severity: 'error',
    },
    [`${forceUpdateRemoteWorkSettings.fulfilled}`]: {
      message: i18n.t('TOASTS.update_remote_work_settings_fulfilled'),
      severity: 'success',
    },
    [`${forceUpdateRemoteWorkSettings.rejected}`]: {
      message: i18n.t('TOASTS.update_remote_work_settings_rejected'),
      severity: 'error',
    },
    [`${deleteRemoteWorkSettings.fulfilled}`]: {
      message: i18n.t('TOASTS.delete_remote_work_settings_fulfilled'),
      severity: 'success',
    },
    [`${deleteRemoteWorkSettings.rejected}`]: {
      message: i18n.t('TOASTS.delete_remote_work_settings_rejected'),
      severity: 'error',
    },
    [`${timeQrStart.fulfilled}`]: {
      message: i18n.t('TOASTS.timeqr_start_fulfilled'),
      severity: 'success',
    },
    [`${timeQrEnd.fulfilled}`]: {
      message: i18n.t('TOASTS.timeqr_end_fulfilled'),
      severity: 'success',
    },
    [`${deleteRemoteWorkRequest.fulfilled}`]: {
      message: i18n.t('TOASTS.delete_remote_work_request_fulfilled'),
      severity: 'success',
    },
    [`${deleteRemoteWorkRequest.rejected}`]: {
      message: i18n.t('TOASTS.delete_remote_work_request_rejected'),
      severity: 'error',
    },
    [`${deleteApproverRemoteWorkRequest.fulfilled}`]: {
      message: i18n.t('TOASTS.delete_remote_work_request_fulfilled'),
      severity: 'success',
    },
    [`${deleteApproverRemoteWorkRequest.rejected}`]: {
      message: i18n.t('TOASTS.delete_remote_work_request_rejected'),
      severity: 'error',
    },
    [`${updateRemoteWorkRequest.fulfilled}`]: {
      message: i18n.t('TOASTS.update_remote_work_request_fulfilled'),
      severity: 'success',
    },
    [`${createRemoteWorkSettings.rejected}`]: {
      message: i18n.t('TOASTS.create_remote_work_settings_rejected'),
      severity: 'error',
    },
    [`${updateUserCompensatoryDays.fulfilled}`]: {
      message: i18n.t('TOASTS.update_user_compensatory_days_fulfilled'),
      severity: 'success',
    },
    [`${updateUserCompensatoryDays.rejected}`]: {
      message: i18n.t('TOASTS.update_user_compensatory_days_rejected'),
      severity: 'error',
    },
    [`${removeResourceUsers.rejected}`]: {
      message: i18n.t('SYSCOMMON.something_went_wrong'),
      severity: 'error',
    },
    [`${addResourceUsers.rejected}`]: {
      message: i18n.t('SYSCOMMON.something_went_wrong'),
      severity: 'error',
    },
    [`${updateQuitReason.fulfilled}`]: {
      message: i18n.t('TOASTS.update_quit_reason_fulfilled'),
      severity: 'success',
    },
    [`${updateQuitReason.rejected}`]: {
      message: i18n.t('TOASTS.update_quit_reason_rejected'),
      severity: 'error',
    },
    [`${resetPassword.fulfilled}`]: {
      message: i18n.t('TOASTS.reset_password_employee_fulfilled'),
      severity: 'success',
    },
    [`${resetPassword.rejected}`]: {
      message: i18n.t('TOASTS.reset_password_employee_rejected'),
      severity: 'error',
    },
    //Tag
    [`${createTag.rejected}`]: {
      message: i18n.t('TOASTS.create_tag_rejected'),
      severity: 'error',
    },
    [`${updateTags.rejected}`]: {
      message: i18n.t('TOASTS.update_tag_rejected'),
      severity: 'error',
    },
    [`${updateTags.fulfilled}`]: {
      message: i18n.t('TOASTS.update_tag_fulfilled'),
      severity: 'success',
    },
    [`${deleteTags.fulfilled}`]: {
      message: i18n.t('TOASTS.delete_tag_fulfilled'),
      severity: 'success',
    },
  }
  return messages as MessageType
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const handleLogout = (_action: AnyAction, _store: any) => {}

const handleForgotPassword = (action: AnyAction, _store: any) => {
  if (action.meta.arg.email) {
    customHistory.replace(ROUTE.VERIFY_CODE, {
      email: action.meta.arg.email,
    } as ForgotPasswordParams)
  }
}

const handleVerifyConfirmationCode = (action: AnyAction, _store: any) => {
  if (action.meta.arg.email && action.meta.arg.confirmation_code) {
    customHistory.replace(ROUTE.RESET_PASSWORD, {
      email: action.meta.arg.email,
      confirmation_code: action.meta.arg.confirmation_code,
    } as ConfirmPasswordParams)
  }
}

const handleResetForgotPassword = (_action: AnyAction, _store: any) => {
  customHistory.replace(ROUTE.LOGIN)
}

const handleCompanyDetailsError = (_action: AnyAction, _store: any) => {
  customHistory.replace(ROUTE.NOT_FOUND)
}

const handleShowDepartmentError = (_action: AnyAction, _store: any) => {
  customHistory.replace(ROUTE.NOT_FOUND)
}

const handleRoleUpdate = (_action: AnyAction, store: any) => {
  store.dispatch(fetchProfile())
}

const handleActionDelete = (action: AnyAction, store: any) => {
  if (
    action.payload.error &&
    action.payload.error?.code === 40001 &&
    action.payload.error.message
  ) {
    store.dispatch(
      addToast({
        message: i18n.t(`ERROR.${action.payload.error.message}`),
        severity: 'warning',
      })
    )
  } else {
    store.dispatch(
      addToast({
        message: i18n.t('ERROR.E000062'),
        severity: 'error',
      })
    )
  }
}

const handleActionMemberDelete = (action: AnyAction, store: any) => {
  if (
    action.payload.error &&
    action.payload.error?.code === 40002 &&
    action.payload.error.message
  ) {
    store.dispatch(
      addToast({
        message: i18n.t(`ERROR.${action.payload.error.message}`),
        severity: 'warning',
      })
    )
  } else {
    store.dispatch(
      addToast({
        message: i18n.t('TOASTS.delete_project_user_rejected'),
        severity: 'error',
      })
    )
  }
}

const handleActionMemberUpdate = (action: AnyAction, store: any) => {
  if (
    action.payload.error &&
    action.payload.error?.code === 40002 &&
    action.payload.error.message
  ) {
    store.dispatch(
      addToast({
        message: i18n.t(`ERROR.${action.payload.error.message}`),
        severity: 'warning',
      })
    )
  } else {
    store.dispatch(
      addToast({
        message: i18n.t('TOASTS.update_user_rejected'),
        severity: 'error',
      })
    )
  }
}

const handleAccessDenied = (action: AnyAction, _store: any) => {
  if (action.payload && action.payload.status === 404) {
    customHistory.replace(`/404`)
  } else if (action.payload && action.payload.status === 403) {
    customHistory.replace(`/403`)
  }
}

// const redirect403 = (action: AnyAction, _store: any) => {
//   if (action.payload.error && action.payload.error.code === 404)
//     customHistory.replace(`/403`)
// }

const handleProfileError = (action: AnyAction) => {
  if (action.payload?.error && action.payload?.error.code === 404) {
    customHistory.replace(`/404`)
  }
}

const handleResourcePlanDelete = (action: AnyAction, store: any) => {
  if (
    action.payload.error &&
    action.payload.error?.code === 40003 &&
    action.payload.error.message
  ) {
    store.dispatch(
      addToast({
        message: i18n.t(`ERROR.${action.payload.error.message}`),
        severity: 'warning',
      })
    )
  } else {
    store.dispatch(
      addToast({
        message: i18n.t('TOASTS.delete_resource_plan_rejected'),
        severity: 'error',
      })
    )
  }
}

const handleActionBatchResourcePlan = (action: AnyAction, store: any) => {
  if (
    action.payload.error &&
    (action.payload.error?.code === 40005 ||
      action.payload.error?.code === 40006) &&
    action.payload.error.message
  ) {
    store.dispatch(
      addToast({
        message: i18n.t(`ERROR.${action.payload.error.message}`),
        severity: 'warning',
      })
    )
  } else {
    store.dispatch(
      addToast({
        message: i18n.t('SYSCOMMON.something_went_wrong'),
        severity: 'error',
      })
    )
  }
}

const handlePartnerPurchaseOrderCreate = (action: AnyAction, store: any) => {
  if (
    action.payload.error &&
    action.payload.error?.code === 40006 &&
    action.payload.error.message
  ) {
    store.dispatch(
      addToast({
        message: i18n.t(`ERROR.${action.payload.error.message}`),
        severity: 'warning',
      })
    )
  } else {
    store.dispatch(
      addToast({
        message: i18n.t('TOASTS.create_partner_purchase_order_rejected'),
        severity: 'error',
      })
    )
  }
}

const handlePartnerPurchaseOrderUpdate = (action: AnyAction, store: any) => {
  if (
    action.payload.error &&
    action.payload.error?.code === 40006 &&
    action.payload.error.message
  ) {
    store.dispatch(
      addToast({
        message: i18n.t(`ERROR.${action.payload.error.message}`),
        severity: 'warning',
      })
    )
  } else {
    store.dispatch(
      addToast({
        message: i18n.t('TOASTS.update_partner_purchase_order_rejected'),
        severity: 'error',
      })
    )
  }
}

const handleMeetingRoomDelete = (action: AnyAction, store: any) => {
  if (
    action.payload.error &&
    action.payload.error?.code === 40007 &&
    action.payload.error.message
  ) {
    store.dispatch(
      addToast({
        message: i18n.t(`ERROR.${action.payload.error.message}`),
        severity: 'error',
      })
    )
  } else {
    store.dispatch(
      addToast({
        message: i18n.t('TOASTS.delete_meeting_room_rejected'),
        severity: 'error',
      })
    )
  }
}

const handleResetForgotPasswordRejected = (action: AnyAction, store: any) => {
  if (
    action.payload &&
    action.payload?.code === 40044 &&
    action.payload.message
  ) {
    store.dispatch(
      addToast({
        message: i18n.t(`ERROR.${action.payload.message}`),
        severity: 'error',
      })
    )
  } else {
    store.dispatch(
      addToast({
        message: i18n.t('TOASTS.password_reset_rejected'),
        severity: 'error',
      })
    )
  }
}

const handleCreateTimeOffRequest = (action: AnyAction, store: any) => {
  if (
    action.payload?.error &&
    action.payload.error?.code &&
    action.payload?.error.code !== 404 &&
    action.payload?.error.code !== 422
  ) {
    handleCustomToastMessage(action, store)
  }
}

const handleCustomToastMessage = (action: AnyAction, store: any) => {
  if (
    action.payload.error &&
    action.payload.error?.code &&
    action.payload.error.message
  ) {
    store.dispatch(
      addToast({
        message: i18n.t(`ERROR.${action.payload.error.message}`),
        severity: 'error',
      })
    )
  } else {
    store.dispatch(
      addToast({
        message: i18n.t('TOASTS.unknown_rejected'),
        severity: 'error',
      })
    )
  }
}

const handleCustomReportToastMessage = (action: AnyAction, store: any) => {
  if (
    action.payload.error &&
    action.payload.error?.code &&
    action.payload.error.message
  ) {
    store.dispatch(
      addToast({
        message: i18n.t(`ERROR.${action.payload.error.message}`),
        severity: 'error',
      })
    )
  } else {
    store.dispatch(
      addToast({
        message: i18n.t('TOASTS.daily_report_create_rejected'),
        severity: 'error',
      })
    )
  }
}

const handleCustomReportUpdateToastMessage = (
  action: AnyAction,
  store: any
) => {
  if (
    action.payload.error &&
    action.payload.error?.code &&
    action.payload.error.message
  ) {
    store.dispatch(
      addToast({
        message: i18n.t(`ERROR.${action.payload.error.message}`),
        severity: 'error',
      })
    )
  } else {
    store.dispatch(
      addToast({
        message: i18n.t('TOASTS.daily_report_update_rejected'),
        severity: 'error',
      })
    )
  }
}

const handleTimeCorrectionRequestRejected = (action: AnyAction, store: any) => {
  if (
    action.payload &&
    action.payload?.error.code === 40028 &&
    action.payload.error.message
  ) {
    store.dispatch(
      addToast({
        message: i18n.t(`ERROR.${action.payload.error.message}`),
        severity: 'error',
      })
    )
  } else {
    store.dispatch(
      addToast({
        message: i18n.t('TOASTS.time_correction_create_rejected'),
        severity: 'error',
      })
    )
  }
}

export const actions = {
  [`${logOut}`]: handleLogout,
  [`${sendConfirmationEmail.fulfilled}`]: handleForgotPassword,
  [`${verifyConfirmationCode.fulfilled}`]: handleVerifyConfirmationCode,
  [`${resetForgotPassword.fulfilled}`]: handleResetForgotPassword,
  [`${fetchCompanyDetails.rejected}`]: handleCompanyDetailsError,
  [`${showDepartment.rejected}`]: handleShowDepartmentError,
  [`${fetchProfile.rejected}`]: handleProfileError,
  [`${updateRole.fulfilled}`]: handleRoleUpdate,
  [`${deletePosition.rejected}`]: handleActionDelete,
  [`${deleteProject.rejected}`]: handleActionDelete,
  [`${deleteCompany.rejected}`]: handleActionDelete,
  [`${deletePartnerDivision.rejected}`]: handleActionDelete,
  [`${deleteDepartment.rejected}`]: handleActionDelete,
  [`${deleteRole.rejected}`]: handleActionDelete,
  [`${showEmployee.rejected}`]: handleAccessDenied,
  [`${emailTemplateDetail.rejected}`]: handleAccessDenied,
  [`${showProject.rejected}`]: handleAccessDenied,
  [`${showResourcePlan.rejected}`]: handleAccessDenied,
  [`${deleteResourcePlan.rejected}`]: handleResourcePlanDelete,
  [`${memberList.rejected}`]: handleAccessDenied,
  [`${projectActivity.rejected}`]: handleAccessDenied,
  [`${updateUser.rejected}`]: handleActionMemberUpdate,
  [`${deleteProjectUser.rejected}`]: handleActionMemberDelete,
  [`${batchResourcePlan.rejected}`]: handleActionBatchResourcePlan,
  [`${createPartnerPurchaseOrder.rejected}`]: handlePartnerPurchaseOrderCreate,
  [`${updatePartnerPurchaseOrder.rejected}`]: handlePartnerPurchaseOrderUpdate,
  [`${meetingRoomDelete.rejected}`]: handleMeetingRoomDelete,
  [`${breakStart.rejected}`]: handleCustomToastMessage,
  [`${breakEnd.rejected}`]: handleCustomToastMessage,
  [`${timeStart.rejected}`]: handleCustomToastMessage,
  [`${timeEnd.rejected}`]: handleCustomToastMessage,
  [`${createTimeOffRequest.rejected}`]: handleCreateTimeOffRequest,
  [`${approveTimeOffRequest.rejected}`]: handleCustomToastMessage,
  [`${denyTimeOffRequest.rejected}`]: handleCustomToastMessage,
  [`${createVacationBalance.rejected}`]: handleCustomToastMessage,
  [`${breakStart.rejected}`]: handleCustomToastMessage,
  [`${approveTimeCorrection.rejected}`]: handleCustomToastMessage,
  [`${updateApprovalTemplate.rejected}`]: handleCustomToastMessage,
  [`${deleteApprovalTemplate.rejected}`]: handleCustomToastMessage,
  [`${createApprovalTemplateDetail.rejected}`]: handleCustomToastMessage,
  [`${updateApprovalTemplateDetail.rejected}`]: handleCustomToastMessage,
  [`${updateApprovalTemplateStep.rejected}`]: handleCustomToastMessage,
  [`${deleteApprovalTemplateDetail.rejected}`]: handleCustomToastMessage,
  [`${resetForgotPassword.rejected}`]: handleResetForgotPasswordRejected,
  [`${updateTimeCorrectionTemplate.rejected}`]: handleCustomToastMessage,
  [`${createTimeCorrection.rejected}`]: handleTimeCorrectionRequestRejected,
  // [`${verifyWorkspace.rejected}`]: handleCustomToastMessage,
  [`${deleteWorkspace.rejected}`]: handleCustomToastMessage,
  [`${approveRemoteWorkRequest.rejected}`]: handleCustomToastMessage,
  [`${createRemoteWorkRequest.rejected}`]: handleCustomToastMessage,
  [`${updateRemoteWorkRequest.rejected}`]: handleCustomToastMessage,
  [`${deleteRemoteWorkRequest.rejected}`]: handleCustomToastMessage,
  [`${updateRemoteWorkTemplateDetail.rejected}`]: handleCustomToastMessage,
  [`${createDailyReport.rejected}`]: handleCustomReportToastMessage,
  [`${updateDailyReport.rejected}`]: handleCustomReportUpdateToastMessage,
  [`${fetchRemoteOfficeReport.rejected}`]: handleCustomToastMessage,
  [`${meetingRoomOrderDelete.rejected}`]: handleCustomToastMessage,
  [`${denyRemoteWorkRequest.rejected}`]: handleCustomToastMessage,
  [`${deleteTags.rejected}`]: handleCustomToastMessage,
}
